<template>
    <div>
        <!-- downloadalert  -->
        <v-snackbar width="300px" vertical top v-model="showDownloadProgress" :timeout="2000000" color="primary">
            <div class="pb-0">
                <div class="download-label">
                    <span class="mx-auto">generating export...</span>
                </div>
                <v-progress-linear style="width: 310px" label="downloading" color="white" indeterminate rounded height="6"></v-progress-linear>
            </div>
        </v-snackbar>
        <v-row>
            <!-- download-dialog -->
            <v-dialog v-model="downloadDialog" max-width="350px" @click:outside="resetTrendsObject()">
                <v-card>
                    <center>
                        <strong id="title" class="mb-5 d-inline-block">Download Keywords</strong>
                    </center>
                    <v-card-text class="pb-0">
                        <v-select
                            class="mr-3"
                            v-model="doctype"
                            :items="doctypes"
                            filled
                            item-text="value"
                            item-value="data"
                            return-object
                            :prepend-icon="doctype.icon"
                            label="select file format"
                            attach></v-select>
                    </v-card-text>
                    <v-card-actions id="downloadactions">
                        <v-btn rounded class="mx-auto" dark color="primary" v-on:click="!trends_export.status ? exportData() : exportTrends()">Download</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <!-- ----------------------- -->
            <!-- restore standard dialog -->
            <!-- ----------------------- -->
            <v-dialog v-model="restoreStandardDialog" max-width="300px">
                <v-card>
                    <v-card-title class="pb-0" style="font-weight: 100; color: grey">
                        <span>Are you sure?</span>
                    </v-card-title>
                    <v-card-actions>
                        <v-btn small rounded color="error" class="white--text ml-auto" @click="restoreStandardDialog = false">No</v-btn>
                        <v-btn small rounded color="primary" class="white--text" @click="restoreStandard()">Yes</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <!-- ----------------------- -->
            <!-- edit Drop Down Option Dialog -->
            <!-- ----------------------- -->
            <v-dialog width="500" v-model="editOptionDialog.show">
                <v-card>
                    <v-card-title> Edit Option </v-card-title>

                    <v-card-text>
                        <v-text-field
                            :rules="[rules.required, rules.counter, validateDropDownItemDuplicateRule]"
                            rounded
                            filled
                            color="primary"
                            v-model="editOptionDialog.text"
                            hide-details></v-text-field>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="primary"
                            rounded
                            @click="
                                editOptionDialog.item.text = editOptionDialog.text
                                optionTextChanged(editOptionDialog.item, headers.modifiedHeaders[modifyHeaderIndex].value, editOptionDialog.type) //Type 0 = new column, type 1 Collection Column. type = saved column
                                editOptionDialog.show = false
                            ">
                            SAve
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <!-- ----------------------- -->
            <!--   modify header dialog  -->
            <!-- ----------------------- -->
            <v-dialog v-model="modifyHeaderDialog" @click:outside="savedHeaderValue = ''" max-width="450px">
                <v-card>
                    <center>
                        <h3>
                            <strong id="title" class="mb-3 d-inline-block">Modify Column</strong>
                            <v-btn
                                class="mt-2"
                                @click="
                                    modifyHeaderDialog = false
                                    savedHeaderValue = ''
                                "
                                right
                                absolute
                                icon
                                ><v-icon>mdi-close</v-icon></v-btn
                            >
                        </h3>
                    </center>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12">
                                <v-form ref="titlemodifyform">
                                    <v-text-field
                                        label="Column Title"
                                        required
                                        id="modified-column-title"
                                        persistent-hint
                                        rounded
                                        filled
                                        :hint="
                                            savedHeaderValue !== ''
                                                ? 'Default: ' + saved_headers[saved_headers.findIndex((x) => x.value == savedHeaderValue)].modifiedText
                                                : 'Default: ' + headers.modifiedHeaders[modifyHeaderIndex].modifiedText
                                        "></v-text-field>
                                </v-form>
                            </v-col>
                            <v-col cols="12">
                                <v-form ref="tooltipmodifyform">
                                    <v-text-field
                                        :hint="
                                            savedHeaderValue !== ''
                                                ? 'Default: ' + saved_headers[saved_headers.findIndex((x) => x.value == savedHeaderValue)].modifiedTooltip
                                                : 'Default: ' + headers.modifiedHeaders[modifyHeaderIndex].modifiedTooltip
                                        "
                                        label="Description"
                                        id="modified-column-description"
                                        required
                                        rounded
                                        persistent-hint
                                        filled></v-text-field>
                                </v-form>
                            </v-col>
                        </v-row>
                        <!-- if dropdown -->
                        <div
                            v-if="
                                (savedHeaderValue !== '' && saved_headers[saved_headers.findIndex((x) => x.value == savedHeaderValue)].customOptions.type === 'Dropdown') ||
                                (headers.modifiedHeaders[modifyHeaderIndex].custom &&
                                    headers.modifiedHeaders[modifyHeaderIndex].customOptions &&
                                    headers.modifiedHeaders[modifyHeaderIndex].customOptions.type === 'Dropdown')
                            ">
                            <v-slide-x-transition hide-on-leave>
                                <v-row v-if="!pasteOptionsDialog.show">
                                    <v-col cols="12">
                                        <!-- SAVED HEADER -->
                                        <v-data-table
                                            v-if="savedHeaderValue !== ''"
                                            :items="saved_headers[saved_headers.findIndex((x) => x.value == savedHeaderValue)].customOptions.entries"
                                            :headers="dropDownOptionsTableHeaders">
                                            <template v-slot:item="{ item, index }">
                                                <tr v-if="index > 0">
                                                    <td>
                                                        <v-btn
                                                            :color="item.color.hex"
                                                            fab
                                                            x-small
                                                            class="mr-2"
                                                            @click="
                                                                showColorPicker = true
                                                                colorIndex = index
                                                            ">
                                                            <v-icon color="white">mdi-chevron-down</v-icon>
                                                        </v-btn>
                                                        {{ item.text }}
                                                    </td>
                                                    <td>
                                                        <v-btn
                                                            icon
                                                            slot="append"
                                                            @click="
                                                                editOptionDialog.show = true
                                                                editOptionDialog.text = item.text + ''
                                                                editOptionDialog.item = item
                                                                editOptionDialog.type = 2
                                                            ">
                                                            <v-icon small>mdi-pencil</v-icon>
                                                        </v-btn>
                                                        <v-btn icon slot="append" @click="removeEntryOfColumn(index, 2)">
                                                            <v-icon small>mdi-close</v-icon>
                                                        </v-btn>
                                                    </td>
                                                </tr>
                                            </template>
                                        </v-data-table>

                                        <v-data-table v-else :items="headers.modifiedHeaders[modifyHeaderIndex].customOptions.entries" :headers="dropDownOptionsTableHeaders">
                                            <template v-slot:item="{ item, index }">
                                                <tr v-if="index > 0">
                                                    <td>
                                                        <v-btn
                                                            :color="item.color.hex"
                                                            fab
                                                            x-small
                                                            class="mr-2"
                                                            @click="
                                                                showColorPicker = true
                                                                colorIndex = index
                                                            ">
                                                            <v-icon color="white">mdi-chevron-down</v-icon>
                                                        </v-btn>
                                                        {{ item.text }}
                                                    </td>
                                                    <td>
                                                        <v-btn
                                                            icon
                                                            slot="append"
                                                            @click="
                                                                editOptionDialog.show = true
                                                                editOptionDialog.text = item.text + ''
                                                                editOptionDialog.item = item
                                                                editOptionDialog.type = 1
                                                            ">
                                                            <v-icon small>mdi-pencil</v-icon>
                                                        </v-btn>
                                                        <v-btn icon slot="append" @click="removeEntryOfColumn(index, 1)">
                                                            <v-icon small>mdi-close</v-icon>
                                                        </v-btn>
                                                    </td>
                                                </tr>
                                            </template>
                                        </v-data-table>
                                    </v-col>
                                </v-row>
                            </v-slide-x-transition>
                            <v-row v-if="!pasteOptionsDialog.show">
                                <v-col cols="auto" class="py-0 ml-0">
                                    <v-btn small @click="savedHeaderValue !== '' ? addEntryToColumn(2) : addEntryToColumn(1)" class="ml-2 px-1 share-dialog-invite-tbn" color="primary" text>
                                        <v-icon> mdi-plus </v-icon>
                                        Add Option
                                    </v-btn>
                                </v-col>
                                <v-btn small class="ml-auto mr-1 px-1 share-dialog-invite-tbn" color="primary" text @click="pasteOptionsDialog.show = true">
                                    <v-icon small>mdi-clipboard-arrow-up</v-icon>
                                    Paste Options
                                </v-btn>
                            </v-row>
                            <v-row class="mt-2">
                                <v-col cols="auto" class="py-0 ml-auto">
                                    <redo-cluster-dialog
                                        :header="savedHeaderValue !== '' ? saved_headers[saved_headers.findIndex((x) => x.value == savedHeaderValue)] : headers.modifiedHeaders[modifyHeaderIndex]"
                                        :selectItems="
                                            savedHeaderValue !== ''
                                                ? saved_headers[saved_headers.findIndex((x) => x.value == savedHeaderValue)].customOptions.entries
                                                : headers.modifiedHeaders[modifyHeaderIndex].customOptions.entries
                                        "
                                        :loading="clusterLoading"
                                        @cluster="autoCluster">
                                    </redo-cluster-dialog>
                                    <!-- <v-dialog v-model="redoClusteringDialog" width="500">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn v-bind="attrs" v-on="on" small class="ml-2 px-1 share-dialog-invite-tbn" color="primary" text>
                                                <v-icon> mdi-refresh </v-icon>
                                                Auto Clustering
                                            </v-btn>
                                        </template>
                                        <v-card>
                                            <v-card-title> Redo Clustering </v-card-title>
                                            <v-card-text>
                                                <v-select
                                                    :items="headers.modifiedHeaders[modifyHeaderIndex].customOptions.entries"
                                                    v-model="selectedEntriesClustering"
                                                    label="label"
                                                    multiple
                                                    chips
                                                    return-object
                                                    item-text="text"
                                                    deletable-chips
                                                    color="primary"></v-select>
                                            </v-card-text>
                                            <v-card-actions>
                                                <v-btn class="white--text" @click="doAutoCluster" color="primary">cluster</v-btn>
                                            </v-card-actions>
                                        </v-card>
                                    </v-dialog> -->
                                </v-col></v-row
                            >
                            <v-slide-x-transition hide-on-leave>
                                <v-row v-if="pasteOptionsDialog.show">
                                    <v-col>
                                        <PasteOptionsDialog
                                            :show="pasteOptionsDialog.show"
                                            :column="headers.modifiedHeaders[this.modifyHeaderIndex]"
                                            @close="pasteOptionsDialog.show = false"></PasteOptionsDialog>
                                    </v-col>
                                </v-row>
                            </v-slide-x-transition>
                        </div>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn
                            rounded
                            outlined
                            color="error"
                            class="white--text"
                            v-if="headers.modifiedHeaders[modifyHeaderIndex].custom"
                            @click="prepareDeleteColumn(headers.modifiedHeaders[modifyHeaderIndex], 0)"
                            >delete column</v-btn
                        >
                        <v-btn rounded color="success" outlined class="ml-auto" @click="savedHeaderValue !== '' ? editSavedColumn() : editColumn()">save changes</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <!-- ----------------------- -->
            <!--   Delete Column Dialog  -->
            <!-- ----------------------- -->
            <DeleteDialog
                :deleteDialog="deleteDialog"
                :selectedDeleteItem="selectedDeleteItem ? selectedDeleteItem.modifiedText : null"
                deleteLabel="Column"
                :loading="deleteLoading"
                @delete="deleteSavedColum ? deleteSavedColumn(selectedDeleteItem.value) : delColumn(selectedDeleteItem.value)"
                @close="deleteDialog = false">
            </DeleteDialog>

            <!-- ----------------------- -->
            <!--   Share Item Dialog  -->
            <!-- ----------------------- -->
            <ShareDialog
                v-if="mountedOn == 'Research'"
                :shareMenu="shareDialog"
                :shareItem="collection_information"
                @disableShareMenu="shareDialog = false"
                :mountedOn="{ url: 'research', name: 'Collection' }">
            </ShareDialog>

            <!-- ----------------------- -->
            <!--    add column dialog    -->
            <!-- ----------------------- -->
            <v-dialog v-if="addColumnDialog" v-model="addColumnDialog" @click:outside="restoreAddColumnDialog" max-width="500px">
                <v-card>
                    <center>
                        <!-- <strong id="title" style="font-size: 1em" class="mb-3 d-inline-block">Add Table Column</strong> -->
                        <v-btn @click="addColumnDialog = false" right absolute class="mt-2" icon><v-icon>mdi-close</v-icon></v-btn>
                    </center>
                    <v-tabs centered v-model="tab">
                        <v-tab>Create Column</v-tab>
                        <v-tab>Saved Columns</v-tab>
                    </v-tabs>

                    <v-tabs-items v-model="tab">
                        <v-tab-item>
                            <v-card-text class="pb-0">
                                <v-row>
                                    <v-col cols="6">
                                        <v-form ref="titleform">
                                            <v-text-field
                                                label="Column Title"
                                                class="new_column_title"
                                                filled
                                                id="new-column-title"
                                                rounded
                                                hint="e.g Color, Size, Intend"
                                                persistent-hint></v-text-field>
                                        </v-form>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-select rounded filled v-model="newColumn.customOptions.type" :rules="[rules.required]" :items="customColumnTypes" label="Column Type">
                                            <template slot="selection-item" slot-scope="item">
                                                <v-icon>mdi-pencil</v-icon>
                                                {{ item }}
                                            </template>
                                        </v-select>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" class="pt-0">
                                        <v-btn
                                            v-if="!addHeaderDescription"
                                            small
                                            style=""
                                            class="ml-2 px-1 share-dialog-invite-tbn"
                                            color="primary"
                                            outlined
                                            @click="addHeaderDescription = !addHeaderDescription">
                                            <v-icon class="ml-0" small>mdi-plus</v-icon>
                                            {{ addHeaderDescription ? "" : "Add Description" }}
                                        </v-btn>
                                        <v-form ref="descrform">
                                            <v-text-field id="new-column-description" rounded v-if="addHeaderDescription" filled label="Description" hide-details></v-text-field>
                                        </v-form>
                                    </v-col>
                                </v-row>
                                <!-- ----------- -->
                                <!--   DROPDOWN  -->
                                <!-- ----------- -->
                                <div v-if="newColumn.customOptions.type === 'Dropdown'">
                                    <v-slide-x-transition hide-on-leave>
                                        <v-row class="" v-if="!pasteOptionsDialog.show">
                                            <v-col cols="12">
                                                <v-data-table
                                                    style="border: 2px solid #f0f0f0; border-radius: 10px"
                                                    class="mx-5"
                                                    :items="newColumn.customOptions.entries"
                                                    :headers="dropDownOptionsTableHeaders">
                                                    <template v-slot:item="{ item, index }">
                                                        <tr v-if="index > 0">
                                                            <td>
                                                                <v-btn
                                                                    :color="item.color.hex"
                                                                    fab
                                                                    x-small
                                                                    class="mr-2"
                                                                    @click="
                                                                        showColorPicker = true
                                                                        colorIndex = index
                                                                    ">
                                                                    <v-icon color="white">mdi-chevron-down</v-icon>
                                                                </v-btn>
                                                                {{ item.text }}
                                                            </td>
                                                            <td>
                                                                <v-btn
                                                                    icon
                                                                    slot="append"
                                                                    @click="
                                                                        editOptionDialog.show = true
                                                                        editOptionDialog.text = item.text + ''
                                                                        editOptionDialog.item = item
                                                                        editOptionDialog.type = 0
                                                                    ">
                                                                    <v-icon small>mdi-pencil</v-icon>
                                                                </v-btn>
                                                                <v-btn icon slot="append" @click="removeEntryOfColumn(index, 0)">
                                                                    <v-icon small>mdi-close</v-icon>
                                                                </v-btn>
                                                            </td>
                                                        </tr>
                                                    </template>
                                                </v-data-table>
                                            </v-col>
                                        </v-row>
                                    </v-slide-x-transition>
                                    <v-slide-x-transition hide-on-leave>
                                        <v-row v-if="pasteOptionsDialog.show">
                                            <v-col>
                                                <PasteOptionsDialog :show="pasteOptionsDialog.show" :column="newColumn" @close="pasteOptionsDialog.show = false"></PasteOptionsDialog>
                                            </v-col>
                                        </v-row>
                                    </v-slide-x-transition>
                                    <v-row v-if="!pasteOptionsDialog.show" class="mx-2">
                                        <v-col cols="12" class="py-0 ml-0 d-flex">
                                            <v-btn small class="ml-2 px-1 share-dialog-invite-tbn" color="primary" outlined @click="addEntryToColumn(0)">
                                                <v-icon small> mdi-plus </v-icon>
                                                Add Option
                                            </v-btn>
                                            <v-btn small class="ml-auto mr-1 px-1 share-dialog-invite-tbn" color="primary" outlined @click="pasteOptionsDialog.show = true">
                                                <v-icon small>mdi-clipboard-arrow-up</v-icon>
                                                Paste Options
                                            </v-btn>
                                        </v-col>

                                        <!-- <v-col cols="6" class="py-0">
                                    <div class="dashed-border ml-auto">
                                        <span class="ml-3 my-auto">auto cluster keywords</span>
                                        <v-checkbox hide-details class="my-auto pt-0 ml-3" v-model="doAutoCluster"></v-checkbox>
                                    </div>
                                    </v-col> -->
                                    </v-row>
                                </div>
                                <v-row class="mt-5">
                                    <v-col>
                                        <div class="mr-auto" style="display: inline-flex">
                                            <v-tooltip rounded top>
                                                <template v-slot:activator="{ on }">
                                                    <v-icon color="primary" v-on="on" class="mx-2">mdi-information-outline</v-icon>
                                                </template>
                                                <span style="width: 50px">
                                                    Identifies the given options in your <br />
                                                    keywords and presets the matching values!
                                                </span>
                                            </v-tooltip>
                                            <span class="my-auto">TAG KEYWORDS</span>
                                            <v-checkbox rounded hide-details class="my-auto pt-0 ml-3" v-model="doAutoCluster"></v-checkbox>
                                        </div>
                                    </v-col>
                                    <v-col style="display: flex">
                                        <div class="ml-auto" style="display: inline-flex">
                                            <v-tooltip rounded top>
                                                <template v-slot:activator="{ on }">
                                                    <v-icon color="primary" v-on="on" class="mx-2">mdi-information-outline</v-icon>
                                                </template>
                                                <span style="width: 50px">
                                                    Adds the column to your set of saved columns.<br />
                                                    You can select them again when you create a new column!
                                                </span>
                                            </v-tooltip>
                                            <span class="my-auto">SAVE COLUMN</span>
                                            <v-checkbox rounded hide-details class="my-auto pt-0 ml-3" v-model="addColumnToSettings"></v-checkbox>
                                        </div>
                                    </v-col>
                                    <!-- <v-col>
                                    <div class="ml-auto" style="display: inline-flex">
                                        <v-tooltip rounded top>
                                            <template v-slot:activator="{ on }">
                                                <v-icon color="#848484" v-on="on" class="mx-2">mdi-information-outline</v-icon>
                                            </template>
                                            <span style="width: 50px">
                                                Identifies the given options in your <br />
                                                keywords and presets the matching values!
                                            </span>
                                        </v-tooltip>
                                        <span class="my-auto">Create Pie Chart</span>
                                        <v-checkbox hide-details class="my-auto pt-0 ml-3"></v-checkbox>
                                    </div>
                                </v-col> -->
                                </v-row>
                            </v-card-text>

                            <v-card-actions>
                                <!-- <v-btn rounded color="error" :disabled="addColumnLoading" class="white--text ml-auto" outlined @click="addColumnDialog = false">cancel</v-btn> -->
                                <v-btn rounded color="primary" :loading="addColumnLoading" class="white--text mx-auto" @click="addColumn()">create</v-btn>
                            </v-card-actions>
                        </v-tab-item>
                        <v-tab-item>
                            <v-card-text class="pb-0">
                                <!-- <div class="list-group-item px-2" v-for="(header, index) in saved_headers" :key="index">
                                    <div class="header-list-entry">
                                        <div class="column-dialog-row-item px-3 mx-auto">
                                            <span class="my-auto mr-2">
                                                <v-icon>mdi-circle</v-icon>
                                            </span>
                                            <span class="my-auto">Column: {{ header.modifiedText }} </span>
                                            <span class="my-auto">- Type: {{ header.customOptions.type }}</span>
                                            <v-btn class="my-auto ml-2" @click="deleteSavedColumn(header)" icon>
                                                <v-icon small color="white" id="edit-column-icon">mdi-delete</v-icon>
                                            </v-btn>
                                            <v-checkbox inset hide-details class="ml-auto my-auto" v-model="header.addToColumns"></v-checkbox>
                                        </div>
                                    </div>
                                </div> -->
                                <v-autocomplete
                                    class="mt-5"
                                    v-model="selectedSavedHeaders"
                                    :items="saved_headers"
                                    :search-input.sync="savedHeadersSearch"
                                    label="Search saved columns..."
                                    rounded
                                    filled
                                    multiple
                                    hide-details
                                    small-chips
                                    item-text="text"
                                    return-object>
                                    <template v-slot:prepend-item>
                                        <!-- unselect all -->
                                        <!-- <v-list-item ripple v-if="selectedSavedHeaders.length > 0" @click="selectedSavedHeaders = []">
                                            <v-list-item-action>
                                                <v-icon color="primary"> mdi-close-box</v-icon>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title> unselect all </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item> -->
                                        <!-- select all -->
                                        <!-- <v-list-item ripple v-if="selectedSavedHeaders.length == 0" v-on:click="selectedSavedHeaders = saved_headers">
                                            <v-list-item-action>
                                                <v-icon color="primary"> mdi-checkbox-multiple-marked </v-icon>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title> select all </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>

                                        <v-divider class="mt-2"></v-divider> -->
                                    </template>

                                    <template v-slot:selection="{ item, index }">
                                        <span class="mt-2">
                                            <span v-if="index < 3"
                                                ><v-chip small>{{ item.modifiedText }}</v-chip></span
                                            >
                                        </span>

                                        <span v-if="index === 3" class="grey--text caption"> (+{{ selectedSavedHeaders.length - 3 }} more) </span>
                                    </template>
                                    <template v-slot:item="data">
                                        <v-col class="px-0" cols="1">
                                            <v-icon small v-if="data.attrs['aria-selected'] == 'true'" color="primary">mdi-check</v-icon>
                                        </v-col>
                                        <v-col class="pl-0" cols="10">
                                            <v-list-item two-line>
                                                <v-list-item-content>
                                                    <v-list-item-title>{{ data.item.modifiedText }}</v-list-item-title>
                                                    <v-list-item-subtitle>
                                                        {{ data.item.customOptions.type }}
                                                        <v-icon small style="padding-bottom: 1px; margin-left: 2px">
                                                            {{
                                                                data.item.customOptions.type == "Dropdown"
                                                                    ? "mdi-chevron-down-circle"
                                                                    : data.item.customOptions.type == "Number"
                                                                    ? "mdi-numeric"
                                                                    : "mdi-alphabetical-variant"
                                                            }}
                                                        </v-icon>
                                                        <span v-if="data.item.customOptions.type == 'Dropdown'">
                                                            <span v-for="(option, index) in data.item.customOptions.entries" :key="option.id">
                                                                <v-chip class="ml-1 white--text" x-small :color="option.color.hex" v-if="option.text != '-' && index < 4"> {{ option.text }} </v-chip>
                                                            </span>
                                                            <v-tooltip rounded top max-width="350px">
                                                                <template v-slot:activator="{ on }">
                                                                    <v-chip color="grey" class="ml-1 white--text" x-small v-on="on" v-if="data.item.customOptions.entries.length > 4">{{
                                                                        " ... "
                                                                    }}</v-chip>
                                                                </template>
                                                                <span style="width: 50px">
                                                                    <div>
                                                                        <div><span class="titlelbl">All Dropdown Options:</span></div>
                                                                        <span v-for="(option, index) in data.item.customOptions.entries" :key="option.id">
                                                                            <span v-if="option.text != '-'">{{ option.text }}</span>
                                                                            <span v-if="index > 0 && index < data.item.customOptions.entries.length - 1" class="mx-1">/</span>
                                                                        </span>
                                                                    </div>
                                                                </span>
                                                            </v-tooltip>
                                                        </span>
                                                    </v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>

                                            <!-- <v-chip v-bind="data.attrs" class="mr-1">
                                                <span> Column Title: </span>
                                                <span style="font-weight: bold" class="ml-1">{{ data.item.text }}</span>
                                            </v-chip>
                                            <v-chip v-bind="data.attrs">
                                                <span>Column Type: </span>
                                                <span style="font-weight: bold" class="ml-1">{{ data.item.customOptions.type }}</span>
                                            </v-chip> -->
                                        </v-col>
                                        <v-col class="pl-0" cols="1">
                                            <v-btn v-bind="data.attrs" flat class="my-auto ml-2 elevation-0" elevation-0 @click="openModifyColumnDialog(data.item.value)" x-small color="white" fab>
                                                <v-icon small color="primary" id="edit-column-icon">mdi-pencil</v-icon>
                                            </v-btn>

                                            <v-btn v-bind="data.attrs" flat class="my-auto ml-2 elevation-0" elevation-0 @click="prepareDeleteColumn(data.item, 1)" x-small color="white" fab>
                                                <v-icon small color="primary" id="edit-column-icon">mdi-delete</v-icon>
                                            </v-btn>
                                        </v-col>
                                    </template>
                                </v-autocomplete>

                                <v-row class="mt-2 mb-5">
                                    <v-col class="flex">
                                        <div class="ml-auto" style="display: inline-flex">
                                            <v-tooltip rounded top>
                                                <template v-slot:activator="{ on }">
                                                    <v-icon color="primary" v-on="on" class="mx-2">mdi-information-outline</v-icon>
                                                </template>
                                                <span style="width: 50px">
                                                    Identifies the given options in your <br />
                                                    keywords and presets the matching values!
                                                </span>
                                            </v-tooltip>
                                            <span class="my-auto">TAG KEYWORDS</span>
                                            <v-checkbox hide-details class="my-auto pt-0 ml-3" v-model="doAutoCluster"></v-checkbox>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <v-card-actions class="flex">
                                <!-- <v-btn rounded color="error" class="white--text ml-auto" outlined @click="addColumnDialog = false">cancel</v-btn> -->
                                <v-btn rounded color="primary" class="white--text mx-auto" @click="addSavedColumn()">add column</v-btn>
                            </v-card-actions>
                        </v-tab-item>
                    </v-tabs-items>
                </v-card>
            </v-dialog>

            <!-- ----------------------- -->
            <!-- color picker dialog     -->
            <!-- ----------------------- -->
            <v-dialog v-if="showColorPicker" v-model="showColorPicker" max-width="360px">
                <v-card>
                    <v-card-text class="pa-5">
                        <v-row justify="space-around">
                            <v-color-picker
                                v-if="showColorPicker && addColumnDialog"
                                class="ma-auto"
                                swatches-max-width="300px"
                                show-swatches
                                hide-canvas
                                v-model="newColumn.customOptions.entries[colorIndex].color"></v-color-picker>
                            <v-color-picker
                                v-if="showColorPicker && modifyHeaderDialog"
                                class="ma-auto"
                                swatches-max-width="300px"
                                show-swatches
                                hide-canvas
                                v-model="headers.modifiedHeaders[modifyHeaderIndex].customOptions.entries[colorIndex].color"></v-color-picker>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-dialog>

            <!-- ----------------------- -->
            <!--  modify columns dialog  -->
            <!-- ----------------------- -->
            <v-dialog v-model="modifyColumnsDialog" max-width="450px">
                <v-snackbar top v-model="notification.show" :color="notification.color">
                    <v-icon>{{ notification.icon }}</v-icon
                    ><span class="font-weight-bold pl-2"> {{ notification.message }} </span>
                </v-snackbar>
                <v-card>
                    <center>
                        <h3>
                            <span id="title" class="mb-5 d-inline-block" style="font-size: 1em">Customize Table Columns</span>
                            <v-btn @click="modifyColumnsDialog = false" right absolute class="mt-2" icon><v-icon>mdi-close</v-icon></v-btn>
                        </h3>
                    </center>
                    <v-card-text>
                        <v-row class="pb-5">
                            <v-btn small rounded color="primary" class="white--text mx-auto" outlined @click="restoreStandardDialog = true">restore default order</v-btn>
                        </v-row>
                        <v-row>
                            <span class="ml-3 mb-3 quaro-subheading">Columns:</span>
                        </v-row>
                        <v-row>
                            <div v-if="$helpers.isActionAllowed(39, user_rights)" class="add_column_bar" @click="openAddColumnDialog()">
                                <v-icon>mdi-plus</v-icon>
                                <span class="add_column_bar_text">Add Column</span>
                            </div>
                            <draggable
                                :list="headers.modifiedHeaders"
                                class="list-group"
                                ghost-class="ghost"
                                @start="dragging = true"
                                @end="
                                    dragging = false
                                    changedHeaders()
                                "
                                handle=".handle">
                                <div class="list-group-item px-2" v-for="(header, index) in headers.modifiedHeaders" :key="index">
                                    <!-- ------------------------------- -->
                                    <!-- all standard and custom columns -->
                                    <!-- ------------------------------- -->
                                    <div v-if="header.name !== 'sv_headers' && header.value !== 'keyword'" class="header-list-entry">
                                        <div v-if="header.text !== 'Instagram Media Count' ? true : media_counts" class="column-dialog-row-item px-3 mx-auto">
                                            <span class="handle mr-2">
                                                <v-icon>mdi-drag</v-icon>
                                            </span>
                                            <span class="my-auto">{{ header.modifiedText }}</span>
                                            <v-chip class="ml-2 my-auto" small color="red" dark v-if="header == latestCreatedHeader">new</v-chip>
                                            <v-chip class="ml-2 my-auto" small color="primary" dark v-if="header.custom">custom</v-chip>
                                            <v-chip class="ml-2 my-auto" small color="primary" dark v-if="saved_headers.findIndex((x) => x.value == header.value) > -1">saved</v-chip>
                                            <v-tooltip v-if="header.custom && saved_headers.findIndex((x) => x.value == header.value) == -1" top>
                                                <template v-slot:activator="{ on }">
                                                    <v-btn class="my-auto ml-2" @click="$store.dispatch('CHANGE_USER_SETTINGS_HEADER', header)" icon v-on="on">
                                                        <v-icon small color="white" id="edit-column-icon">mdi-content-save</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>save column</span>
                                            </v-tooltip>

                                            <v-btn
                                                class="my-auto ml-2"
                                                @click="
                                                    modifyHeaderDialog = true
                                                    modifyHeaderIndex = index
                                                "
                                                icon>
                                                <v-icon small color="white" id="edit-column-icon">mdi-pencil</v-icon>
                                            </v-btn>

                                            <v-switch inset flat hide-details class="ml-auto my-auto" v-model="header.active" @change="changedHeaders()"></v-switch>
                                        </div>
                                    </div>
                                    <!-- ------------------------------- -->
                                    <!--        monthly searches columns     -->
                                    <!-- ------------------------------- -->
                                    <div v-if="header.name === 'sv_headers'" class="mt-5">
                                        <v-row class="mb-2">
                                            <span class="handle mr-1">
                                                <v-icon>mdi-drag</v-icon>
                                            </span>

                                            <span class="my-auto quaro-subheading">Monthly Searches:</span>
                                        </v-row>
                                        <div class="dashed-border">
                                            <span class="ml-3 my-auto" v-if="headers.options.sort">invert order</span>
                                            <span class="ml-3 my-auto" v-else>invert order</span>
                                            <v-checkbox hide-details class="my-auto pt-0 ml-3" v-model="headers.options.sort" @change="setSearchesHeadersSort()"></v-checkbox>
                                        </div>
                                        <div class="dashed-border">
                                            <!-- <span class="ml-3 my-auto" v-if="headers.modifiedHeaders[svHeadersIndex].active">Hide all</span> -->
                                            <span class="ml-3 my-auto">Show all</span>
                                            <v-checkbox
                                                hide-details
                                                class="pt-0 ml-3 my-auto"
                                                v-model="headers.modifiedHeaders[svHeadersIndex].active"
                                                @change="hideHeaders('sv_headers')"></v-checkbox>
                                        </div>
                                        <!-- <draggable
                                            :list="header.columns"
                                            class="list-group"
                                            ghost-class="ghost"
                                            @start="dragging = true"
                                            @end="
                                                dragging = false
                                                changedHeaders()
                                            "
                                            handle=".handleSnd"> -->
                                        <div v-for="(el, index) in setHeadersToShow('sv')" :key="index">
                                            <div class="column-dialog-row-item px-3 mx-auto">
                                                <!-- <span class="handleSnd">
                                                    <v-icon>mdi-drag</v-icon>
                                                </span> -->
                                                <span class="my-auto">{{ el.modifiedText }}</span>
                                                <v-switch inset flat hide-details class="ml-auto my-auto" v-model="el.active" @change="changedHeaders()"></v-switch>
                                            </div>
                                        </div>
                                        <!-- </draggable> -->
                                    </div>
                                </div>
                            </draggable>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-dialog>

            <!-- ------------------------------- -->
            <!-- --------- select menu --------- -->
            <!-- ------------------------------- -->
            <SelectMenu @createNotification="createNotification" :show="mountedOn != 'SharedResearch' ? selected.length > 0 : false" :keywords="selected"></SelectMenu>
            <!-- Start -->
            <v-col v-if="mountedOn != 'Brandindex' && mountedOn != 'Research' && mountedOn != 'SharedResearch' && mountedOn != 'FindInCollections' ? tableloaded : true" cols="12" class="pt-0">
                <v-card class="rounded_card quaroshadow">
                    <div class="backsheet"></div>
                    <v-tabs rounded :height="40" class="tab_module" color="primary" v-model="active_tab">
                        <v-tab
                            v-for="(tabmodule, i) in tabmodules"
                            :key="i"
                            :class="$vuetify.theme.dark ? '' : active_tab == i ? 'kwtab active' : 'kwtab'"
                            :style="i == 0 ? ' border-radius: 10px 0 0 0' : ' border-radius: 0 0 10px 0'">
                            {{ tabmodule.name }}
                        </v-tab>
                        <v-tab-item>
                            <div class="v-card__title sheet-card-title pt-2 pb-1" id="tabletitle" v-if="!user_settings.advancedMode">
                                {{ cardData.title }}
                                <span v-if="cardData.searchPhrases" class="pr-5 sheet-card-title">
                                    <span class="kursiv ml-1"> "{{ cardData.searchPhrases[0] }}"</span></span
                                >
                            </div>

                            <v-row no-gutters class="sheet-card-title v-card__subtitle pt-3 pb-2" id="card__subtitle">
                                <span v-if="selected.length > 0">
                                    <v-chip class="white--text" color="primary" small> {{ selected.length }} of {{ keywordCount }} selected</v-chip></span
                                >
                                <span v-else class="mt-auto">
                                    <v-chip small>{{ cardData.subtitle }}</v-chip></span
                                >
                                <v-text-field
                                    v-model="search"
                                    color="primary"
                                    append-icon="mdi-magnify"
                                    class="adv_mode_searchbar"
                                    label="Search table..."
                                    hide-details
                                    filled
                                    dense
                                    rounded
                                    single-line
                                    clearable
                                    clear-icon="mdi-close"
                                    @click:clear="
                                        {
                                            search = ''
                                        }
                                    "
                                    ref="searchfield"
                                    style="width: 40%" />
                            </v-row>

                            <v-row no-gutters id="table_actions" class="px-4 pb-1">
                                <v-col>
                                    <v-tooltip v-if="!selected.length" top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn
                                                @click="selectAll"
                                                :outlined="user_settings.advancedMode ? false : true"
                                                :small="user_settings.advancedMode ? false : true"
                                                :icon="user_settings.advancedMode ? true : false"
                                                color="primary"
                                                class="mb-1 mr-1"
                                                id="select_all_btn"
                                                v-on="on">
                                                <v-icon color="primary" dark>mdi-checkbox-multiple-marked-outline</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Select all</span>
                                    </v-tooltip>

                                    <v-tooltip v-else top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn
                                                @click="selectAll"
                                                :outlined="user_settings.advancedMode ? false : true"
                                                :small="user_settings.advancedMode ? false : true"
                                                :icon="user_settings.advancedMode ? true : false"
                                                color="primary"
                                                class="mb-1 mr-1"
                                                v-on="on">
                                                <v-icon color="primary" dark>mdi-checkbox-multiple-blank-outline</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Unselect all</span>
                                    </v-tooltip>

                                    <v-tooltip top :disabled="!user_settings.advancedMode">
                                        <template v-slot:activator="{ on }">
                                            <v-btn
                                                v-on="on"
                                                id="add_clipboard_btn"
                                                v-if="mountedOn != 'SharedResearch'"
                                                class="mr-1 mb-1"
                                                :outlined="user_settings.advancedMode ? false : true"
                                                :small="user_settings.advancedMode ? false : true"
                                                :icon="user_settings.advancedMode ? true : false"
                                                color="primary"
                                                @click="addToClipboard">
                                                <span v-if="!user_settings.advancedMode"> add to clipboard</span>
                                                <v-icon :class="!user_settings.advancedMode ? 'ml-1' : ''">mdi-text-box-plus-outline</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>add to clipboard</span>
                                    </v-tooltip>

                                    <v-tooltip top :disabled="!user_settings.advancedMode">
                                        <template v-slot:activator="{ on }">
                                            <v-btn
                                                v-on="on"
                                                class="mr-1 mb-1"
                                                id="add_chart_btn"
                                                :outlined="user_settings.advancedMode ? false : true"
                                                :small="user_settings.advancedMode ? false : true"
                                                :icon="user_settings.advancedMode ? true : false"
                                                color="primary"
                                                @click="addToChart"
                                                :disabled="addToChartDisabled">
                                                <span v-if="!user_settings.advancedMode"> add to chart</span>
                                                <v-icon :class="!user_settings.advancedMode ? 'ml-1' : ''">mdi-chart-timeline-variant</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>add to chart</span>
                                    </v-tooltip>

                                    <v-tooltip top :disabled="!user_settings.advancedMode">
                                        <template v-slot:activator="{ on }">
                                            <v-btn
                                                v-on="on"
                                                v-if="user_settings.advancedMode && $helpers.isActionAllowed(43, user_rights)"
                                                class="mr-1 mb-1"
                                                :outlined="user_settings.advancedMode ? false : true"
                                                :small="user_settings.advancedMode ? false : true"
                                                :icon="user_settings.advancedMode ? true : false"
                                                color="primary"
                                                @click="$emit('fetchSERP', selected)"
                                                :disabled="selected.length > 1 || addToSerpDisabled || siteKeywordsLocation.loc == 'International'">
                                                <v-icon :class="!user_settings.advancedMode ? 'ml-1' : ''">mdi-feature-search-outline</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>load SERP</span>
                                    </v-tooltip>

                                    <v-tooltip top :disabled="!user_settings.advancedMode">
                                        <template v-slot:activator="{ on }">
                                            <v-btn
                                                v-on="on"
                                                class="mr-1 mb-1"
                                                v-if="mountedOn != 'FindInCollections' && mountedOn != 'SharedResearch' && $helpers.isActionAllowed(40, user_rights)"
                                                id="table_settings_btn"
                                                :outlined="user_settings.advancedMode ? false : true"
                                                :small="user_settings.advancedMode ? false : true"
                                                :icon="user_settings.advancedMode ? true : false"
                                                color="primary"
                                                @click="modifyColumnsDialog = true">
                                                <span v-if="!user_settings.advancedMode"> customize</span>
                                                <v-icon :class="!user_settings.advancedMode ? 'ml-1' : ''">mdi-table-settings</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>customize table</span>
                                    </v-tooltip>

                                    <v-menu rounded="lg" color="primary" transition="slide-y-transition" offset-y bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn class="mr-1 mb-1" outlined color="primary" small v-bind="attrs" v-on="on">
                                                actions
                                                <v-icon>mdi-chevron-down</v-icon>
                                            </v-btn>
                                        </template>
                                        <v-list rounded dense>
                                            <v-list-item @click="actionHandler(actions[4])" v-if="$helpers.isActionAllowed(4, user_rights) && mountedOn == 'Research'">
                                                <v-icon small class="mr-2">{{ actions[4].icon }}</v-icon>
                                                <v-list-item-title>{{ actions[4].title }}</v-list-item-title>
                                            </v-list-item>
                                            <v-list-item @click="actionHandler(actions[0])" v-if="$helpers.isActionAllowed(24, user_rights) || mountedOn == 'SharedResearch'">
                                                <v-icon small class="mr-2">{{ actions[0].icon }}</v-icon>
                                                <v-list-item-title>{{ actions[0].title }}</v-list-item-title>
                                            </v-list-item>

                                            <v-list-item
                                                @click="actionHandler(actions[3])"
                                                v-if="
                                                    mountedOn == 'Brandindex'
                                                        ? selected.length > 0 && $helpers.isActionAllowed(13, user_rights)
                                                        : mountedOn == 'Research'
                                                        ? $helpers.isActionAllowed(5, user_rights) && selected.length > 0
                                                        : mountedOn == 'SharedResearch' || mountedOn == 'FindInCollections'
                                                        ? false
                                                        : selected.length > 0
                                                ">
                                                <v-icon small class="mr-2">{{ actions[3].icon }}</v-icon>
                                                <v-list-item-title>{{ actions[3].title }}</v-list-item-title>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>

                                    <span
                                        v-if="
                                            mountedOn == 'Brandindex'
                                                ? selected.length > 0 && $helpers.isActionAllowed(13, user_rights)
                                                : mountedOn == 'Research'
                                                ? $helpers.isActionAllowed(5, user_rights) && selected.length > 0
                                                : mountedOn == 'SharedResearch' || mountedOn == 'FindInCollections'
                                                ? false
                                                : selected.length > 0
                                        ">
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn @click="deleteKeywords" outlined small color="primary" class="mr-1 mb-1 px-0" v-bind="attrs" v-on="on">
                                                    <v-icon color="primary" dark>mdi-delete</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>delete keywords</span>
                                        </v-tooltip>
                                    </span>
                                    <span v-if="isFiltered()">
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn @click="clearFilter" small color="primary" class="mr-1 mb-1 px-0" v-bind="attrs" v-on="on">
                                                    <v-icon color="white" dark>mdi-filter-variant-remove</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>clear filter</span>
                                        </v-tooltip>
                                    </span>
                                </v-col>
                            </v-row>

                            <v-layout fluid v-resize="onResize">
                                <v-data-table
                                    ref="keywordtable"
                                    id="keyword-table"
                                    v-model="selected"
                                    :headers="headersToShow"
                                    :items="keywords"
                                    item-key="keyword"
                                    :search.sync="search"
                                    :height="windowSize.y"
                                    fixed-header
                                    :loading="loading"
                                    @pagination="paginationHandler"
                                    :custom-sort="customSort"
                                    show-select
                                    :sort-by.sync="sortBy"
                                    :footer-props="{ 'items-per-page-options': [25, 50, 100] }"
                                    :items-per-page="25"
                                    :page.sync="tablepage"
                                    @toggle-select-all="selectPage">
                                    <!-- no data -->
                                    <template v-slot:no-data>
                                        <center class="circularprogress">
                                            <v-progress-circular :size="50" color="primary" :indeterminate="true"></v-progress-circular></center
                                    ></template>

                                    <!-- <template v-slot:header.data-table-select>
                                <v-icon style="margin: 0px; padding: 0px; position: sticky !important">mdi-checkbox-blank-outline</v-icon>
                            </template> -->

                                    <!-- header slot -->
                                    <template v-for="(customHeader, index) in headersToShow" v-slot:[`header.${customHeader.value}`]="{ header }">
                                        <span :key="index">
                                            <!-- month headers -->
                                            <span
                                                v-bind:style="{ width: header.width }"
                                                v-if="
                                                    ![
                                                        'keyword',
                                                        'media_count',
                                                        'avgsv',
                                                        'ranking',
                                                        'trend',
                                                        'adwords_competition',
                                                        'adwords_cpc',
                                                        'cats',
                                                        'adwords_cpc_high',
                                                        'adwords_cpc_low',
                                                        'serp_items',
                                                        'keyword_difficulty',
                                                        'peak',
                                                        'season'
                                                    ].includes(header.value) && !header.value.includes('custom')
                                                ">
                                                <span>{{ header.modifiedText }}</span>
                                            </span>
                                            <!-- standard -->
                                            <span
                                                v-if="
                                                    [
                                                        'keyword',
                                                        'media_count',

                                                        'avgsv',
                                                        'trend',
                                                        'adwords_competition',
                                                        'adwords_cpc',
                                                        'adwords_cpc_high',
                                                        'adwords_cpc_low',
                                                        'keyword_difficulty',
                                                        'peak',
                                                        'season'
                                                    ].includes(header.value)
                                                "
                                                v-bind:style="{ width: header.width }">
                                                <v-tooltip color="primary" top max-width="250px">
                                                    <template v-slot:activator="{ on }">
                                                        <span v-on="on">
                                                            {{ user_settings.advancedMode && header.advanced_text ? header.advanced_text : header.modifiedText }}
                                                            <v-icon x-small>mdi-help</v-icon>
                                                        </span>
                                                    </template>
                                                    <span>{{ header.modifiedTooltip }}</span>
                                                </v-tooltip>
                                            </span>
                                            <span v-if="header.value == 'ranking'" v-bind:style="{ width: header.width }">
                                                <v-tooltip color="primary" top max-width="250px">
                                                    <template v-slot:activator="{ on }">
                                                        <span v-on="on">
                                                            {{ user_settings.advancedMode && header.advanced_text ? header.advanced_text : header.modifiedText }}
                                                            <span style="white-space: nowrap">
                                                                <v-icon x-small>mdi-help</v-icon>
                                                                <v-menu ref="rankingMenu" :close-on-content-click="false" offset-y transition="slide-y-transition" bottom>
                                                                    <template v-slot:activator="{ on }">
                                                                        <v-btn v-on="on" x-small icon>
                                                                            <v-icon x-small>mdi-cog</v-icon>
                                                                        </v-btn>
                                                                    </template>
                                                                    <v-list>
                                                                        <v-row class="mx-2">
                                                                            <v-text-field
                                                                                prepend-inner-icon="mdi-web"
                                                                                hide-details
                                                                                rounded
                                                                                dense
                                                                                filled
                                                                                color="primary"
                                                                                label="Domain"
                                                                                v-model="rankingColURL"></v-text-field>
                                                                            <v-btn rounded outlined class="my-auto ml-2" color="primary" text @click="changeRankingURL()">OK</v-btn>
                                                                        </v-row>
                                                                        <v-row no-gutters>
                                                                            <v-col class="pl-2 pt-2">
                                                                                <v-btn-toggle class="pt-0" v-model="ranking_device" rounded outlined color="primary">
                                                                                    <v-btn outlined x-small>
                                                                                        <v-icon x-small>mdi-cellphone</v-icon>
                                                                                    </v-btn>
                                                                                    <v-btn outlined x-small>
                                                                                        <v-icon x-small>mdi-monitor</v-icon>
                                                                                    </v-btn>
                                                                                    <v-btn outlined x-small>
                                                                                        <v-icon x-small>mdi-tablet</v-icon>
                                                                                    </v-btn>
                                                                                </v-btn-toggle>
                                                                            </v-col>
                                                                        </v-row>
                                                                    </v-list>
                                                                </v-menu>
                                                            </span>
                                                        </span>
                                                    </template>
                                                    <span>{{ header.modifiedTooltip }}</span>
                                                </v-tooltip>
                                            </span>
                                            <!-- serp_items -->
                                            <span v-if="header.value.includes('serp_items')" v-bind:style="{ width: header.width }" class="customColumn" style="display: inline-flex !important">
                                                <v-tooltip v-if="header.modifiedTooltip !== ''" color="primary" top max-width="250px">
                                                    <template v-slot:activator="{ on }">
                                                        <span v-on="on">
                                                            {{ header.modifiedText }}
                                                            <v-icon x-small>mdi-help</v-icon></span
                                                        >
                                                    </template>
                                                    <span>{{ header.modifiedTooltip }}</span>
                                                </v-tooltip>
                                                <span v-if="header.modifiedTooltip === ''" class="mr-2 my-auto">
                                                    {{ header.modifiedText }}
                                                </span>
                                                <span id="serp_items_table_filter">
                                                    <v-menu :close-on-content-click="false" offset-y transition="slide-y-transition" bottom>
                                                        <template v-slot:activator="{ on }">
                                                            <v-btn v-on="on" small icon>
                                                                <div>
                                                                    <v-icon small>mdi-filter-variant</v-icon>
                                                                    <div class="badge pulsate" v-if="columnFilter[header.value].setFilter.length > 0"></div>
                                                                </div>
                                                            </v-btn>
                                                        </template>
                                                        <v-list>
                                                            <div class="catsfiltermenu px-2">
                                                                <!-- @click:clear="getFilterAttributes(header)" -->
                                                                <!-- @focus="getFilterAttributes(header)" -->
                                                                <v-autocomplete
                                                                    hide-details
                                                                    v-model="columnFilter[header.value].setFilter"
                                                                    :items="serpsOfList"
                                                                    filled
                                                                    ref="selectComponent"
                                                                    rounded
                                                                    multiple
                                                                    :loading="false"
                                                                    chips
                                                                    deletable-chips
                                                                    prepend-icon="mdi-filter-variant"
                                                                    color="primary"
                                                                    label="Filter"
                                                                    return-object
                                                                    clearable
                                                                    @input="addColumnFilter(header.value)"
                                                                    item-text="text">
                                                                    <template v-slot:prepend-item>
                                                                        <!-- unselect all -->
                                                                        <v-list-item
                                                                            ripple
                                                                            v-if="columnFilter[header.value].setFilter.length > 0"
                                                                            @click="
                                                                                columnFilter[header.value].setFilter = []
                                                                                addColumnFilter(header.value)
                                                                            ">
                                                                            <v-list-item-action>
                                                                                <v-icon color="primary"> mdi-close-box</v-icon>
                                                                            </v-list-item-action>
                                                                            <v-list-item-content>
                                                                                <v-list-item-title> unselect all </v-list-item-title>
                                                                            </v-list-item-content>
                                                                        </v-list-item>
                                                                        <!-- select all -->
                                                                        <!-- <v-list-item
                                                                            ripple
                                                                            v-if="columnFilter[header.value].setFilter.length == 0"
                                                                            v-on:click="
                                                                                columnFilter[header.value].setFilter = serpsOfList
                                                                                addColumnFilter(header.value)
                                                                            ">
                                                                            <v-list-item-action>
                                                                                <v-icon color="primary"> mdi-checkbox-multiple-marked </v-icon>
                                                                            </v-list-item-action>
                                                                            <v-list-item-content>
                                                                                <v-list-item-title> select all </v-list-item-title>
                                                                            </v-list-item-content>
                                                                        </v-list-item> -->

                                                                        <v-divider class="mt-2"></v-divider>
                                                                    </template>
                                                                    <template v-slot:selection="data">
                                                                        <v-chip dark v-bind="data.attrs" :input-value="data.selected">
                                                                            {{ data.item.tooltip.split(":")[0] }}
                                                                        </v-chip>
                                                                    </template>
                                                                    <!-- <template v-slot:item="data">
                                                            {{ data.item.text }}
                                                        </template> -->
                                                                    <template v-slot:item="data">
                                                                        <v-col class="px-0" cols="2">
                                                                            <v-icon small v-if="data.attrs['aria-selected'] == 'true'" color="grey">mdi-check</v-icon>
                                                                        </v-col>
                                                                        <v-col class="pl-0" cols="10">
                                                                            <v-chip small v-bind="data.attrs">
                                                                                <span>{{ data.item.tooltip.split(":")[0] }} </span> <v-icon class="pl-1" size="20px">${{ data.item.value }}</v-icon>
                                                                            </v-chip>
                                                                        </v-col>
                                                                    </template>
                                                                </v-autocomplete>
                                                                <!-- <v-switch v-model="columnFilter[header.value].include" :label="columnFilter[header.value].include ? 'include' : 'exclude'"></v-switch> -->
                                                            </div>
                                                        </v-list>
                                                    </v-menu>
                                                </span>
                                            </span>

                                            <!-- custom -->
                                            <span v-if="header.value.includes('custom')" v-bind:style="{ width: header.width }" class="customColumn" style="display: inline-flex !important">
                                                <v-tooltip v-if="header.modifiedTooltip !== ''" color="primary" top max-width="250px">
                                                    <template v-slot:activator="{ on }">
                                                        <span class="mr-2" v-on="on">
                                                            {{ header.modifiedText }}
                                                            <v-icon x-small>mdi-help</v-icon></span
                                                        >
                                                    </template>
                                                    <span>{{ header.modifiedTooltip }}</span>
                                                </v-tooltip>
                                                <span v-if="header.modifiedTooltip === ''" class="mr-2 my-auto">
                                                    {{ header.modifiedText }}
                                                </span>
                                                <v-menu v-if="header.customOptions.type === 'Dropdown'" :close-on-content-click="false" offset-y transition="slide-y-transition" bottom>
                                                    <template v-slot:activator="{ on }">
                                                        <v-btn v-on="on" small icon>
                                                            <div>
                                                                <v-icon small>mdi-filter-variant</v-icon>
                                                                <div
                                                                    class="badge pulsate"
                                                                    v-if="!columnFilter[header.value].setFilter ? false : columnFilter[header.value].setFilter.length == 0 ? false : true"></div>
                                                            </div>
                                                        </v-btn>
                                                    </template>
                                                    <v-list>
                                                        <div class="catsfiltermenu px-2">
                                                            <v-autocomplete
                                                                @click:clear="getFilterAttributes(header)"
                                                                @focus="getFilterAttributes(header)"
                                                                hide-details
                                                                v-model="columnFilter[header.value].setFilter"
                                                                :items="selectFilters"
                                                                filled
                                                                ref="selectComponent"
                                                                rounded
                                                                multiple
                                                                :loading="selectLoading"
                                                                chips
                                                                deletable-chips
                                                                prepend-icon="mdi-filter-variant"
                                                                color="primary"
                                                                label="Filter"
                                                                return-object
                                                                clearable
                                                                @input="addColumnFilter(header.value)"
                                                                item-text="text">
                                                                <template v-slot:prepend-item>
                                                                    <!-- unselect all -->
                                                                    <v-list-item
                                                                        ripple
                                                                        v-if="columnFilter[header.value].setFilter.length > 0"
                                                                        @click="
                                                                            columnFilter[header.value].setFilter = []
                                                                            addColumnFilter(header.value)
                                                                        ">
                                                                        <v-list-item-action>
                                                                            <v-icon color="primary"> mdi-close-box</v-icon>
                                                                        </v-list-item-action>
                                                                        <v-list-item-content>
                                                                            <v-list-item-title> unselect all </v-list-item-title>
                                                                        </v-list-item-content>
                                                                    </v-list-item>
                                                                    <!-- select all -->
                                                                    <v-list-item
                                                                        ripple
                                                                        v-if="columnFilter[header.value].setFilter.length == 0"
                                                                        v-on:click="
                                                                            columnFilter[header.value].setFilter = header.customOptions.entries.filter((x) => x.text !== '-')
                                                                            addColumnFilter(header.value)
                                                                        ">
                                                                        <v-list-item-action>
                                                                            <v-icon color="primary"> mdi-checkbox-multiple-marked </v-icon>
                                                                        </v-list-item-action>
                                                                        <v-list-item-content>
                                                                            <v-list-item-title> select all </v-list-item-title>
                                                                        </v-list-item-content>
                                                                    </v-list-item>

                                                                    <v-divider class="mt-2"></v-divider>
                                                                </template>
                                                                <template v-slot:selection="data">
                                                                    <v-chip dark v-bind="data.attrs" :input-value="data.selected" :color="data.item.color ? data.item.color.hex : ''">
                                                                        {{ data.item.text }}
                                                                    </v-chip>
                                                                </template>
                                                                <!-- <template v-slot:item="data">
                                                            {{ data.item.text }}
                                                        </template> -->
                                                                <template v-slot:item="data">
                                                                    <v-col class="px-0" cols="2">
                                                                        <v-icon small v-if="data.attrs['aria-selected'] == 'true'" color="grey">mdi-check</v-icon>
                                                                    </v-col>
                                                                    <v-col class="pl-0" cols="10">
                                                                        <v-chip dark v-if="data.item.id != 0" small v-bind="data.attrs" :color="data.item.color ? data.item.color.hex : ''">
                                                                            <span class="white--text">{{ data.item.text }}</span>
                                                                        </v-chip>
                                                                        <span v-else> {{ data.item.text }}</span>
                                                                    </v-col>
                                                                </template>
                                                            </v-autocomplete>
                                                        </div>
                                                    </v-list>
                                                </v-menu>
                                                <!-- <v-menu :close-on-content-click="false" offset-y transition="slide-y-transition" bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn class="ml-auto my-auto" v-on="on" small icon v-bind="attrs">
                                                    <v-icon color="white" class="headermenuicon" small>mdi-chevron-down</v-icon>
                                                </v-btn>
                                            </template>
                                            <v-list rounded dense>
                                                <v-list-item @click=""> <v-list-item-title>Show Pie Chart</v-list-item-title> </v-list-item>
                                                <v-list-item @click="header.active = !header.active">
                                                    <v-list-item-title>Hide Column</v-list-item-title>
                                                </v-list-item>
                                                <v-list-item @click=""> <v-list-item-title>Edit Column</v-list-item-title> </v-list-item>
                                            </v-list>
                                        </v-menu> -->
                                            </span>
                                            <!-- cats -->
                                            <span v-if="header.value === 'cats'" v-bind:style="{ width: header.width }">
                                                <v-tooltip color="primary" top max-width="250px">
                                                    <template v-slot:activator="{ on }">
                                                        <span v-on="on">
                                                            {{ header.modifiedText }}
                                                            <v-icon x-small>mdi-help</v-icon></span
                                                        >
                                                    </template>
                                                    <span>{{ header.modifiedTooltip }}</span>
                                                </v-tooltip>
                                                <v-menu :close-on-content-click="false" offset-y transition="slide-y-transition" bottom>
                                                    <template v-slot:activator="{ on }">
                                                        <v-btn v-on="on" :disabled="catsLoading" small icon>
                                                            <div>
                                                                <v-icon small>mdi-filter-variant</v-icon>
                                                                <div class="badge pulsate" v-if="columnFilter[header.value].setFilter.length > 0"></div>
                                                            </div>
                                                        </v-btn>
                                                    </template>
                                                    <v-list>
                                                        <div class="catsfiltermenu px-2">
                                                            <v-autocomplete
                                                                hide-details
                                                                v-model="columnFilter[header.value].setFilter"
                                                                :items="catsOfList"
                                                                filled
                                                                ref="selectComponent"
                                                                rounded
                                                                multiple
                                                                chips
                                                                deletable-chips
                                                                prepend-icon="mdi-filter-variant"
                                                                color="primary"
                                                                label="Filter for categories"
                                                                return-object
                                                                clearable
                                                                @change="fixselectError"
                                                                @input="addColumnFilter(header.value)"
                                                                item-text="category_name">
                                                                <template v-slot:prepend-item>
                                                                    <!-- unselect all -->
                                                                    <v-list-item
                                                                        ripple
                                                                        v-if="columnFilter[header.value].setFilter.length > 0"
                                                                        @click="
                                                                            columnFilter[header.value].setFilter = []
                                                                            addColumnFilter(header.value)
                                                                        ">
                                                                        <v-list-item-action>
                                                                            <v-icon color="primary"> mdi-close-box</v-icon>
                                                                        </v-list-item-action>
                                                                        <v-list-item-content>
                                                                            <v-list-item-title> unselect all </v-list-item-title>
                                                                        </v-list-item-content>
                                                                    </v-list-item>
                                                                    <!-- select all -->
                                                                    <!-- <v-list-item
                                                                        ripple
                                                                        v-if="columnFilter[header.value].setFilter.length == 0"
                                                                        v-on:click="
                                                                            columnFilter[header.value].setFilter = catsOfList
                                                                            addColumnFilter(header.value)
                                                                        ">
                                                                        <v-list-item-action>
                                                                            <v-icon color="primary"> mdi-checkbox-multiple-marked </v-icon>
                                                                        </v-list-item-action>
                                                                        <v-list-item-content>
                                                                            <v-list-item-title> select all </v-list-item-title>
                                                                        </v-list-item-content>
                                                                    </v-list-item> -->

                                                                    <v-divider class="mt-2"></v-divider>
                                                                </template>
                                                                <template v-slot:selection="data">
                                                                    <v-chip dark v-bind="data.attrs" :input-value="data.selected" :color="data.item.color ? data.item.color.hex : ''">
                                                                        {{ data.item.category_name }}
                                                                    </v-chip>
                                                                </template>
                                                            </v-autocomplete>
                                                        </div>
                                                    </v-list>
                                                </v-menu>
                                            </span>
                                        </span>
                                    </template>
                                    <!-- table body -->
                                    <template v-slot:body="{ items }">
                                        <tbody>
                                            <tr v-if="items.length" v-for="(item, itemIndex) in items" :key="itemIndex" :class="item.selected ? 'highlighted' : ''">
                                                <td class="selecttd">
                                                    <v-icon v-if="!item.selected" @click="selectItem(itemIndex, item)" style="margin: 0px; padding: 0px">mdi-checkbox-blank-outline</v-icon>
                                                    <v-icon v-else @click="selectItem(itemIndex, item)" style="margin: 0px; padding: 0px">mdi-checkbox-marked</v-icon>
                                                </td>
                                                <td v-for="(header, headerIndex) in showRow()" :key="headerIndex">
                                                    <div v-if="header.group === 0" v-bind:style="{ width: header.width }">
                                                        <!-- ------- -->
                                                        <!-- customs -->
                                                        <!-- ------- -->
                                                        <div v-if="header.custom">
                                                            <!-- ----------- -->
                                                            <!-- dropdown td -->
                                                            <!-- ----------- -->
                                                            <div id="dropdown_td">
                                                                <div v-if="header.customOptions.type === 'Dropdown'" class="mx-auto" id="dropdown_td">
                                                                    <v-autocomplete
                                                                        :disabled="!$helpers.isActionAllowed(41, user_rights) || mountedOn == 'SharedResearch'"
                                                                        :items="header.customOptions.entries"
                                                                        dense
                                                                        chips
                                                                        small-chips
                                                                        :menu-props="{ closeOnContentClick: true }"
                                                                        hide-details
                                                                        flat
                                                                        :multiple="allowMultipleTags"
                                                                        placeholder="-"
                                                                        append-icon="mdi-chevron-down"
                                                                        solo
                                                                        color=""
                                                                        attach
                                                                        return-object
                                                                        v-model="item.customs[header.value]"
                                                                        @input="addCustomEntries(item, header.value, true, false)">
                                                                        <template v-slot:selection="data">
                                                                            <v-chip v-if="data.item.id != 0" dark small v-bind="data.attrs" :input-value="data.selected" :color="data.item.color.hex">
                                                                                {{ data.item.text }}
                                                                            </v-chip>
                                                                            <span v-else> {{ data.item.text }}</span>
                                                                        </template>
                                                                        <template v-slot:item="data">
                                                                            <v-col class="px-0" cols="2">
                                                                                <v-icon small v-if="data.attrs['aria-selected'] == 'true'" color="grey">mdi-check</v-icon>
                                                                            </v-col>
                                                                            <v-col class="px-0" cols="10">
                                                                                <v-chip dark v-if="data.item.id != 0" small v-bind="data.attrs" :color="data.item.color.hex">
                                                                                    <span class="white--text dots">{{ data.item.text }}</span>
                                                                                </v-chip>
                                                                                <span class="pl-2" v-else> {{ data.item.text }}</span></v-col
                                                                            >
                                                                        </template>
                                                                    </v-autocomplete>
                                                                </div>
                                                                <div v-if="header.customOptions.type === 'Text'" class="mx-auto">
                                                                    <v-text-field
                                                                        :disabled="!$helpers.isActionAllowed(41, user_rights) || mountedOn == 'SharedResearch'"
                                                                        type="text"
                                                                        hide-details
                                                                        solo
                                                                        placeholder="-"
                                                                        flat
                                                                        class="tabeltextfld"
                                                                        style="font-size: 0.9em"
                                                                        dense
                                                                        v-model="item.customs[header.value]"
                                                                        @input="addCustomEntries(item, header.value, true, false)"></v-text-field>
                                                                </div>
                                                                <div v-if="header.customOptions.type === 'Number'" class="mx-auto">
                                                                    <v-text-field
                                                                        :disabled="!$helpers.isActionAllowed(41, user_rights) || mountedOn == 'SharedResearch'"
                                                                        type="number"
                                                                        hide-details
                                                                        solo
                                                                        placeholder="-"
                                                                        flat
                                                                        style="font-size: 0.9em"
                                                                        dense
                                                                        v-model="item.customs[header.value]"
                                                                        @input="addCustomEntries(item, header.value, true, false)"></v-text-field>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <!-- ------- -->
                                                        <!-- keyword -->
                                                        <!-- ------- -->
                                                        <div v-if="header.value === 'keyword'">
                                                            <div class="kw_table_td">
                                                                <div class="mr-1">
                                                                    <span> {{ item[header.value] }}</span>
                                                                </div>
                                                                <div class="ml-auto" style="display: flex">
                                                                    <div v-if="mountedOn != 'Research' && mountedOn != 'SharedResearch'" class="savedicon_wrapper">
                                                                        <v-tooltip top>
                                                                            <template v-slot:activator="{ on }">
                                                                                <v-icon v-on="on" class="savedicon" small :color="item.lists ? 'primary' : item.in_clipboard ? '#e6a940' : 'white'"
                                                                                    >mdi-content-save</v-icon
                                                                                >
                                                                            </template>
                                                                            <span v-if="item.lists">
                                                                                <div class="saved-in">Saved in:</div>
                                                                                <ul>
                                                                                    <li v-for="(list, index) in item.lists" :key="index">{{ list }}</li>
                                                                                </ul>
                                                                            </span>
                                                                            <span v-else-if="item.in_clipboard"><div class="saved-in">in clipboard</div></span>
                                                                            <span v-else><div class="saved-in">Not saved</div></span>
                                                                        </v-tooltip>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <!-- ------- -->
                                                        <!--  media count  -->
                                                        <!-- ------- -->
                                                        <div v-if="header.value === 'media_count' && !columnsDisabled.media_count">
                                                            {{ $helpers.formatNumber(item.media_count) }}
                                                        </div>
                                                        <!-- ------- -->
                                                        <!--  avgsv  -->
                                                        <!-- ------- -->

                                                        <div v-if="header.value === 'avgsv' && !columnsDisabled.avgsv" id="avgsv_cell">
                                                            <v-chip
                                                                class="avgsvcell"
                                                                outlined
                                                                color="primary"
                                                                :style="'background-color: rgb(237 239 241 /' + ($vuetify.theme.light == false ? '35%)' : '10%)') + '!important'"
                                                                >{{ $helpers.formatNumber(item[header.value]) }}</v-chip
                                                            >
                                                        </div>
                                                        <!-- ------- -->
                                                        <!--  trend  -->
                                                        <!-- ------- -->
                                                        <div
                                                            v-if="header.value === 'trend' && !columnsDisabled.trend"
                                                            id="sparkline_cell"
                                                            v-bind="(sparklineData = { data: $helpers.getSparklineData(item, 12) })">
                                                            <v-row no-gutters>
                                                                <v-col class="d-flex" style="position: relative">
                                                                    <v-sparkline
                                                                        :value="sparklineData.data"
                                                                        color="primary"
                                                                        padding="2"
                                                                        style="max-width: 80px; max-height: 55px; position: absolute; bottom: -10px"
                                                                        smooth="20"
                                                                        gradient-direction="top"
                                                                        :fill="false"></v-sparkline>
                                                                    <v-sparkline
                                                                        :value="sparklineData.data"
                                                                        style="max-width: 80px; max-height: 55px; position: absolute; bottom: -10px"
                                                                        :gradient="gradient"
                                                                        padding="2"
                                                                        smooth="20"
                                                                        gradient-direction="top"
                                                                        :fill="true"></v-sparkline> </v-col
                                                            ></v-row>
                                                        </div>
                                                        <!-- ---------- -->
                                                        <!-- categories -->
                                                        <!-- ---------- -->
                                                        <!-- <div v-if="header.value === 'cats' && !columnsDisabled.cats" id="cat_cell">
                                                            <v-chip></v-chip>
                                                        </div> -->

                                                        <!-- ---------- -->
                                                        <!-- categories -->
                                                        <!-- ---------- -->
                                                        <div v-if="header.value === 'cats' && !columnsDisabled.cats" id="cat_cell">
                                                            <!--  if categories exists for keyword -->
                                                            <v-menu v-if="item.cats" top offset-y :close-on-content-click="false">
                                                                <template v-slot:activator="{ on, attrs }">
                                                                    <div style="white-space: nowrap">
                                                                        <v-chip class="mr-1 px-0" small @click="showAllCats = !showAllCats"
                                                                            ><v-icon small v-if="showAllCats">mdi-chevron-right</v-icon><v-icon small v-else>mdi-chevron-left</v-icon></v-chip
                                                                        >
                                                                        <v-btn
                                                                            style="border-radius: 20px"
                                                                            v-bind="attrs"
                                                                            small
                                                                            v-if="item.cats"
                                                                            v-on="$helpers.isActionAllowed(14, user_rights) ? on : null"
                                                                            elevation="0">
                                                                            <span v-if="showAllCats">
                                                                                <span v-for="(cat, index) in item.cats" v-bind:key="index">
                                                                                    <span v-if="index == item.active_cat_index">
                                                                                        <span v-for="(cat, i) in item.cats[index]" v-bind:key="cat.category_name">
                                                                                            <v-icon v-if="i && i < item.cats[index].length" small>mdi-chevron-right</v-icon>
                                                                                            {{ cat.category_name }}
                                                                                        </span>
                                                                                    </span>
                                                                                </span>
                                                                            </span>
                                                                            <span v-else>
                                                                                <span v-for="(cat, index) in item.cats" v-bind:key="index">
                                                                                    <span v-if="index == item.active_cat_index">
                                                                                        {{ item.cats[index][item.cats[index].length - 1].category_name }}
                                                                                    </span>
                                                                                </span>
                                                                            </span>
                                                                            <v-icon> mdi-chevron-down </v-icon>
                                                                        </v-btn>
                                                                    </div>
                                                                </template>

                                                                <v-list dense rounded class="pb-0">
                                                                    <div class="catmenu">
                                                                        <div>
                                                                            <div class="catmenutitle mb-0">
                                                                                {{ item.keyword }}
                                                                            </div>
                                                                            <div class="catmenusubtitle">Change or add categories</div>
                                                                        </div>
                                                                    </div>
                                                                    <v-divider class="pb-1"></v-divider>
                                                                    <v-list-item
                                                                        @click="switchCat(item, cat)"
                                                                        v-for="(cat, index) in item.cats"
                                                                        v-bind:key="cat.category_name"
                                                                        :style="index == 0 ? '{background-color: grey !important}' : ''"
                                                                        class="catlistitem pointerhover mx-1">
                                                                        <v-icon v-if="index == item.active_cat_index" fab color="primary" class="mr-2">mdi-check-circle</v-icon>
                                                                        <v-icon v-else fab class="mr-2">mdi-checkbox-blank-circle</v-icon>
                                                                        <v-list-item-title>
                                                                            <span class="mx-0" v-for="(c, index) in cat" v-bind:key="c.catlevel">
                                                                                <v-icon v-if="index < cat.length && index" small>mdi-chevron-right</v-icon>
                                                                                <v-chip small class="mx-0 catchip">{{ c.category_name }}</v-chip>
                                                                            </span>
                                                                            <v-btn @click="removeCat(item, cat)" class="ml-1" x-small icon><v-icon small>mdi-close</v-icon></v-btn>
                                                                        </v-list-item-title>
                                                                    </v-list-item>

                                                                    <v-divider></v-divider>

                                                                    <v-dialog max-width="500" v-model="display[item.keyword]">
                                                                        <template v-slot:activator="{ on, attrs }">
                                                                            <v-row>
                                                                                <v-btn class="ma-2 mx-auto" v-bind="attrs" small outlined color="primary" v-on="on"> add category </v-btn>
                                                                            </v-row>
                                                                        </template>
                                                                        <v-card class="mt-0">
                                                                            <v-card-title>
                                                                                <div class="catdialog">
                                                                                    <div class="catdialogtitle mb-0">Add Categories</div>
                                                                                    <div class="catdialogsubtitle">note: keywords can be assigned to multiple categories</div>
                                                                                </div>
                                                                            </v-card-title>
                                                                            <v-card-text>
                                                                                <v-autocomplete
                                                                                    v-model="selectedCats"
                                                                                    :search-input="catsSearch"
                                                                                    cache-items
                                                                                    :items="catData"
                                                                                    filled
                                                                                    chips
                                                                                    hide-details
                                                                                    multiple
                                                                                    :menu-props="{
                                                                                        closeOnContentClick: false
                                                                                    }"
                                                                                    deletable-chips
                                                                                    @focus="closeTextField"
                                                                                    item-text="category_name"
                                                                                    item-value="data"
                                                                                    return-object
                                                                                    prepend-icon="mdi-file-tree"
                                                                                    label="Search for categories">
                                                                                    <template v-slot:prepend-item>
                                                                                        <v-list-item class="py-0" v-if="showCatAddBtn" ripple @click="openTextField">
                                                                                            <v-list-item-action>
                                                                                                <v-icon color="primary"> mdi-plus-circle-outline </v-icon>
                                                                                            </v-list-item-action>
                                                                                            <v-list-item-content>
                                                                                                <v-list-item-title> Create a new category </v-list-item-title>
                                                                                            </v-list-item-content>
                                                                                        </v-list-item>

                                                                                        <!-- else -->
                                                                                        <div v-if="showCatTextFld == true" class="px-7">
                                                                                            <v-row>
                                                                                                <v-icon color="primary" class="pr-5"> mdi-plus-circle-outline </v-icon>

                                                                                                <!-- Textfield for adding keywords -->
                                                                                                <v-text-field ref="cattoadd" v-model="catToAdd" label="Category name"> </v-text-field>
                                                                                            </v-row>
                                                                                            <v-row>
                                                                                                <v-btn class="ml-auto" @click="closeTextField" x-small outlined color="red">cancel</v-btn>
                                                                                                <v-btn class="ml-2" x-small @click="createNewCat(item)" outlined color="primary">create</v-btn>
                                                                                            </v-row>
                                                                                        </div>
                                                                                        <v-divider class="mt-2"></v-divider>
                                                                                    </template>

                                                                                    <template v-slot:no-data>
                                                                                        <v-list-item>
                                                                                            <v-list-item-content>
                                                                                                <v-list-item-title>
                                                                                                    <center>
                                                                                                        <kbd>No category found! </kbd>
                                                                                                    </center>
                                                                                                </v-list-item-title>
                                                                                            </v-list-item-content>
                                                                                        </v-list-item>
                                                                                    </template>
                                                                                </v-autocomplete>
                                                                            </v-card-text>
                                                                            <v-card-actions>
                                                                                <v-btn outlined class="mx-auto" @click="addCategories(item)" color="primary">add categories</v-btn>
                                                                            </v-card-actions>
                                                                        </v-card>
                                                                    </v-dialog>
                                                                </v-list>
                                                            </v-menu>
                                                            <div v-else-if="catsLoading" class="mx-auto" style="width: 250px !important">
                                                                <v-progress-circular :size="20" :width="2" color="primary" indeterminate></v-progress-circular>
                                                            </div>

                                                            <!-- keyword has no categories  -->
                                                            <v-dialog v-else max-width="500" v-model="display[item.keyword]">
                                                                <template v-slot:activator="{ on, attrs }">
                                                                    <v-btn
                                                                        v-if="$helpers.isActionAllowed(14, user_rights)"
                                                                        style="border-radius: 20px"
                                                                        v-bind="attrs"
                                                                        x-small
                                                                        fab
                                                                        v-on="on"
                                                                        elevation="0">
                                                                        <v-icon color="grey">mdi-plus-circle</v-icon>
                                                                    </v-btn>
                                                                </template>
                                                                <v-card class="mt-0">
                                                                    <v-card-title>
                                                                        <div class="catdialog">
                                                                            <div class="catdialogtitle mb-0">Add Categories</div>
                                                                            <div class="catdialogsubtitle">note: keywords can be assigned to multiple categories</div>
                                                                        </div>
                                                                    </v-card-title>
                                                                    <v-card-text class="pb-10">
                                                                        <v-autocomplete
                                                                            v-model="selectedCats"
                                                                            :search-input="catsSearch"
                                                                            cache-items
                                                                            :items="catData"
                                                                            filled
                                                                            chips
                                                                            hide-details
                                                                            multiple
                                                                            :menu-props="{ closeOnContentClick: false }"
                                                                            deletable-chips
                                                                            @focus="closeTextField"
                                                                            item-text="category_name"
                                                                            item-value="data"
                                                                            return-object
                                                                            prepend-icon="mdi-file-tree"
                                                                            label="Search for categories">
                                                                            <template v-slot:prepend-item>
                                                                                <v-list-item class="py-0" v-if="showCatAddBtn" ripple @click="openTextField">
                                                                                    <v-list-item-action>
                                                                                        <v-icon color="primary"> mdi-plus-circle-outline </v-icon>
                                                                                    </v-list-item-action>
                                                                                    <v-list-item-content>
                                                                                        <v-list-item-title> Create a new category </v-list-item-title>
                                                                                    </v-list-item-content>
                                                                                </v-list-item>

                                                                                <!-- else  -->
                                                                                <div v-if="showCatTextFld == true" class="px-7">
                                                                                    <v-row>
                                                                                        <v-icon color="primary" class="pr-5"> mdi-plus-circle-outline </v-icon>

                                                                                        <!-- Textfield for adding keywords -->
                                                                                        <v-text-field ref="cattoadd" v-model="catToAdd" label="Category name"> </v-text-field>
                                                                                    </v-row>
                                                                                    <v-row>
                                                                                        <v-btn class="ml-auto" @click="closeTextField" x-small outlined color="red">cancel</v-btn>
                                                                                        <v-btn class="ml-2" x-small @click="createNewCat(item)" outlined color="primary">create</v-btn>
                                                                                    </v-row>
                                                                                </div>
                                                                                <v-divider class="mt-2"></v-divider>
                                                                            </template>

                                                                            <template v-slot:no-data>
                                                                                <v-list-item>
                                                                                    <v-list-item-content>
                                                                                        <v-list-item-title>
                                                                                            <center>
                                                                                                <kbd>No category found! </kbd>
                                                                                            </center>
                                                                                        </v-list-item-title>
                                                                                    </v-list-item-content>
                                                                                </v-list-item>
                                                                            </template>
                                                                        </v-autocomplete>
                                                                    </v-card-text>
                                                                    <v-card-actions>
                                                                        <v-btn class="mx-auto" @click="addCategories(item)" color="primary">add categories</v-btn>
                                                                    </v-card-actions>
                                                                </v-card>
                                                            </v-dialog>
                                                        </div>
                                                        <!-- ------------ -->
                                                        <!--  serp_items  -->
                                                        <!-- ------------ -->
                                                        <div v-if="header.value === 'serp_items' && !columnsDisabled.serp_items" id="serp_items_cell">
                                                            <SERPTypesCell :serp_items="item[header.value]" :showAll="showAllSERPItems" @toggle="showAllSERPItems = !showAllSERPItems"></SERPTypesCell>
                                                        </div>
                                                        <!-- --------------------- -->
                                                        <!--   keyword_difficulty  -->
                                                        <!-- --------------------- -->
                                                        <div v-if="header.value === 'keyword_difficulty' && !columnsDisabled.keyword_difficulty" id="kw_difficulty_cell">
                                                            <Difficulty :difficulty="item[header.value]" icon="$organic"></Difficulty>
                                                        </div>
                                                        <!-- -------------------------- -->
                                                        <!--    adwords_competition     -->
                                                        <!-- -------------------------- -->
                                                        <div v-if="header.value === 'adwords_competition' && !columnsDisabled.adwords_competition" id="competition_cell">
                                                            <Difficulty :difficulty="{ difficulty: item.adwords_competition }" icon="$paid"></Difficulty>
                                                        </div>
                                                        <!-- ----------------- -->
                                                        <!--    Peak Month     -->
                                                        <!-- ----------------- -->
                                                        <div v-if="header.value === 'peak' && !columnsDisabled.peak">
                                                            {{ item.peak }}
                                                        </div>
                                                        <!-- ------------- -->
                                                        <!--    Season     -->
                                                        <!-- ------------- -->
                                                        <div v-if="header.value === 'season' && !columnsDisabled.season">
                                                            <span v-if="item.season">{{ item.season.season }}</span>
                                                        </div>
                                                    </div>
                                                    <div v-if="header.value === 'ranking' && !columnsDisabled.ranking" id="ranking_cell" class="d-flex">
                                                        <div v-if="item[header.value]" class="ml-auto">
                                                            <v-menu class="rankingmenu" style="border-radius: 10px !important" offset-y rounded>
                                                                <template v-slot:activator="{ on }">
                                                                    <!-- Hier wird der Aktivatorinhalt platziert, z.B. ein Button -->
                                                                    <span v-on="on">
                                                                        <v-chip outlined :color="item[header.value].color ? item[header.value].color : ''" class="qchip pointer_cursor">{{
                                                                            item[header.value].position ? item[header.value].position : "-"
                                                                        }}</v-chip>
                                                                    </span>
                                                                </template>
                                                                <v-list style="border-radius: 10px !important">
                                                                    <!-- Ihr bisheriger Tooltip-Inhalt geht hier hinein -->
                                                                    <div class="px-3 py-1">
                                                                        <div class="d-flex mb-2">
                                                                            <span class="mr-1 my-auto quarolbl">{{ item[header.value] ? "Position:" : "No ranking" }}</span>
                                                                            <v-chip small outlined :color="item[header.value].color ? item[header.value].color : ''" class="qchip my-auto">{{
                                                                                item[header.value].position ? item[header.value].position : "-"
                                                                            }}</v-chip>
                                                                            <!-- <v-divider vertical></v-divider> -->
                                                                            <v-spacer class="mr-2"></v-spacer>
                                                                            <v-icon class="mr-1" small>mdi-clock-time-eight-outline</v-icon>
                                                                            <span class="my-auto" style="color: #727272; font-size: 14px; text-transform: uppercase">
                                                                                {{ item[header.value].datetime.substring(0, 10) }}</span
                                                                            >
                                                                        </div>
                                                                        <v-divider></v-divider>
                                                                        <v-row>
                                                                            <!-- <v-col style="max-width: 70px !important"> </v-col> -->
                                                                            <v-col style="white-space: nowrap">
                                                                                <div>
                                                                                    {{ item[header.value].title }}
                                                                                </div>
                                                                                <v-chip color="primary" @click="openURL(item[header.value].url)">
                                                                                    <span v-if="item[header.value].url" class="d-flex">
                                                                                        <img
                                                                                            class="mr-2 my-auto"
                                                                                            :src="'https://s2.googleusercontent.com/s2/favicons?domain=' + item[header.value].url + '&sz=42'"
                                                                                            alt="" />
                                                                                        <span class="my-auto">{{ item[header.value].url }}</span>
                                                                                        <v-icon class="ml-1">mdi-link</v-icon>
                                                                                    </span>
                                                                                    <span v-else>no ranking</span>
                                                                                </v-chip>
                                                                            </v-col>
                                                                        </v-row>
                                                                    </div>
                                                                </v-list>
                                                            </v-menu>
                                                        </div>
                                                        <div v-else class="ml-auto">
                                                            <v-tooltip v-if="item[header.value] == null" top>
                                                                <template v-slot:activator="{ on }"> <span v-on="on"> - </span> </template>
                                                                <span>This keyword is currently not monitored</span>
                                                            </v-tooltip>
                                                        </div>
                                                    </div>
                                                    <!-- ---------- -->
                                                    <!--    cpc     -->
                                                    <!-- ---------- -->
                                                    <div v-if="header.value === 'adwords_cpc' && !columnsDisabled.adwords_cpc" id="cpc_cell">
                                                        <!-- <v-chip v-if="$helpers.exchangeUsdToEuro(item.adwords_cpc) !== '-'" class="mx-0" color="#f5f5f5" small
                                                        >{{ $helpers.exchangeUsdToEuro(item.adwords_cpc) + "€" }}
                                                    </v-chip> -->

                                                        <v-chip
                                                            v-if="
                                                                mountedOn == 'SharedResearch'
                                                                    ? $helpers.exchangeUsdToEuro(item.adwords_cpc, usdRate) !== '-'
                                                                    : $helpers.exchangeUsdToEuro(item.adwords_cpc) !== '-'
                                                            "
                                                            class="mx-0"
                                                            color="#f5f5f5"
                                                            small
                                                            light
                                                            >{{
                                                                mountedOn == "SharedResearch"
                                                                    ? $helpers.exchangeUsdToEuro(item.adwords_cpc, usdRate) + "€"
                                                                    : $helpers.exchangeUsdToEuro(item.adwords_cpc) + "€"
                                                            }}
                                                        </v-chip>
                                                        <span v-else>-</span>
                                                        <!-- <span v-else>-</span> -->
                                                    </div>
                                                    <!-- ---------- -->
                                                    <!--    cpc high     -->
                                                    <!-- ---------- -->
                                                    <div v-if="header.value === 'adwords_cpc_high' && !columnsDisabled.adwords_cpc_high" id="cpc_cell">
                                                        <!-- <v-chip v-if="$helpers.exchangeUsdToEuro(item.adwords_cpc) !== '-'" class="mx-0" color="#f5f5f5" small
                                                        >{{ $helpers.exchangeUsdToEuro(item.adwords_cpc) + "€" }}
                                                    </v-chip> -->

                                                        <v-chip
                                                            v-if="
                                                                mountedOn == 'SharedResearch'
                                                                    ? $helpers.exchangeUsdToEuro(item.adwords_cpc_high, usdRate) !== '-'
                                                                    : $helpers.exchangeUsdToEuro(item.adwords_cpc_high) !== '-'
                                                            "
                                                            class="mx-0"
                                                            color="#f5f5f5"
                                                            small
                                                            light
                                                            >{{
                                                                mountedOn == "SharedResearch"
                                                                    ? $helpers.exchangeUsdToEuro(item.adwords_cpc_high, usdRate) + "€"
                                                                    : $helpers.exchangeUsdToEuro(item.adwords_cpc_high) + "€"
                                                            }}
                                                        </v-chip>
                                                        <span v-else>-</span>
                                                        <!-- <span v-else>-</span> -->
                                                    </div>
                                                    <!-- ---------- -->
                                                    <!--    cpc low     -->
                                                    <!-- ---------- -->
                                                    <div v-if="header.value === 'adwords_cpc_low' && !columnsDisabled.adwords_cpc_low" id="cpc_cell">
                                                        <!-- <v-chip v-if="$helpers.exchangeUsdToEuro(item.adwords_cpc) !== '-'" class="mx-0" color="#f5f5f5" small
                                                        >{{ $helpers.exchangeUsdToEuro(item.adwords_cpc) + "€" }}
                                                    </v-chip> -->

                                                        <v-chip
                                                            v-if="
                                                                mountedOn == 'SharedResearch'
                                                                    ? $helpers.exchangeUsdToEuro(item.adwords_cpc_low, usdRate) !== '-'
                                                                    : $helpers.exchangeUsdToEuro(item.adwords_cpc_low) !== '-'
                                                            "
                                                            class="mx-0"
                                                            color="#f5f5f5"
                                                            small
                                                            light
                                                            >{{
                                                                mountedOn == "SharedResearch"
                                                                    ? $helpers.exchangeUsdToEuro(item.adwords_cpc_low, usdRate) + "€"
                                                                    : $helpers.exchangeUsdToEuro(item.adwords_cpc_low) + "€"
                                                            }}
                                                        </v-chip>
                                                        <span v-else>-</span>
                                                        <!-- <span v-else>-</span> -->
                                                    </div>

                                                    <!-- ---------- -->
                                                    <!--  Searches  -->
                                                    <!-- ---------- -->
                                                    <div v-if="header.group === 1">
                                                        <div v-if="headerIndex == showRow().length - 1">
                                                            <v-tooltip v-if="item[header.value] == null" top>
                                                                <template v-slot:activator="{ on }"> tba<v-icon v-on="on" small>mdi-help-circle-outline</v-icon> </template>
                                                                <span>The monthly search volume are not yet available for this keyword. It will be released in the near future.</span>
                                                            </v-tooltip>
                                                            <span v-else>{{ $helpers.formatNumber(item[header.value]) }}</span>
                                                        </div>
                                                        <div v-else>
                                                            <span v-if="item[header.value] == null">-</span>
                                                            <span v-else>{{ $helpers.formatNumber(item[header.value]) }}</span>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>

                                            <!-- <center></center> -->
                                            <div v-if="items.length == 0" class="pl-5">
                                                <v-row>
                                                    <v-col>
                                                        <v-chip>no data</v-chip>
                                                        <span v-if="$store.state.active_filter.length">
                                                            <v-btn class="mt-3" rounded color="primary" @click="clearGlobalFilter">
                                                                <v-icon class="mr-2">mdi-filter-variant-remove</v-icon>
                                                                clear filter
                                                            </v-btn>
                                                        </span>
                                                    </v-col>
                                                </v-row>
                                            </div>
                                            <!-- <center></center> -->
                                        </tbody>
                                    </template>
                                </v-data-table>
                            </v-layout>
                        </v-tab-item>
                        <v-tab-item>
                            <keyword-trends
                                :process="processTrends"
                                :keywords="keywords"
                                :mountedOn="mountedOn"
                                :resize="trends_resize"
                                :cardData="cardData"
                                @fetchSERP="fetchSERP"
                                @addToChart="addToChart"
                                @addToClipboard="addToClipboard"
                                @exportTrends="initExportTrends"></keyword-trends>
                        </v-tab-item>
                    </v-tabs>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    // import { UAParser } from "ua-parser-js"

    import SelectMenu from "../SelectMenu"
    import Difficulty from "./Difficulty"
    import SERPTypesCell from "./SERPTypesCell"
    import draggable from "vuedraggable"
    import DeleteDialog from "../dialogs/DeleteDialog.vue"
    import ShareDialog from "../dialogs/shareDialog/Sharedialog"
    import PasteOptionsDialog from "./PasteOptionsDialog"
    import RedoClusterDialog from "../dialogs/RedoClusterDialog.vue"
    import KeywordTrends from "../keyword-trends/KeywordTrends"
    import { montharray_complete } from "../../components/helper/global.js"

    export default {
        name: "KeywordTable",
        props: {
            resize: Number,
            columnsDisabled: Object,
            cardData: Object,
            catsLoading: Boolean,
            loading: Boolean,
            decryptedPlan: String,
            keywords: Array,
            catsOfList: Array,
            serpsOfList: Array,
            headers: Object,
            mountedOn: String,
            media_counts: Boolean,
            sharedCompanyId: undefined,
            usdRate: undefined,
            tourPushToClipboard: Boolean,
            tourSelectKeywords: Number,
            trendClipboardKw: Array,
            collection_information: undefined,
            addToSerpDisabled: Boolean,
            siteKeywordsLocation: Object,
            new_data: Number
        },
        components: {
            draggable,
            SelectMenu,
            DeleteDialog,
            RedoClusterDialog,
            Difficulty,
            SERPTypesCell,
            ShareDialog,
            KeywordTrends,
            PasteOptionsDialog
        },
        data() {
            return {
                rules: {
                    required: (value) => !!value || "Required.",
                    counter: (value) => value.length >= 3 || "min 3 characters",
                    duplicate: (value) => {
                        return validateDuplicate(value) || "duplicate"
                    }
                },
                pasteOptionsDialog: { show: false },
                editOptionDialog: { show: false, text: "", type: 0 },
                dropDownOptionsTableHeaders: [
                    { text: "OPTION", align: "start", sortable: false, value: "option" },
                    { text: "ACTION", align: "start", sortable: false, value: "action" }
                ],
                // ranking_device: 0,
                new_ranking_device: null,
                // rankingColURL: "www.xxxlutz.de",
                newRankingColURL: "",
                tablepage: 1,
                notification: { show: false },
                processTrends: 0,
                selectedSavedHeaders: [],
                savedHeadersSearch: "",
                savedHeaderValue: "",
                deleteSavedColum: false,
                showAllSERPItems: false,
                redoClusteringDialog: false,
                selectedEntriesClustering: [],
                doAutoCluster: false,
                addColumnToSettings: false,
                tab: null,
                keywordCount: 0,
                tableloaded: false,
                addColumnLoading: false,
                selectFilters: [],
                selectLoading: false,
                allowMultipleTags: false,
                addHeaderDescription: false,
                showColorPicker: false,
                colorIndex: 0,
                customColumnTypes: ["Text", "Number", "Dropdown"],
                latestCreatedHeader: {},
                newColumn: {
                    active: true,
                    sortable: true,
                    group: 0,
                    text: "",
                    modifiedText: "",
                    value: "custom_",
                    tooltip: "",
                    modifiedTooltip: "",
                    // width: "100",
                    align: "left",
                    custom: true,
                    filter_attributes: [],
                    customOptions: {
                        type: "Dropdown",
                        entries: [
                            {
                                text: "-",
                                color: {
                                    hex: ""
                                },
                                id: 0
                            },
                            {
                                text: "Option 1",
                                color: {
                                    hex: "#d34eed"
                                },
                                id: 1
                            },
                            {
                                text: "Option 2",
                                color: {
                                    hex: "#fc8403"
                                },
                                id: 2
                            }
                        ]
                    }
                },
                selectedEntries: {},
                headersToShow: [],
                allHeaderValues: [],
                svHeadersIndex: 0,
                dragging: false,
                transition: 0,
                isMove: false,
                showTopPagination: false,
                windowSize: {
                    x: 0,
                    y: 0
                },
                deleteDialog: false,
                selectedDeleteItem: null,
                deleteLoading: false,
                showDownloadProgress: false,
                showAddColumnSection: false,
                showCatAddBtn: true,
                catToAdd: "",
                showCatTextFld: false,
                display: {},
                catData: [],
                catsSearch: "",
                attributes: [],
                attributesSearch: "",
                modifyColumnsDialog: false,
                checkChangesDialog: false,
                addColumnDialog: false,
                modifyHeaderDialog: false,
                shareDialog: false,
                shareItem: {},
                modifyHeaderIndex: 0,
                restoreStandardDialog: false,
                doctypes: [
                    {
                        value: "Microsoft Excel (.xlsx)",
                        data: "json2xls",
                        icon: "mdi-microsoft-excel"
                    },
                    {
                        value: "Comma separated values (.csv)",
                        data: "json2csv",
                        icon: "mdi-file-delimited"
                    }
                ],
                doctype: {
                    value: "Microsoft Excel (.xlsx)",
                    data: "json2xls",
                    icon: "mdi-microsoft-excel"
                },
                selectedLocation: localStorage.selectedLocation ? JSON.parse(localStorage.selectedLocation) : { loc: "Germany", value: "2276", cc: "de", ads_lang_code: "de", serp_lang_code: "de" },
                tableBtnDisabled: false,
                auth: "Basic " + window.btoa(process.env.VUE_APP_AUTH),
                downloadDialog: false,
                trends_export: { status: false, keywords: [], headers: [] },
                actions: [
                    { title: "Export", icon: "mdi-cloud-download", id: 1 },
                    { title: "Modify Columns", icon: "mdi-table-settings", id: 2 },
                    {
                        title: "Create Columns",
                        icon: "mdi-table-column-plus-before",
                        id: 3
                    },
                    { title: "Delete Selected", icon: "mdi-delete", id: 4 },
                    { title: "Share", icon: "mdi-share-variant", id: 5 }
                ],
                selected: [],
                search: "",
                selectedCats: [],
                cat_for_filter: [],
                dropdownFilter: {},
                // serpFilter: {},

                columnFilter: {
                    serp_items: {
                        include: true,
                        setFilter: []
                    },
                    cats: {
                        include: true,
                        setFilter: []
                    }
                    // customs: {
                    //     include: true,
                    //     setFilter: []
                    // }
                },
                showAllCats: false,
                gradient: [this.$vuetify.theme.themes.light.sparklineGradient1, this.$vuetify.theme.themes.light.sparklineGradient2, this.$vuetify.theme.themes.light.sparklineGradient3],
                clusterLoading: false,
                sortBy: [],
                active_tab: 0,
                tabmodules: [
                    {
                        name: "All Keywords",
                        component: "-"
                    },
                    {
                        name: "Keyword Trends",
                        component: "KeywordTrends"
                    }
                ],
                trends_resize: 0
            }
        },
        watch: {
            active_tab(newValue) {
                if (newValue == 1) {
                    this.trends_resize++
                    return
                }
                this.onResize()
            },
            new_data() {
                console.log("NEWWWWWWWWWEWEWEWEW")
                this.tablepage = 1
            },
            keywords() {
                this.selected = []
                this.sortBy = []

                this.keywords.map((x) => {
                    x.selected = false
                })
                if (this.processTrends == 0) {
                    this.processTrends++
                }
                if (this.mountedOn !== "SharedResearch") {
                    this.$helpers.fetchListsOfKeywords(this.keywords, localStorage.company_id, this.siteKeywordsLocation.value)
                    this.$helpers.keywordsInClipboard(this.keywords, this.clipboard_keywords)
                }
                this.$helpers.addPeakAndSeason(this.keywords)
            },
            resize() {
                this.onResize()
            },

            keywords2(val) {
                // console.log("test watch")
            },
            tourSelectKeywords(val) {
                if (this.selected.length == 0) {
                    let cloneArr = this.keywords.slice(0)
                    this.selected = cloneArr
                }
            },
            tourPushToClipboard(val) {
                this.addToClipboard()
            },
            catsSearch(val) {
                val && val !== this.selectedCats
            },
            trendClipboardKw(val) {
                this.addToClipboard(val)
            },
            // active_filter(val) {
            //     console.log("test watch")
            //     this.filterColumns()
            // },
            active_filter: {
                handler(val) {
                    // if (val[0]) console.log(val[0].field.id)
                    if (val.findIndex((x) => x.field.id == 7 && x.action.value == "includes") > -1) {
                        this.columnFilter.serp_items.setFilter = val[val.findIndex((x) => x.field.id == 7 && x.action.value == "includes")].value
                    } else {
                        this.columnFilter.serp_items.setFilter = []
                    }
                    if (val.findIndex((x) => x.field.id == 3 && x.action.value == "includes") > -1) {
                        this.columnFilter.cats.setFilter = val[val.findIndex((x) => x.field.id == 3 && x.action.value == "includes")].value
                    } else {
                        this.columnFilter.cats.setFilter = []
                    }

                    let custom_headers = this.headers.modifiedHeaders.filter((header) => header.value !== undefined && header.value.includes("custom_"))
                    // let header_index = 0
                    if (custom_headers.length) {
                        for (let index = 0; index < custom_headers.length; index++) {
                            if (val.findIndex((x) => x.field.id == custom_headers[index].value && x.action.value == "includes") > -1) {
                                // console.log(val.findIndex((x) => x.field.id == custom_headers[index].value && x.action.value == "includes"))
                                this.columnFilter[val[val.findIndex((x) => x.field.id == custom_headers[index].value && x.action.value == "includes")].field.id].setFilter =
                                    val[val.findIndex((x) => x.field.id == custom_headers[index].value && x.action.value == "includes")].value
                            } else {
                                this.columnFilter[custom_headers[index].value].setFilter = []
                            }
                            // if (this.columnFilter[custom_headers[header_index + 1]] == undefined) break
                            // header_index++
                        }
                    }

                    this.filterColumns()
                },
                deep: true
            },
            headers: {
                handler(val) {
                    // console.log("ACTIVE", this.headers.modifiedHeaders[this.headers.modifiedHeaders.findIndex((p) => p.value === "media_count")].active)
                    this.svHeadersIndex = this.headers.modifiedHeaders.findIndex((p) => p.name === "sv_headers")
                    this.changeHeaderWidthForMode()
                    this.setHeadersToShow()
                    this.$store.dispatch("CHANGE_HEADER_ITEMS", { items: this.headers })
                },
                deep: true
            }
        },
        computed: {
            rankingColURL: {
                // getter
                get: function () {
                    return this.$store.state.monitoring_domain
                },
                // setter
                set: function (newValue) {
                    this.newRankingColURL = newValue
                }
            },
            ranking_device: {
                // getter
                get: function () {
                    return this.$store.state.monitoring_device
                },
                // setter
                set: function (newValue) {
                    this.new_ranking_device = newValue
                }
            },
            active_filter: {
                // getter
                get: function () {
                    return this.$store.state.active_filter
                },
                // setter
                set: function (newValue) {
                    this.$store.dispatch("CHANGE_ACTIVE_FILTER", newValue)
                }
            },
            //needed for not working watcher on this.keywords
            keywords2() {
                this.setCustomColumnDefaultValues(false)
                this.onResize()
                this.fetchAllCats()
                this.keywordCount = this.keywords ? this.keywords.length : 0
                return this.keywords
            },
            user_rights() {
                return this.$store.state.user_rights
            },
            addToChartDisabled() {
                return this.selected.length > 10
            },
            clipboard_keywords() {
                return this.$store.state.clipboard_keywords
            },
            user_settings: {
                get: function () {
                    // this.onResize()
                    return this.$store.state.user_settings
                },
                set: function (newValue) {
                    this.$store.dispatch("CHANGE_USER_SETTINGS", newValue)
                    this.changeHeaderWidthForMode()
                    // this.onResize()
                }
            },
            saved_headers() {
                return this.$store.state.saved_headers
            }
        },
        methods: {
            changeRankingURL() {
                let user_settings = this.$store.state.user_settings
                user_settings.monitoring_domain = this.newRankingColURL ? this.newRankingColURL : this.rankingColURL
                user_settings.monitoring_device = this.new_ranking_device ? this.new_ranking_device : this.ranking_device
                this.$store.dispatch("CHANGE_USER_SETTINGS", user_settings)
                this.$store.dispatch("SET_MONITORING_DOMAIN", this.newRankingColURL ? this.newRankingColURL : this.rankingColURL)
                this.$store.dispatch("SET_MONITORING_DEVICE", this.new_ranking_device ? this.new_ranking_device : this.ranking_device)
                this.$refs.rankingMenu[0].isActive = false // close menu
                this.$emit("changeRankingURL", this.newRankingColURL ? this.newRankingColURL : this.rankingColURL, this.new_ranking_device ? this.new_ranking_device : this.ranking_device)
            },
            openURL(url) {
                window.open(url, "_blank")
            },
            startFetchWithSendToKeywords() {
                this.$emit("startFetchWithSendToKeywords")
            },
            editSavedColumn() {
                console.log("EDIT")
                console.log(this.savedHeaderValue)
                let title = document.getElementById("modified-column-title")._value
                let tooltip = document.getElementById("modified-column-description")._value
                this.$refs.titlemodifyform.reset()
                this.$refs.tooltipmodifyform.reset()
                // if (title && title.length > 0 && title.length < 3) {
                //     this.createNotification("The column title must contain at least 3 characters ", "mdi-alert-circle-outline", "#FCBA03")
                //     return
                // }
                if (this.validateColumnInput(title, "edit") == false) {
                    return
                }

                let headers = this.saved_headers
                console.log(headers)
                if (title) {
                    headers[headers.findIndex((x) => x.value == this.savedHeaderValue)].modifiedText = title
                }
                if (tooltip) {
                    headers[headers.findIndex((x) => x.value == this.savedHeaderValue)].modifiedTooltip = tooltip
                }
                console.log(headers[headers.findIndex((x) => x.value == this.savedHeaderValue)])
                // this.$store.dispatch("DELETE_USER_SETTINGS_HEADER", headers)
                this.modifyHeaderDialog = false
                this.savedHeaderValue = ""
            },

            openModifyColumnDialog(value) {
                console.log("MODIFY")
                console.log(value)
                this.savedHeaderValue = value

                this.modifyHeaderDialog = true
                event.stopPropagation()
            },

            clearGlobalFilter() {
                this.active_filter.splice(0, this.active_filter.length)
            },
            addColumnFilter(type) {
                // if (type == "serp") {
                // console.log(this.serpFilter)
                // let custom_headers = this.headers.modifiedHeaders.filter((header) => header.value !== undefined && header.value.includes("custom_"))
                let active_filter = this.$store.state.active_filter

                let column_name = type.slice(7)
                let filter = {
                    field:
                        type == "serp_items"
                            ? { type: "array", text: "SERP Items", id: 7 }
                            : type == "cats"
                            ? { type: "array", text: "Category", id: 3 }
                            : { type: "array", text: column_name, id: type, custom: true },
                    action: { text: "includes", value: "includes", type: "array" },
                    value: type == "serp_items" ? this.columnFilter.serp_items.setFilter : type == "cats" ? this.columnFilter.cats.setFilter : this.columnFilter[type].setFilter
                }

                // if no serpfilter set, remove from active filters
                if (this.columnFilter[type].setFilter.length == 0) {
                    let index = active_filter.findIndex((f) => filter.field.id == f.field.id)
                    if (index > -1) {
                        active_filter.splice(index, 1)
                    }
                }
                if (this.columnFilter[type].setFilter.length) {
                    let index = active_filter.findIndex((f) => filter.field.id == f.field.id && filter.action.value == f.action.value)
                    if (index === -1) {
                        // add new filter
                        active_filter.push(filter)
                    } else {
                        // replace existing filter
                        active_filter.splice(index, 1)
                        active_filter.push(filter)
                    }
                }
                this.$store.dispatch("CHANGE_ACTIVE_FILTER", active_filter)
            },

            optionTextChanged(newOption, headerValue, type) {
                //Type 0 = new column, type 1 Collection Column. type = saved column
                if (type !== 1) return //only do if editing table column
                this.$emit("modifiedDropDownOptions", newOption, headerValue)
                //TODO option for saved column and new column
            },
            /**
             * is called from parent when keywords deleted
             * clears selection & sets new Keyword Count
             */
            keywordsDeleted(keywordCount) {
                this.selected = []
                this.keywords.map((x) => {
                    x.selected = false
                })
                this.clearFilter()
                this.keywordCount = keywordCount
            },
            /**
             * is called to set kw count
             * only for suggests
             */
            setKwCount(kwCount) {
                this.keywordCount = kwCount
            },
            clearFilter() {
                let filteredStatus = this.isFiltered()
                if (filteredStatus == "searchFilter") {
                    this.search = ""
                } else if (filteredStatus == "columnFilter") {
                    this.search = ""
                    this.columnFilter["cats"].setFilter = []
                    this.columnFilter["serp_items"].setFilter = []
                    let keys = Object.keys(this.columnFilter)
                    keys.map((x) => {
                        if (x.includes("custom_")) {
                            this.columnFilter[x].setFilter = []
                        }
                    })

                    this.$store.state.active_filter = []
                    this.filterColumns()
                }
            },
            isFiltered() {
                let keys = Object.keys(this.columnFilter)
                let resp = false

                if (this.search && this.search.length > 0) resp = "searchFilter"
                if (this.$store.state.active_filter.length > 0) resp = "columnFilter"

                return resp
            },
            editColumn() {
                let title = document.getElementById("modified-column-title")._value
                let tooltip = document.getElementById("modified-column-description")._value
                this.$refs.titlemodifyform.reset()
                this.$refs.tooltipmodifyform.reset()

                if (this.validateColumnInput(title, "edit") == false) {
                    return
                }

                if (title) {
                    this.headers.modifiedHeaders[this.modifyHeaderIndex].modifiedText = title
                }
                if (tooltip) {
                    this.headers.modifiedHeaders[this.modifyHeaderIndex].modifiedTooltip = tooltip
                }
                this.changedHeaders()
                this.modifyHeaderDialog = false
            },
            /**
             * sets used atrributes for column filter
             * @param {Object} [header]
             */
            getFilterAttributes(header) {
                this.selectLoading = true
                let used_attributes = this.keywords.map((keyword) => keyword.customs[header.value])
                // get unique
                let unique = []
                used_attributes.map((used_attribute) => {
                    if (unique.findIndex((entry) => entry.text == used_attribute.text) === -1) {
                        unique.push(used_attribute)
                    }
                })

                // Sort "-" empty entry to top
                let copy = [...new Array(unique)]
                copy = copy[0]
                let sorted = [...copy.filter((attribute) => attribute.id == 0), ...copy.filter((attribute) => attribute.id != 0)]
                // console.log(sorted)
                sorted.map((entry) => {
                    let headerCustomEntry = header.customOptions.entries.filter((optionEntry) => optionEntry.text == entry.text)
                    entry.color.hex = headerCustomEntry[0].color.hex
                })
                this.selectFilters = sorted
                this.selectLoading = false
                return
            },
            prepareDeleteColumn(column, state) {
                // 0 -> table columns
                // 1 -> saved columns
                this.deleteSavedColum = false
                this.selectedDeleteItem = column
                this.deleteDialog = true
                if (state == 1) this.deleteSavedColum = true
            },
            validateDuplicateRule(value) {
                return !this.allHeaderValues.includes(value) || "value is in use"
            },
            validateDropDownItemDuplicateRule(value) {
                if (this.newColumn.customOptions.entries.length > 1) {
                    let counter = this.newColumn.customOptions.entries.length - this.newColumn.customOptions.entries.length + 1
                    this.newColumn.customOptions.entries.map((entry) => {
                        if (entry.text === value) counter += -1
                    })
                    return !(counter < 0)
                }
                return true
            },
            selectItem(index, item) {
                let visibleIndex = this.keywords.indexOf(item)
                this.keywords[visibleIndex].selected = !this.keywords[visibleIndex].selected
                if (this.keywords[visibleIndex].selected) {
                    this.selected.push(item)
                } else {
                    this.selected = this.selected.filter((obj) => obj.keyword !== item.keyword)
                }
            },
            selectPage(event) {
                let selectedPageItems = event.items.filter((x) => x.selected)
                if (event.value && selectedPageItems.length == 0) {
                    //select

                    event.items.map((x) => {
                        x.selected = true
                        this.selected.push(x)
                    })
                } else {
                    //unselect
                    event.items.map((x) => {
                        const index = this.selected.findIndex((selectedRow) => selectedRow.keyword == x.keyword)
                        if (index > -1) {
                            this.selected.splice(index, 1)
                        }
                        x.selected = false
                    })
                }
            },
            // ------------------------
            //  Remove columns:
            // ------------------------
            delColumn(headerParam) {
                console.log(headerParam)
                //delete custom entry on rows
                this.keywords.map((x) => {
                    delete x.customs[headerParam]
                })

                this.deleteLoading = true
                this.headers.standardHeaders = JSON.parse(JSON.stringify(this.headers.standardHeaders.filter((header) => header.value !== headerParam)))
                this.headers.modifiedHeaders = JSON.parse(JSON.stringify(this.headers.modifiedHeaders.filter((header) => header.value !== headerParam)))
                this.modifyHeaderDialog = false
                this.modifyHeaderIndex = 0
                this.changedHeaders()

                this.deleteLoading = false
                this.deleteDialog = false
            },
            // --------------------------------
            // Check and update custom entries
            // to database
            // --------------------------------
            /**
             * * check if entries exist on passed item
             * * @param {object} [item=keywordItem]
             * * @param {object} [header=headerItem]
             */
            hasCustomEntries(item, header) {
                return item.customs === undefined || item.customs[header.value] === undefined
            },
            /**
             * * emit new custom entries to Research.vue component.
             * * @param {object} [item=keywordItem]
             * * @param {string} [headerValue=header name]
             * * @param {boolean} [isBackend]
             * * @param {boolean} [isArray]
             */

            addCustomEntries(item, headerValue, isBackend, isArray) {
                let data = this.selected.length > 0 ? this.selected : [item]
                this.$emit("addCustomEntries", data, item.customs[headerValue], headerValue, isBackend, isArray)
                this.rerenderKeywordTable()
            },
            validateColumnInput(text, dialog) {
                if (text && text.indexOf(".") > -1) {
                    this.notification = this.$helpers.createNotification("The column title cannot contain '.'! Please try something else! ", "mdi-alert-circle-outline", "#FCBA03")
                    return false
                } else if (dialog == "edit" ? false : !text) {
                    this.notification = this.$helpers.createNotification("Please provide a column title! ", "mdi-alert-circle-outline", "#FCBA03")
                    return false
                } else if (dialog == "edit" ? false : text.length < 3) {
                    this.notification = this.$helpers.createNotification("The column title must contain at least 3 characters!", "mdi-alert-circle-outline", "#FCBA03")
                    return false
                } else if (!text ? false : this.validateDuplicateRule(text) != true) {
                    this.notification = this.$helpers.createNotification("The column title is already in use! ", "mdi-alert-circle-outline", "#FCBA03")
                    return false
                } else {
                    return true
                }
            },
            openAddColumnDialog() {
                let customheaders = this.headers.modifiedHeaders.filter((x) => x.value !== undefined && x.value.includes("custom_"))
                if (customheaders.length < 20) {
                    this.addColumnDialog = true
                } else {
                    this.notification = this.$helpers.createNotification(
                        "Maximum 20 custom columns allowed! This collection has already " + customheaders.length + " custom columns.",
                        "mdi-alert-circle-outline",
                        "#FCBA03"
                    )
                }
            },

            // ------------------------
            //  Add columns:
            // ------------------------
            /**
             * * Add new column to Collection
             */
            addColumn() {
                // #new-column-title
                this.addColumnLoading = true
                this.newColumn.text = document.getElementById("new-column-title")._value
                if (this.newColumn.customOptions.type == "Number") this.newColumn.width = "135px"
                if (this.newColumn.customOptions.type == "Text") this.newColumn.width = "135px"
                if (this.addHeaderDescription)
                    this.newColumn.tooltip = document.getElementById("new-column-description")._value == undefined ? "" : document.getElementById("new-column-description")._value

                if (this.validateColumnInput(this.newColumn.text, "add") == false) {
                    this.addColumnLoading = false
                    return
                }
                if (!this.newColumn.customOptions.type) {
                    this.notification = this.$helpers.createNotification("Please select a column type! ", "mdi-alert-circle-outline", "#FCBA03")
                    this.addColumnLoading = false
                    return
                }

                if (this.newColumn.customOptions.type !== "Dropdown") delete this.newColumn.customOptions.entries
                this.clearFilter()
                this.setCustomColumnDefaultValues(this.newColumn)

                this.newColumn.modifiedText = JSON.parse(JSON.stringify(this.newColumn.text))
                this.newColumn.modifiedTooltip = JSON.parse(JSON.stringify(this.newColumn.tooltip))
                this.newColumn.value = JSON.parse(JSON.stringify("custom_" + this.newColumn.text))

                let svHeadersIndexStandard = this.headers.standardHeaders.findIndex((p) => p.name === "sv_headers")
                this.headers.modifiedHeaders.splice(this.svHeadersIndex, 0, this.newColumn)
                this.headers.standardHeaders.splice(svHeadersIndexStandard, 0, this.newColumn)
                this.latestCreatedHeader = this.newColumn
                if (this.addColumnToSettings) {
                    let found_headers = this.saved_headers.filter((x) => x.value == this.newColumn.value)
                    if (found_headers.length) {
                        this.notification = this.$helpers.createNotification("Could not add column to saved columns, the column title is already in use! ", "mdi-alert-circle-outline", "#FCBA03")
                    } else {
                        // let saved_headers = this.$store.state.saved_headers
                        // saved_headers.unshift(this.newColumn)
                        // console.log(saved_headers)
                        this.$store.dispatch("CHANGE_USER_SETTINGS_HEADER", this.newColumn)
                    }
                }

                this.changedHeaders()
                this.restoreAddColumnDialog()

                // unselect selected after creating column
                this.selected = []
                this.keywords.map((x) => {
                    x.selected = false
                })
                if (this.doAutoCluster) {
                    this.autoCluster(this.latestCreatedHeader)
                    return
                }
                this.addColumnLoading = false
                this.addColumnDialog = false
                this.addColumnToSettings = false
            },
            deleteSavedColumn(value) {
                console.log(value)
                console.log(JSON.stringify(this.saved_headers.length))
                let new_headers = this.saved_headers.filter((x) => x.value !== value)
                console.log(JSON.stringify(new_headers.length))
                this.$store.dispatch("DELETE_USER_SETTINGS_HEADER", new_headers)
            },
            addSavedColumn() {
                let customheaders = this.headers.modifiedHeaders.filter((x) => x.value !== undefined && x.value.includes("custom_"))
                let new_headers = this.selectedSavedHeaders
                console.log(new_headers)
                if (!new_headers.length) return

                if (customheaders.length + new_headers.length < 20) {
                    this.addColumnLoading = true
                    this.clearFilter()
                    for (let index = 0; index < new_headers.length; index++) {
                        if (this.headers.modifiedHeaders.findIndex((x) => x.value == new_headers[index].value) == -1) {
                            // this.setCustomColumnDefaultValues(new_headers[index])

                            let svHeadersIndexModified = this.headers.modifiedHeaders.findIndex((p) => p.name === "sv_headers")
                            let svHeadersIndexStandard = this.headers.standardHeaders.findIndex((p) => p.name === "sv_headers")
                            this.headers.modifiedHeaders.splice(svHeadersIndexModified, 0, new_headers[index])
                            this.headers.standardHeaders.splice(svHeadersIndexStandard, 0, new_headers[index])
                            this.latestCreatedHeader = new_headers[index]
                            // this.autoCluster(new_headers[index])
                        }
                    }

                    this.changedHeaders()

                    for (let index = 0; index < new_headers.length; index++) {
                        this.setCustomColumnDefaultValues(new_headers[index])
                        this.autoCluster(new_headers[index])
                    }

                    // unselect selected after creating column
                    this.selected = []
                    this.keywords.map((x) => {
                        x.selected = false
                    })

                    this.addColumnLoading = false
                    this.addColumnDialog = false
                    this.addColumnToSettings = false
                    this.selectedSavedHeaders = []
                } else {
                    this.notification = this.$helpers.createNotification(
                        "Maximum 20 custom columns allowed! This collection has already " + customheaders.length + " custom columns.",
                        "mdi-alert-circle-outline",
                        "#FCBA03"
                    )
                }
            },
            /**
             * sets entry for keyword  ist string is found
             * entries is null if clusterg should be done for all entries
             */
            autoCluster(header, entries = null) {
                this.clusterLoading = true
                if (!entries) {
                    entries = header.customOptions.entries
                }
                if (header.customOptions.type != "Dropdown") return
                header.customOptions.entries.map((option) => {
                    if (option.id != 0) {
                        let rowsToModify = this.keywords.filter((row) => row.keyword.toLowerCase().includes(option.text.toLowerCase()))
                        if (rowsToModify.length > 0) {
                            this.$emit("addCustomEntries", rowsToModify, option, header.value, true, false)
                        }
                    }
                })
                this.clusterLoading = false
                this.addColumnLoading = false
                this.addColumnDialog = false
            },
            customSort(items, index, isDesc) {
                if (!index[0]) return items
                const clickedHeader = this.headers.modifiedHeaders.filter((header) => header.value == index[0])

                items.sort((a, b) => {
                    if (index[0].substring(0, 6) == "custom") {
                        //  custom columns
                        const clickedHeaderType = clickedHeader[0].customOptions.type
                        let isnan = clickedHeaderType == "Dropdown" ? isNaN(a.customs[index[0]].text) : isNaN(a.customs[index[0]])
                        if (!isnan) {
                            // String
                            if (!isDesc[0]) {
                                if (a.customs[index[0]] == undefined || a.customs[index[0]] == "") return 1
                                if (b.customs[index[0]] == undefined || b.customs[index[0]] == "") return -1
                                return clickedHeaderType == "Dropdown" ? a.customs[index[0]].text - b.customs[index[0]].text : a.customs[index[0]] - b.customs[index[0]]
                            } else {
                                if (a.customs[index[0]] == undefined || a.customs[index[0]] == "") return 1
                                if (b.customs[index[0]] == undefined || b.customs[index[0]] == "") return -1
                                return clickedHeaderType == "Dropdown" ? b.customs[index[0]].text - a.customs[index[0]].text : b.customs[index[0]] - a.customs[index[0]]
                            }
                        } else {
                            // Number
                            if (!isDesc[0]) {
                                if (a.customs[index[0]] == undefined || a.customs[index[0]] == "") return 1
                                if (b.customs[index[0]] == undefined || b.customs[index[0]] == "") return -1
                                return clickedHeaderType == "Dropdown" ? (a.customs[index[0]].text < b.customs[index[0]].text ? -1 : 1) : a.customs[index[0]] < b.customs[index[0]] ? -1 : 1
                            } else {
                                if (a.customs[index[0]] == undefined || a.customs[index[0]] == "") return 1
                                if (b.customs[index[0]] == undefined || b.customs[index[0]] == "") return -1
                                return clickedHeaderType == "Dropdown" ? (b.customs[index[0]].text < a.customs[index[0]].text ? -1 : 1) : b.customs[index[0]] < a.customs[index[0]] ? -1 : 1
                            }
                        }
                    } else {
                        if (index[0] == "keyword_difficulty") {
                            //  kw difficulty
                            if (!isDesc[0]) {
                                if (a[index[0]] && b[index[0]]) return a[index[0]].difficulty < b[index[0]].difficulty ? -1 : 1
                                if (!a[index[0]]) return -1
                                if (!b[index[0]]) return 1
                            } else {
                                if (a[index[0]] && b[index[0]]) return b[index[0]].difficulty < a[index[0]].difficulty ? -1 : 1
                                if (!a[index[0]]) return 1
                                if (!b[index[0]]) return -1
                            }
                        } else if (index[0] == "peak") {
                            // peak
                            let months = montharray_complete
                            let aindex = months.indexOf(a[index[0]]) > -1 ? 12 - months.indexOf(a[index[0]]) : 0
                            let bindex = months.indexOf(b[index[0]]) > -1 ? 12 - months.indexOf(b[index[0]]) : 0

                            if (!isDesc[0]) {
                                return aindex < bindex ? -1 : 1
                            } else {
                                return bindex < aindex ? -1 : 1
                            }
                        } else if (index[0] == "season") {
                            // season
                            let months = montharray_complete
                            let astartmonth = ""
                            let avalue = ""
                            if (a[index[0]]) {
                                avalue = a[index[0]].season.split("-")[0]
                                avalue = avalue.slice(0, -1)
                            }
                            astartmonth = avalue

                            let bstartmonth = ""
                            let bvalue = ""
                            if (b[index[0]]) {
                                bvalue = b[index[0]].season.split("-")[0]
                                bvalue = bvalue.slice(0, -1)
                            }
                            bstartmonth = bvalue

                            let aindex = months.indexOf(astartmonth) > -1 ? 12 - months.indexOf(astartmonth) : 0
                            let bindex = months.indexOf(bstartmonth) > -1 ? 12 - months.indexOf(bstartmonth) : 0

                            if (!isDesc[0]) {
                                return aindex < bindex ? -1 : 1
                            } else {
                                return bindex < aindex ? -1 : 1
                            }
                        } else {
                            //  standard columns
                            if (!isNaN(a[index[0]])) {
                                // String
                                if (!isDesc[0]) {
                                    return a[index[0]] - b[index[0]]
                                } else {
                                    return b[index[0]] - a[index[0]]
                                }
                            } else {
                                // Number
                                if (!isDesc[0]) {
                                    return a[index[0]] < b[index[0]] ? -1 : 1
                                } else {
                                    return b[index[0]] < a[index[0]] ? -1 : 1
                                }
                            }
                        }
                    }
                })
                return items
            },
            /**
             * * Restore newColumn variable
             */
            restoreAddColumnDialog() {
                if (this.$refs.titleform) this.$refs.titleform.reset()
                if (this.$refs.descrform) this.$refs.descrform.reset()

                console.log("resetted")
                this.newColumn = {
                    active: true,
                    sortable: true,
                    group: 0,
                    text: "",
                    modifiedText: "",
                    value: "custom_",
                    tooltip: "",
                    modifiedTooltip: "",
                    // width: "100",
                    align: "",
                    custom: true,
                    customOptions: {
                        type: "",
                        entries: [
                            {
                                text: "-",
                                color: {
                                    hex: ""
                                },
                                id: 0
                            },
                            {
                                text: "Option 1",
                                color: {
                                    hex: "#d34eed"
                                },
                                id: 1
                            },
                            {
                                text: "Option 2",
                                color: {
                                    hex: "#fc8403"
                                },
                                id: 2
                            }
                        ]
                    }
                }
            },
            removeEntryOfColumn(index, state) {
                // state = 0 -> addColumnDialog
                // state = 1 -> modifyColumnsDialog - edit header column
                // state = 2 -> modifyColumnsDialog - edit saved column

                if (state === 0) {
                    // 2 -> default '-' und mindestens ein entry
                    if (this.newColumn.customOptions.entries.length > 2) {
                        this.newColumn.customOptions.entries = [...this.newColumn.customOptions.entries.slice(0, index), ...this.newColumn.customOptions.entries.slice(index + 1)]
                    } else {
                        this.createNotification("At least one option must be set!", "mdi-alert-circle-outline", "#FCBA03")
                    }
                } else if (state === 1) {
                    if (this.headers.modifiedHeaders[this.modifyHeaderIndex].customOptions.entries.length > 2) {
                        this.headers.modifiedHeaders[this.modifyHeaderIndex].customOptions.entries = [
                            ...this.headers.modifiedHeaders[this.modifyHeaderIndex].customOptions.entries.slice(0, index),
                            ...this.headers.modifiedHeaders[this.modifyHeaderIndex].customOptions.entries.slice(index + 1)
                        ]
                    } else {
                        this.createNotification("At least one option must be set!", "mdi-alert-circle-outline", "#FCBA03")
                    }
                } else if (state === 2) {
                    if (this.saved_headers[this.saved_headers.findIndex((x) => x.value == this.savedHeaderValue)].customOptions.entries.length > 2) {
                        this.saved_headers[this.saved_headers.findIndex((x) => x.value == this.savedHeaderValue)].customOptions.entries = [
                            ...this.saved_headers[this.saved_headers.findIndex((x) => x.value == this.savedHeaderValue)].customOptions.entries.slice(0, index),
                            ...this.saved_headers[this.saved_headers.findIndex((x) => x.value == this.savedHeaderValue)].customOptions.entries.slice(index + 1)
                        ]
                    } else {
                        this.createNotification("At least one option must be set!", "mdi-alert-circle-outline", "#FCBA03")
                    }
                }
            },
            addEntryToColumn(state) {
                // state = 0 -> addColumnDialog
                // state = 1 -> modifyColumnsDialog - edit header column
                // state = 2 -> modifyColumnsDialog - edit saved column
                let randomColorHex = [...Array(6)].map(() => Math.floor(Math.random() * 16).toString(16)).join("")
                if (state === 0) {
                    let newE = {
                        text: "Option " + this.newColumn.customOptions.entries.length,
                        color: { hex: "#" + randomColorHex },
                        id: this.newColumn.customOptions.entries.length + 1
                    }
                    this.newColumn.customOptions.entries.splice(1, 0, newE)
                } else if (state === 1) {
                    this.headers.modifiedHeaders[this.modifyHeaderIndex].customOptions.entries.splice(1, 0, {
                        text: "Option " + this.headers.modifiedHeaders[this.modifyHeaderIndex].customOptions.entries.length,
                        color: { hex: "#" + randomColorHex },
                        id: this.headers.modifiedHeaders[this.modifyHeaderIndex].customOptions.entries.length + 1
                    })
                    console.log(this.headers.modifiedHeaders[this.modifyHeaderIndex])
                } else if (state === 2) {
                    this.saved_headers[this.saved_headers.findIndex((x) => x.value == this.savedHeaderValue)].customOptions.entries.splice(1, 0, {
                        text: "Option " + this.saved_headers[this.saved_headers.findIndex((x) => x.value == this.savedHeaderValue)].customOptions.entries.length,
                        color: { hex: "#" + randomColorHex },
                        id: this.saved_headers[this.saved_headers.findIndex((x) => x.value == this.savedHeaderValue)].customOptions.entries.length + 1
                    })
                    console.log(this.saved_headers[this.saved_headers.findIndex((x) => x.value == this.savedHeaderValue)])
                }
            },
            // ------------------------
            //  modify columns:
            // ------------------------
            /**
             * * sort searches headers. Jan - Dec | Dec -Jan
             * * only concerns searches headers
             * * sort === true -> Sort ASC
             * * sort === false -> Sort DESC
             * * calls function changedHeaders()
             */
            setSearchesHeadersSort() {
                this.svHeadersIndex = this.headers.modifiedHeaders.findIndex((p) => p.name === "sv_headers")
                // this.headers.options.sort
                //     ? this.headers.modifiedHeaders[this.svHeadersIndex].columns.sort((a, b) => a.value - b.value)
                //     : this.headers.modifiedHeaders[this.svHeadersIndex].columns.sort((a, b) => b.value - a.value)
                this.changedHeaders()
            },
            /**
             * * restore standard series of headers.
             * * calls function changedHeaders()
             */
            restoreStandard() {
                console.log("standard: ", this.headers.standardHeaders)
                console.log("modified: ", this.headers.modifiedHeaders)
                let svHeadersIndexStandard = this.headers.standardHeaders.findIndex((p) => p.name === "sv_headers")
                let svHeadersIndexModified = this.headers.modifiedHeaders.findIndex((p) => p.name === "sv_headers")
                if (this.headers.standardHeaders[svHeadersIndexStandard].columns.length == 0) {
                    let svcopy = JSON.parse(JSON.stringify(this.headers.modifiedHeaders[svHeadersIndexModified].columns))
                    this.headers.standardHeaders[svHeadersIndexStandard].columns = svcopy.map((x) => {
                        x.active = true
                        x.modifiedText = x.text
                        return x
                    })
                }

                this.headers.modifiedHeaders = JSON.parse(JSON.stringify(this.headers.standardHeaders))
                console.log("restored: ", this.headers.modifiedHeaders)
                this.headers.options = { active: true, sort: true }
                this.changedHeaders()
            },
            /**
             * * hide passed header group
             * * @param {string} [headerGroup=sv_headers | standCustom]
             * * calls function changedHeaders()
             */
            hideHeaders(headerGroup) {
                if (headerGroup === "sv_headers") {
                    console.log(this.headers.modifiedHeaders[this.svHeadersIndex])
                    this.headers.modifiedHeaders[this.svHeadersIndex].columns.map((header) => (header.active = this.headers.modifiedHeaders[this.svHeadersIndex].active))
                } else if (headerGroup === "standCustom") {
                    this.headers.modifiedHeaders.map((header, index) => {
                        console.log(header)
                        if (index !== this.svHeadersIndex && header.value !== "keyword") header.active = this.headers.options.active
                    })
                }
                this.changedHeaders()
            },
            /**
             * * pass changed headers object to store and emit event to parent vue.
             * * calls function setHeadersToShow()
             */
            changedHeaders() {
                this.svHeadersIndex = this.headers.modifiedHeaders.findIndex((p) => p.name === "sv_headers")
                this.$store.dispatch("CHANGE_HEADER_ITEMS", { items: this.headers })
                this.$emit("changedHeaders")
                this.setHeadersToShow()
            },
            setHeadersToShow(type) {
                if (type == "sv") {
                    let svHeaders = []
                    let svHeaderIndex = this.headers.modifiedHeaders.findIndex((p) => p.name === "sv_headers")
                    this.headers.modifiedHeaders[svHeaderIndex].columns.map((result) => svHeaders.push(result))
                    if (this.headers.options.sort) {
                        svHeaders.sort((a, b) => a.value - b.value)
                    } else {
                        svHeaders.sort((a, b) => b.value - a.value)
                    }
                    return svHeaders
                } else {
                    let tmpHeaders = []
                    let svHeaders = []
                    this.headers.modifiedHeaders.map((header) => {
                        if (header.name !== "sv_headers") {
                            tmpHeaders.push(header)
                        } else {
                            header.columns.map((result) => svHeaders.push(result))
                            if (this.headers.options.sort) {
                                svHeaders.sort((a, b) => a.value - b.value)
                            } else {
                                svHeaders.sort((a, b) => b.value - a.value)
                            }
                            tmpHeaders = tmpHeaders.concat(svHeaders)
                        }
                    })
                    // Setzen der Headers aus dem modifiedHeaders package
                    this.headersToShow = JSON.parse(JSON.stringify(tmpHeaders.filter((header) => header.active)))
                    // Setzen der Headers aus dem standardHeaders package
                    // nur fuer die allHeaderValues

                    // console.log(tmpHeaders);
                    this.headers.standardHeaders.map((header) => {
                        if (header.name !== "sv_headers") {
                            tmpHeaders.push(header)
                        } else {
                            header.columns.map((result) => tmpHeaders.push(result))
                        }
                    })
                    console.log("tmpHeaderst", tmpHeaders)
                    // add ranking Header if available
                    // let h = {
                    //     active: true,
                    //     sortable: false,
                    //     canBeModified: false,
                    //     modify: false,
                    //     group: 0,
                    //     custom: false,
                    //     text: "Keyword",
                    //     modifiedText: "Keyword",
                    //     value: "keyword",
                    //     tooltip: "The given search phrase",
                    //     modifiedTooltip: "The given search phrase",
                    //     width: "250",
                    //     align: "left"
                    // }

                    this.allHeaderValues = tmpHeaders.map((header) => header.value)
                    this.allHeaderValues = JSON.parse(JSON.stringify([...this.allHeaderValues, ...tmpHeaders.map((header) => header.text)]))
                    this.allHeaderValues = [...new Set(this.allHeaderValues)]
                    this.restoreStandardDialog = false
                }
            },
            showRow() {
                // console.log(this.headersToShow.filter((header) => header.active))
                return this.headersToShow.filter((header) => header.active)
            },
            onSortStart() {
                this.isMove = true
                this.transition = 300
            },
            onSortEnd() {
                this.transition = 0
                this.isMove = false
            },
            onResize() {
                console.log(this.mountedOn)
                const check = this.mountedOn == "Brandindex" ? false : this.user_settings.advancedMode
                const windowHeight = window.innerHeight
                const windowWidth = window.innerWidth

                // Check if window height is less than 800
                if (windowHeight < 800 || windowWidth < 1264) {
                    this.windowSize.y = "auto" // Allow table to take the height it needs
                    return
                }
                setTimeout(() => {
                    if (!check) {
                        console.log("inside")
                        const navHeight = 48 + 30 // + main padding
                        const footerHeight = 59
                        const padding = 50
                        const tabHeight = 40
                        const windowHeight = window.innerHeight
                        const rowElement = document.querySelector("td:nth-child(2)")
                        const tabletitle = document.getElementById("tabletitle") || { offsetHeight: 0 }
                        const card__subtitle = document.getElementById("card__subtitle")
                        const table_actions = document.getElementById("table_actions")
                        const tableHeader = document.getElementsByClassName("text-start")
                        if (rowElement) {
                            const tabelHeight = windowHeight - navHeight - footerHeight - tabletitle.offsetHeight - card__subtitle.offsetHeight - table_actions.offsetHeight
                            const allRowHeight = rowElement.offsetHeight * this.keywords.length
                            const tableInnerHeight = tabelHeight - tableHeader[0].offsetHeight - tabHeight

                            if (allRowHeight < tableInnerHeight) {
                                this.windowSize = {
                                    x: window.innerWidth,
                                    y: allRowHeight + tableHeader[0].offsetHeight
                                }
                            } else {
                                this.windowSize = {
                                    x: window.innerWidth,
                                    y: windowHeight - navHeight - footerHeight - tabletitle.offsetHeight - card__subtitle.offsetHeight - table_actions.offsetHeight - padding
                                }
                            }
                        }
                        console.log(this.windowSize.y)
                    } else {
                        // window.scrollTo(0, 0)
                        const table_actions = document.getElementById("table_actions")
                        if (table_actions) {
                            const table_actions_position = table_actions.getBoundingClientRect()
                            const footerHeight = 59
                            const btm_padding = 12
                            const windowHeight = window.innerHeight
                            this.windowSize.y = windowHeight - table_actions_position.y - footerHeight - btm_padding - table_actions.offsetHeight
                        }
                    }
                }, 150)
            },
            getCatsOfList() {
                let catsOfList2 = []
                this.keywords.forEach((keyword) => {
                    let cats = keyword.cats
                    if (cats) {
                        if (cats[0]) {
                            catsOfList2.push(cats[keyword.active_cat_index][cats[0].length - 1])
                        }
                    }
                })
                catsOfList2.filter((x) => !x)
                catsOfList2.sort((a, b) => a.category_name.localeCompare(b.category_name))

                this.catsOfList = catsOfList2
            },
            unselectAll() {
                this.selected = []

                this.keywords.map((x) => {
                    x.selected = false
                })
            },
            selectAll() {
                var visibleRows = this.$refs.keywordtable.$children[0].filteredItems
                console.log(visibleRows)
                console.log(this.keywords)
                if (this.selected.length) {
                    // unselect
                    this.selected = []

                    this.keywords.map((x) => {
                        x.selected = false
                    })
                } else {
                    this.keywords.map((x) => {
                        x.selected = false
                    })
                    // select
                    this.selected = visibleRows
                    visibleRows.map((row) => {
                        this.keywords.map((x) => {
                            if (row.keyword == x.keyword) {
                                x.selected = true
                            }
                        })
                    })
                    console.log(this.selected)
                    console.log(this.keywords)
                }
            },
            closeTextField() {
                this.showCatAddBtn = true
                this.showCatTextFld = false
            },
            openTextField() {
                this.catsSearch = ""
                this.catToAdd = document.getElementsByClassName("v-select__selections")[1].childNodes[document.getElementsByClassName("v-select__selections")[1].childNodes.length - 1]._value
                this.showCatAddBtn = false
                this.showCatTextFld = true
            },
            close(id) {
                // close dialog
                this.display[id] = false
            },
            createNewCat(row) {
                console.log(this.catToAdd)
                let newCat = {
                    category_name: this.catToAdd,
                    category_code_parent: null,
                    custom: true
                }
                this.$http
                    .post(process.env.VUE_APP_APIURL + "/categories/add", { company_id: localStorage.company_id, category: newCat }, { headers: { Authorization: this.auth } })
                    .then(function (response) {
                        // console.log(response)
                        this.selectedCats.push(newCat)
                        this.catsSearch = ""
                        this.closeTextField()
                        this.fetchAllCats()
                    })
                    .catch((err) => {
                        // console.log(err)
                        if (err.body.message) {
                            this.createNotification(err.body.message, "mdi-alert-circle-outline", "#FCBA03")
                        }
                    })
            },
            actionHandler(item) {
                switch (item.id) {
                    case 1:
                        this.downloadDialog = true
                        break
                    case 2:
                        this.modifyColumnsDialog = true
                        break
                    case 3:
                        this.addColumnDialog = true
                        break
                    case 4:
                        this.deleteKeywords()
                        break
                    case 5:
                        this.shareDialog = true
                        break

                    default:
                        break
                }
            },
            showDownloadDialog() {
                this.downloadDialog = true
            },
            addToClipboard(trendkeywords) {
                let keywords
                if (trendkeywords && trendkeywords.length > 0) {
                    keywords = trendkeywords
                } else {
                    keywords = this.selected
                }
                console.log(keywords)
                if (keywords.length > 0) {
                    // if (localStorage.keywords) {
                    //     let keywords = JSON.parse(localStorage.keywords)
                    console.log("CLIPBOARD LANG: ", this.$store.state.clipboard_location.value)
                    console.log("SELECTED LANG: ", this.siteKeywordsLocation.value)
                    console.log(keywords.length + this.clipboard_keywords.length)
                    console.log(!(keywords.length + this.clipboard_keywords.length > 5000))
                    if (!(keywords.length + this.clipboard_keywords.length > 5000)) {
                        if (this.clipboard_keywords.length > 0) {
                            // console.log('hi')
                            // let actualLocObj = JSON.parse(localStorage.loc)

                            if (
                                this.$store.state.clipboard_location.value == this.siteKeywordsLocation.value &&
                                this.$store.state.clipboard_location.loc == this.siteKeywordsLocation.loc &&
                                this.$store.state.clipboard_location.ads_lang_code == this.siteKeywordsLocation.ads_lang_code &&
                                this.$store.state.clipboard_location.serp_lang_code == this.siteKeywordsLocation.serp_lang_code
                            ) {
                                this.pushToClipboard(this.clipboard_keywords, keywords) // in clipboard pushen
                            } else {
                                this.createNotification(
                                    "The keywords in your clipboard have search volume from another country. Please delete these keywords first.",
                                    "mdi-alert-circle-outline",
                                    "#FCBA03"
                                )
                            }
                        } else {
                            this.$store.dispatch("SET_CLIPBOARD_LOCATION", this.siteKeywordsLocation)
                            console.log("LOC", this.siteKeywordsLocation)
                            localStorage.setItem("selectedLocation", JSON.stringify(this.siteKeywordsLocation)) // location im localstorage setzen
                            this.pushToClipboard([], keywords)
                        }
                    } else {
                        this.createNotification("Clipboard limit is 5.000 keywords. Please free up your clipboard before pushing new keywords.", "mdi-alert-circle-outline", "#FCBA03")
                    }
                    // } else {
                    //     localStorage.setItem("loc", JSON.stringify(this.selectedLocation)) // location im localstorage setzen
                    //     this.pushToClipboard([])
                    // }
                } else {
                    this.createNotification("You have to select at least one keyword for this function!", "mdi-alert-circle-outline", "#FCBA03")
                }
            },

            pushToClipboard(clipboard_keywords, keywords) {
                var kwNoDuplicates = this.$helpers.removeDuplicatesByAttribute(keywords.concat(clipboard_keywords), "keyword")
                kwNoDuplicates.sort((a, b) => (a.avgsv > b.avgsv ? -1 : 1))
                console.log(kwNoDuplicates)

                try {
                    this.$matomo.trackEvent("Ideas", "addToCollection", "Keywords: " + kwNoDuplicates.length, "")
                } catch (error) {
                    console.log(error)
                    console.log("matomo blocked")
                }

                this.$store.dispatch("SET_CLIPBOARD_KEYWORDS", kwNoDuplicates)
                this.$store.dispatch("SET_CLIPBOARD_HEADERS", this.headers)
                this.createNotification("The selected keywords have been added to the clipboard!", "mdi-check-circle-outline", "#16C96D")
                this.$store.dispatch("SHOW_SIDEBARMENU")
                this.$helpers.keywordsInClipboard(this.keywords, this.clipboard_keywords)
                this.rerenderKeywordTable()
            },

            disabled() {
                if (this.selected.length > 0) {
                    this.tableBtnDisabled = false
                    return this.$vuetify.theme.themes.light.primary
                } else {
                    this.tableBtnDisabled = true
                    return "grey"
                }
            },
            exportData() {
                this.downloadDialog = false
                this.showDownloadProgress = true
                let keywords = []
                if (this.selected.length > 0) {
                    keywords = JSON.parse(JSON.stringify(this.selected))
                } else {
                    keywords = JSON.parse(JSON.stringify(this.keywords))
                }
                console.log(keywords)
                try {
                    this.$matomo.trackEvent("KeywordTable", "download", "Keywords length: " + keywords.length, "")
                } catch (error) {
                    console.log(error)
                    console.log("matomo blocked")
                }
                keywords.sort(function (a, b) {
                    return b.avgsv - a.avgsv
                })
                let fields = this.$helpers.deleteDownloadColumns(this.headers.modifiedHeaders)
                let foundHeaders = this.headers.modifiedHeaders.find((x) => x.custom == true)
                console.log(foundHeaders)
                if (foundHeaders) fields = this.$helpers.addCustoms(keywords, fields)
                console.log(fields)
                if (this.doctype.data == "json2xls") {
                    keywords = this.$helpers.transformDataForExcelDownload(keywords, this.headers.modifiedHeaders)
                }
                if (this.doctype.data == "json2csv") {
                    keywords = keywords.map((x) => {
                        x.cats = x.cats ? x.cats[0][x.cats[0].length - 1].category_name : null // only one cat for donwload
                        x.keyword_difficulty = x.keyword_difficulty ? x.keyword_difficulty.difficulty : null
                        x.season = x.season ? x.season.season : null
                        if (x.serp_items) {
                            let serpString = ""
                            x.serp_items.map((y, i) => (serpString += y.value += i == x.serp_items.length - 1 ? "" : ","))
                            x.serp_items = serpString
                        } else {
                            x.serp_items = null
                        }
                        return x
                    })
                }
                // console.log(keywords)
                this.$http
                    .post(
                        process.env.VUE_APP_APIURL + "/research/" + this.doctype.data,
                        { data: this.$helpers.deleteDownloadAttributes(keywords), columns: fields },
                        { headers: { Authorization: this.auth } }
                    )
                    .then(function (response) {
                        this.showDownloadProgress = false
                        window.location = process.env.VUE_APP_APIURL + "/" + response.body
                    })
            },
            initExportTrends(keywords, headers) {
                console.log(keywords)
                this.trends_export.status = true
                this.trends_export.keywords = keywords
                this.trends_export.headers = headers
                this.downloadDialog = true
            },
            exportTrends() {
                this.downloadDialog = false
                this.showDownloadProgress = true
                let keywords = JSON.parse(JSON.stringify(this.trends_export.keywords))
                let keyword = keywords.filter((x) => x.keyword == "urlaub mit baby")
                console.log(keyword)

                console.log(keywords)
                try {
                    this.$matomo.trackEvent("Trends", "download", "Keywords length: " + keywords.length, "")
                } catch (error) {
                    console.log(error)
                    console.log("matomo blocked")
                }

                let fields = this.$helpers.deleteDownloadColumns(this.trends_export.headers)
                console.log(fields)

                if (this.doctype.data == "json2xls") {
                    keywords = this.$helpers.transformTrendsForExcelDownload(keywords, this.trends_export.headers)
                }
                console.log(keywords)
                if (this.doctype.data == "json2csv") {
                    keywords = this.$helpers.transformTrendsForCsvDownload(keywords)
                    let headerIndex = fields.findIndex((x) => x.value == "avgsv")
                    if (headerIndex > -1) fields[headerIndex].label = " Avg. Google SV"
                }

                this.$http
                    .post(process.env.VUE_APP_APIURL + "/research/" + this.doctype.data, { data: keywords, columns: fields }, { headers: { Authorization: this.auth } })
                    .then(function (response) {
                        this.showDownloadProgress = false
                        this.resetTrendsObject()
                        window.location = process.env.VUE_APP_APIURL + "/" + response.body
                    })
            },
            resetTrendsObject() {
                console.log("trendobject restored")
                this.trends_export.status = false
                this.trends_export.keywords = []
                this.trends_export.headers = []
            },
            createNotification(message, icon, color) {
                this.$emit("createNotification", {
                    message: message,
                    icon: icon,
                    color: color
                })
            },
            deleteKeywords() {
                this.$emit("deleteKeywords", this.selected)
                // this.selected = []
                // this.keywords.map((x) => {
                //     x.selected = false
                // })
            },
            addToChart(trend_selected) {
                console.log(trend_selected)
                if (trend_selected[0]) {
                    this.$emit("addToChart", trend_selected)
                    return
                }
                this.$emit("addToChart", this.selected)
            },
            addCategories(item) {
                console.log(item)
                let data = this.selected.length > 0 ? this.selected : [item]
                this.$emit("addCategories", data, this.selectedCats)
                this.close(item.keyword)
                this.selectedCats = []
            },
            rerenderKeywordTable() {
                this.tablepage = 2
                this.tablepage = 1
            },
            removeCat(item, kat) {
                this.$emit("removeCat", item, kat)
            },
            switchCat(item, kat) {
                console.log("item:", item)
                console.log("selected:", this.selected)

                console.log("switchCat")
                let data = this.selected.length > 0 ? this.selected : [item]
                this.$emit("switchCat", data, kat)
            },
            fixselectError() {
                this.$emit("fixselectError")
            },
            filterForCat() {
                this.$emit("filterForCat", this.cat_for_filter)
            },
            filterForCustomDropdown() {
                this.$emit("filterForCustomDropdown", this.dropdownFilter, this.allowMultipleTags, this.cat_for_filter)
            },
            filterColumns() {
                console.log(this.columnFilter)
                this.$emit("filterColumns", this.columnFilter)
            },
            fetchAllCats() {
                let company_id = undefined
                if (this.sharedCompanyId) {
                    company_id = this.sharedCompanyId
                } else {
                    company_id = localStorage.company_id
                }
                this.$http
                    .post(process.env.VUE_APP_APIURL + "/categories", { company_id: company_id }, { headers: { Authorization: this.auth } })
                    .then((response) => {
                        let cats = response.body.categories.filter((x) => x != null && x.category_name)
                        // console.log(cats)
                        this.catData = cats.sort((a, b) => a.category_name.localeCompare(b.category_name))
                        // console.log(this.catData);
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            },

            /**
             * Add Default Value to Keyword for custom columns
             * @param {String} headerValue name of header
             * @param {Object} keywordRow table row object
             * @param {String} headerType Dropdown, Text, Number
             */
            addDefaultValueToKeyword(headerValue, keywordRow, headerType) {
                let defaultEntry
                if (!keywordRow.customs) keywordRow.customs = {}
                if (headerType.toLowerCase() == "dropdown") {
                    // defaultEntry = {
                    //     [headerValue]: {
                    //         text: "-",
                    //         color: {
                    //             hex: ""
                    //         },
                    //         id: 0
                    //     }
                    // }

                    if (!keywordRow.customs[headerValue])
                        keywordRow.customs[headerValue] = {
                            text: "-",
                            color: {
                                hex: ""
                            },
                            id: 0
                        }
                } else {
                    if (!keywordRow.customs[headerValue]) keywordRow.customs[headerValue] = ""
                    // console.log(keywordRow)
                }
            },
            /**
             * Used in Mounted for setting Default entrys
             * Used for setting Default entrys for new custom columns
             * @param {Object} newCol created custom column / undefinded (mounted)
             */
            setCustomColumnDefaultValues(newCol) {
                if (newCol) {
                    // called if new column added
                    // if (newCol.customOptions.type == "Dropdown") {
                    this.keywords.map((keyword) => {
                        this.addDefaultValueToKeyword(`custom_${newCol.text}`, keyword, newCol.customOptions.type)
                    })

                    // }
                } else {
                    //called when this.keywords changes
                    let tmpHeader = []
                    this.headers.modifiedHeaders.map((header) => {
                        if (header.value !== undefined && header.value.includes("custom_")) {
                            tmpHeader.push(header)
                        }
                    })
                    // add customs to filter object
                    this.addCustomFilters(tmpHeader)
                    this.keywords.map((keyword) => {
                        tmpHeader.map((header) => {
                            // if (!(keyword.customs !== undefined && keyword.customs[header.value] !== undefined)) {
                            this.addDefaultValueToKeyword(header.value, keyword, header.customOptions.type)
                            // }
                        })
                    })
                    if (this.mountedOn != "Brandindex" && this.mountedOn != "Research" && this.mountedOn != "SharedResearch" && this.mountedOn != "FindInCollections") this.tableloaded = true
                }
            },
            fetchSERP(selected) {
                this.$emit("fetchSERP", selected)
            },
            addCustomFilters(header) {
                header.map((x) => {
                    if (!this.columnFilter[x.value]) {
                        this.columnFilter[x.value] = {
                            include: true,
                            setFilter: []
                        }
                    }
                })
                console.log(this.columnFilter)
            },
            paginationHandler(pagination) {
                this.$emit("paginationHandler", pagination)
            },
            /**
             * used for creatign smaller table for advanced mode
             * needs to be called before setHeadersToShow()
             */
            changeHeaderWidthForMode() {
                this.headers.modifiedHeaders.map((header) => {
                    // Keyword Column
                    if (header.value && header.value == "keyword") {
                        if (this.user_settings.advancedMode) {
                            header.width = "200"
                        } else {
                            header.width = "250"
                        }
                    }
                    // AVG googleSV
                    if (header.value && header.value == "avgsv") {
                        if (this.user_settings.advancedMode) {
                            header.advanced_text = "⌀ Google SV"
                            header.width = "112"
                        } else {
                            header.width = "150"
                        }
                    }
                    // Trend
                    if (header.value && header.value == "trend") {
                        if (this.user_settings.advancedMode) {
                            header.advanced_text = "Trend"
                        }
                    }
                    // KW Difficulty
                    if (header.value && header.value == "keyword_difficulty") {
                        if (this.user_settings.advancedMode) {
                            header.advanced_text = "KD"
                            header.width = "64"
                        } else {
                            header.width = "100"
                        }
                    }
                    // Competition
                    if (header.value && header.value == "adwords_competition") {
                        if (this.user_settings.advancedMode) {
                            header.advanced_text = "PC"
                            header.width = "64"
                        } else {
                            header.width = "105"
                        }
                    }
                    // CPC LR
                    if (header.value && header.value == "adwords_cpc_low") {
                        if (this.user_settings.advancedMode) {
                            header.advanced_text = "CPC (Lower Range)"
                        }
                    }
                    // CPC UR
                    if (header.value && header.value == "adwords_cpc_high") {
                        if (this.user_settings.advancedMode) {
                            header.advanced_text = "CPC (Upper Range)"
                        }
                    }
                })
            }
        },

        mounted() {
            this.fetchAllCats()
            // this.setCustomColumnDefaultValues()
            console.log("MOUNTED", this.headers)
            this.keywordCount = this.keywords ? this.keywords.length : 0
            this.svHeadersIndex = this.headers.modifiedHeaders.findIndex((p) => p.name === "sv_headers")
            // let chartkw = this.keywords.find((keywordrow) => keywordrow.keyword == this.cardData.searchPhrases[0])
            // this.addToChart(chartkw)
            // console.log("chartkw:", chartkw)
            this.changeHeaderWidthForMode()
            this.setHeadersToShow()
            this.$store.dispatch("CHANGE_HEADER_ITEMS", { items: this.headers })
            this.$socket.on("reloadTable", async (data) => {
                console.log("RELOAD TABLE")
                await this.$helpers.fetchListsOfKeywords(this.keywords, localStorage.company_id, this.siteKeywordsLocation.value)
                this.rerenderKeywordTable()
            })
        }
    }
</script>
<style lang="scss">
    .kwtab {
        background-color: #eaeaea;
        &.active {
            background-color: #e0e0e0;
        }
    }
    .add_column_bar {
        display: inline-flex;
        width: 100%;
        border: 2px dashed grey;
        border-radius: 7px;
        margin: 0px 22px 10px 10px;
        padding: 10px 10px 10px 10px;
        cursor: pointer;
        &:hover {
            background: rgb(243, 243, 243);
        }
        .add_column_bar_text {
            margin-top: auto;
            margin-bottom: auto;
            margin-left: 5px;
        }
    }
    .savedicon {
        margin: auto;
    }
    .savedicon_wrapper {
        //     // opacity: 0.7;
        //     position: absolute;
        //     right: 5px;
        display: flex;
        //     top: 25%;
        margin: auto;
        width: 25px;
        height: 25px;
        border-radius: 12.5px;
        background-color: var(--v-q_grey-base);
        //     // &:hover {
        //     //     // cursor: pointer;
        //     //     // background-color: rgb(228, 228, 228);
        //     //     // .savedicon {
        //     //     //     color: var(--v-primary-base) !important;
        //     //     // }
        //     // }
    }
    #keyword-table .qchip {
        background-color: rgb(245, 245, 245) !important;
        border-width: 1px;
    }
</style>

<style>
    .theme--dark.avgsvcell {
        background-color: #555 !important;
    }

    .v-text-field__slot input {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    .highlighted {
        background: var(--v-keywordcol-base) !important;
    }
    .highlighted:hover {
        background: var(--v-keywordcol-base) !important;
    }
    .text-left:hover .headermenuicon {
        color: grey !important;
    }
    .text-start:hover .headermenuicon {
        color: grey !important;
    }
    .column-dialog-row-item:hover #edit-column-icon {
        color: grey !important;
    }
    #keyword-table .v-data-footer {
        margin-right: 0px;
    }
    #keyword-table table > tbody > tr > td:nth-child(1) {
        z-index: 2 !important;
    }
    #keyword-table table > tbody > tr > td:nth-child(2) {
        z-index: 2 !important;
    }
    #keyword-table table > thead > tr > th:nth-child(2) {
        z-index: 3 !important;
    }
    #keyword-table table > thead > tr > th {
        /* font-weight: 200;
        text-transform: uppercase;
        white-space: nowrap; */
    }
    #keyword-table table > thead > tr > th:nth-child(1) {
        position: sticky !important;
        position: -webkit-sticky !important;
        left: 0 !important;
        z-index: 3 !important;
        background: var(--v-keywordcol-base) !important;
    }

    #keyword-table table > tbody > tr > td:nth-child(1),
    #keyword-table table > tbody > tr > th:nth-child(1) {
        position: sticky !important;
        position: -webkit-sticky !important;
        left: 0 !important;
        background: var(--v-keywordcol-base) !important;
    }

    #keyword-table table > tbody > tr > td:nth-child(2),
    #keyword-table table > thead > tr > th:nth-child(2) {
        position: sticky !important;
        position: -webkit-sticky !important;
        left: 56px !important;
        background: var(--v-keywordcol-base) !important;
        border-right: 1.5px solid rgb(218, 218, 218);
    }
    .v-data-table-header {
        border-top: 0px;
    }
    .kursiv {
        font-style: italic;
        font-weight: bold;
    }
    #title {
        padding-top: 16px;
        line-height: 25px;
        font-size: 12px;
        font-weight: 600;
        text-transform: uppercase;
        color: #212529;
        font-size: 1em;
    }

    .catmenu {
        margin-top: 2px;
        margin-left: 15px;
    }
    .catmenutitle {
        padding-bottom: -10px;
        line-height: 25px;
        font-size: 0.95em;
        font-weight: 600;
        text-transform: uppercase;
    }
    .catmenusubtitle {
        margin-top: -4px;
        margin-bottom: 5px;
        font-size: 0.8em !important;
        color: rgb(110, 103, 103);
    }
    .catchip {
        background-color: #e0e0e0 !important;
    }
    .pointerhover:hover {
        cursor: pointer;
    }
    .catchip:hover {
        background-color: #e0e0e0;
        cursor: pointer;
    }
    .catdialogtitle {
        margin-top: -5px;
        line-height: 25px;
        font-size: 0.8em;
        font-weight: 600;
        text-transform: uppercase;
    }
    .catdialogsubtitle {
        margin-top: -8px;
        margin-bottom: 5px;
        font-size: 0.61em !important;
        color: rgb(110, 103, 103);
    }
    .catlistitem:hover {
        background-color: rgb(245, 245, 245);
    }
    .table {
        max-height: calc(100vh - 170px);
    }
    .v-data-table {
        overflow: auto;
    }
    .v-data-table /deep/ .v-data-table__wrapper {
        overflow: unset;
    }
    .v-data-table /deep/ .sticky-header {
        position: sticky;
        top: 56px;
    }
    .download-label {
        font-weight: bold;
        padding-bottom: 5px;
        display: flex;
    }
    .item__modify {
        padding-top: 8px;
        padding-bottom: 8px;
    }

    .handle {
        cursor: grab;
        float: left;
        padding-top: 8px;
        padding-bottom: 8px;
    }

    .handleSnd {
        cursor: grab;
        float: left;
        padding-top: 8px;
        padding-bottom: 8px;
    }
    .dashed-border {
        display: inline-flex !important;
        margin-left: auto;
        border: 1px dashed grey;
        border-radius: 5px;
        padding-top: 3px;
        padding-bottom: 3px;
        margin-right: 10px;
        margin-bottom: 10px;
    }
    #option-txt-field::before {
        display: none;
    }
    #dropdown_td:hover {
        cursor: pointer !important;
    }
    .dropdown_td:hover {
        cursor: pointer !important;
    }
    .ghost {
        padding-top: 7px !important;
        background: rgb(240, 240, 240);
        border: 2px dashed rgb(168, 168, 168);
        border-radius: 5px;
        padding-left: 5px;
        padding-right: 5px;
    }

    #keyword-table table > thead > tr > th {
        /* padding-left: 8px !important;
        padding-right: 8px !important; */
        background-color: var(--v-keywordcol-base);
    }
    .adv_mode_searchbar {
        position: absolute;
        top: -30px;
        right: 10px;
    }
    .kw_table_td {
        display: flex;
        flex-direction: row;
    }
    #option-txt-field .v-input__append-inner {
        margin-top: 0px !important;
    }
</style>
