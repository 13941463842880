<template>
    <div id="contentcontainter" :class="$store.state.user_settings.advancedMode ? 'advanced-mode' : 'standard-mode'">
        <v-snackbar top v-model="notification.show" :color="notification.color">
            <v-icon>{{ notification.icon }}</v-icon
            ><span class="font-weight-bold pl-2"> {{ notification.message }} </span>
        </v-snackbar>
        <SearchSettings :catsOfList="catsOfList" :seasonsOfList="seasonsOfList" :headers="headers.modifiedHeaders"></SearchSettings>

        <div v-if="loading">
            <div class="loaderwrapper">
                <center class="circularprogress">
                    <img id="loader" src="../assets/quaro-q.svg" />
                </center>
            </div>
        </div>
        <!-- <v-slide-x-transition>
            <div class="mt-10 mb-4" v-if="collection_information.mdata && !$store.state.user_settings.advancedMode">
                <div style="font-size: 12px; text-transform: uppercase">Keyword Collection</div>
                <div style="font-size: 24px" class="pb-1">
                    <span>{{ collection_information.listname }}</span>
                </div>
                <div style="font-size: 12px">
                    <v-row no-gutters>
                        <v-chip color="primary" class="mr-2 my-auto" style="text-transform: capitalize">
                            <v-icon small class="mr-1">mdi-map-marker</v-icon>
                            {{ collection_information.mdata.langloc.value }}
                        </v-chip>
                        <v-chip color="primary" class="mr-2 my-auto" style="text-transform: uppercase">
                            <v-icon small class="mr-1">mdi-clock</v-icon>
                            {{ collection_information.mdata.updated }}
                        </v-chip>
                        <div v-if="$helpers.isActionAllowed(42, $store.state.user_rights)" style="display: inline-flex; border-radius: 10px; background-color: #eaeaea" class="px-3 py-1">
                            <v-switch
                                @change="$store.dispatch('CHANGE_USER_SETTINGS', $store.state.user_settings)"
                                class="my-auto"
                                v-model="$store.state.user_settings.advancedMode"
                                light
                                inset
                                style="padding-bottom: 2px"
                                color="primary"
                                hide-details
                                dense></v-switch>

                            <span class="my-auto" style="white-space: nowrap; font-size: 14px">Advanced Mode</span><v-chip class="ml-2 betachip my-auto" x-small color="red">new</v-chip>
                        </div>
                    </v-row>
                </div>
            </div>
        </v-slide-x-transition> -->
        <!-- Delete Dialog -->
        <v-dialog v-model="deleteQuestion" max-width="300">
            <v-card>
                <v-card-title
                    style="
                         {
                            text-align: center;
                        }
                    ">
                    Are you sure?</v-card-title
                >
                <v-card-text class="text-center">
                    <v-btn class="mr-3" text @click="deleteQuestion = false"> Nevermind </v-btn>
                    <v-btn color="primary" text @click="deleteKeywords(selected)"> Yes </v-btn>
                </v-card-text>
            </v-card>
        </v-dialog>

        <!-- Comments -->
        <v-card
            id="comments"
            class="py-2 px-4"
            color="primary"
            dark
            flat
            link
            min-width="100"
            style="position: fixed; top: 370px; right: -35px; border-radius: 25px; z-index: 4; opacity: 0.7"
            v-if="!hideAddCommentMenu && $helpers.isActionAllowed(18, user_rights)">
            <v-icon large> mdi-forum </v-icon>
        </v-card>
        <v-menu v-model="commentMenu" :close-on-content-click="false" activator="#comments" bottom left nudge-left="8" offset-x origin="top right" transition="scale-transition">
            <v-card class="text-center mb-0" width="300" max-height="500" style="overflow: auto">
                <v-card-title class="py-3">
                    <v-spacer></v-spacer>
                    <center>
                        <strong v-if="comments.length > 0" class="d-inline-block" id="maintitle"> All Comments </strong>
                        <strong v-else class="d-inline-block" id="maintitle">Create a comment</strong>
                    </center>
                    <v-spacer></v-spacer>

                    <!-- Add comment menu  -->
                    <v-menu v-if="$helpers.isActionAllowed(19, user_rights)" v-model="addCommentMenu" :close-on-content-click="false" :nudge-width="200" offset-x>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn color="primary" dark absolute right v-bind="attrs" v-on="on" fab x-small>
                                <v-icon>mdi-plus-circle</v-icon>
                            </v-btn>
                        </template>
                        <v-card>
                            <v-card-title id="title" class="ml-8"> Create a comment </v-card-title>
                            <v-card-text class="pb-0">
                                <v-textarea label="insert your comment here" prepend-outer-icon="mdi-message" v-model="comment" filled rows="3"></v-textarea>
                            </v-card-text>

                            <v-card-actions class="pt-0">
                                <v-btn rounded class="mx-auto" color="primary" @click="saveComment"> Save </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-menu>
                </v-card-title>

                <v-card-text v-if="comments.length > 0" class="pa-0">
                    <v-timeline align-top dense class="mr-5">
                        <v-timeline-item class="timelineitem" v-for="message in comments" :key="message._id" :color="message.color" small>
                            <div class="comment">
                                <div>
                                    <strong>{{ message.username }}</strong>
                                </div>
                                <div class="commentdate">at {{ transformCommentDate(message.date) }}</div>

                                <div class="commenttext" v-bind:id="'comment' + message._id" v-on:blur="editComment(message._id)">
                                    {{ message.comment }}
                                </div>

                                <v-btn
                                    v-if="message.commentOwner == userid && $helpers.isActionAllowed(20, user_rights)"
                                    v-bind:id="'editbtn' + message._id"
                                    @click="changeCommentText(message._id)"
                                    icon
                                    small>
                                    <v-icon x-small>mdi-pencil</v-icon>
                                </v-btn>
                                <v-btn v-if="message.commentOwner == userid" v-bind:id="'checkbtn' + message._id" style="display: none" @click="editComment(message._id)" icon small>
                                    <v-icon x-small>mdi-check</v-icon>
                                </v-btn>
                                <v-btn v-if="message.commentOwner == userid && $helpers.isActionAllowed(21, user_rights)" @click="deleteComment(message._id)" icon small>
                                    <v-icon x-small>mdi-delete</v-icon>
                                </v-btn>
                            </div>
                        </v-timeline-item>
                    </v-timeline>
                </v-card-text>
            </v-card>
        </v-menu>

        <ChartSettings
            :pieChartVisible="pieChartVisible"
            :lineChartVisible="lineChartVisible"
            :headers="headers"
            @pieHeadersChanges="
                changedHeaders()
                renderPieChart++
            "
            @lineHeadersChanges="
                changedHeaders()
                rerenderChart++
            "
            @toggleLineChart="lineChartVisible = !lineChartVisible"
            @createNotification="createNotification"
            @settingsLineChart="settingsLineChart++"
            @downloadLineChart="downloadLineChart++"
            @resetLineChart="resetLineChart++"
            @togglePieChart="pieChartVisible = !pieChartVisible"
            @settingsPieChart=""
            @downloadPieChart=""
            @resetPieChart="resetPieChart++">
        </ChartSettings>

        <div v-if="!$store.state.user_settings.advancedMode">
            <!-- Chart Area -->
            <v-row>
                <v-col v-if="lineChartVisible" :cols="pieChartVisible ? '8' : '12'" style="height: auto" id="linechart_col">
                    <!-- linechart -->
                    <v-slide-y-transition>
                        <KeywordLineChart
                            class="mb-5"
                            :id="pieChartVisible ? 'line_chart_not_responsive' : 'line_chart'"
                            v-if="!loading"
                            :dataChart="dataChart"
                            :countGraph="countGraph"
                            :countGraph2="countGraph2"
                            :keywords="keywords >= unfilteredKeywords ? keywords : unfilteredKeywords"
                            :headers="headers"
                            :graphloaded="graphloaded"
                            :settingsLineChart="settingsLineChart"
                            :downloadLineChart="downloadLineChart"
                            :resetLineChart="resetLineChart"
                            @clearChart="clearChart"
                            @rerender="rerenderChart">
                        </KeywordLineChart>
                    </v-slide-y-transition>
                </v-col>

                <v-slide-y-transition>
                    <v-col v-if="pieChartVisible" :cols="lineChartVisible ? '4' : '5'" style="height: auto">
                        <v-slide-y-transition>
                            <KeywordPieChart
                                :render="renderPieChart"
                                :show="!loading && pieChartVisible"
                                @hide="pieChartVisible = false"
                                :keywords="keywords >= unfilteredKeywords ? keywords : unfilteredKeywords"
                                :headers="headers"
                                :resetPieChart="resetPieChart">
                            </KeywordPieChart>
                        </v-slide-y-transition>
                    </v-col>
                </v-slide-y-transition>
            </v-row>

            <!-- Data table  -->
            <v-slide-y-transition>
                <KeywordTable
                    ref="kw_table"
                    class="mb-5 mt-4"
                    v-if="true && !loading"
                    :columnsDisabled="columnsDisabled"
                    :keywords="keywords"
                    :headers="headers"
                    :catsLoading="false"
                    :cardData="cardData"
                    :media_counts="false"
                    :catsOfList="catsOfList"
                    :serpsOfList="serpsOfList"
                    :collection_information="collection_information"
                    :mountedOn="$route.query.findKeyword ? 'FindInCollections' : 'Research'"
                    :addToSerpDisabled="addToSerpDisabled"
                    :siteKeywordsLocation="siteKeywordsLocation"
                    @removeCat="removeCat"
                    @switchCat="switchCat"
                    @addToChart="addToChart"
                    @deleteKeywords="prepareDeleteKw"
                    @filterForCat="filterForCat"
                    @addCategories="addCategories"
                    @createNotification="createNotification"
                    @changedHeaders="changedHeaders"
                    @addCustomEntries="addCustomEntries"
                    @modifiedDropDownOptions="modifiedDropDownOptions"
                    @filterForCustomDropdown="filterForCustomDropdown"
                    @filterColumns="filterColumns"
                    @changeRankingURL="addRankings"></KeywordTable>
            </v-slide-y-transition>
        </div>
        <div v-else>
            <v-row>
                <v-col cols="6" class="pb-0">
                    <v-slide-y-transition>
                        <KeywordTable
                            ref="kw_table"
                            v-if="true && !loading"
                            :columnsDisabled="columnsDisabled"
                            :keywords="keywords"
                            :headers="headers"
                            :catsLoading="false"
                            :cardData="cardData"
                            :media_counts="false"
                            :catsOfList="catsOfList"
                            :serpsOfList="serpsOfList"
                            :collection_information="collection_information"
                            :mountedOn="$route.query.findKeyword ? 'FindInCollections' : 'Research'"
                            :addToSerpDisabled="addToSerpDisabled"
                            :siteKeywordsLocation="siteKeywordsLocation"
                            @fetchSERP="fetchSERP"
                            @removeCat="removeCat"
                            @switchCat="switchCat"
                            @addToChart="addToChart"
                            @deleteKeywords="prepareDeleteKw"
                            @filterForCat="filterForCat"
                            @addCategories="addCategories"
                            @createNotification="createNotification"
                            @changedHeaders="changedHeaders"
                            @addCustomEntries="addCustomEntries"
                            @modifiedDropDownOptions="modifiedDropDownOptions"
                            @filterForCustomDropdown="filterForCustomDropdown"
                            @filterColumns="filterColumns"
                            @changeRankingURL="addRankings"></KeywordTable>
                    </v-slide-y-transition>
                </v-col>
                <v-col cols="6">
                    <v-row>
                        <!-- {{ hide_linechart }}11111 -->
                        <v-col cols="12" class="py-0" id="linechart_col">
                            <!-- line chart  -->
                            <v-slide-y-transition>
                                <KeywordLineChart
                                    class="mb-5"
                                    id="line_chart"
                                    v-if="!loading"
                                    :dataChart="dataChart"
                                    :countGraph="countGraph"
                                    :countGraph2="countGraph2"
                                    :keywords="keywords >= unfilteredKeywords ? keywords : unfilteredKeywords"
                                    :headers="headers"
                                    :graphloaded="graphloaded"
                                    :settingsLineChart="settingsLineChart"
                                    :downloadLineChart="downloadLineChart"
                                    :resetLineChart="resetLineChart"
                                    @clearChart="clearChart"
                                    @rerender="rerenderChart">
                                </KeywordLineChart>
                            </v-slide-y-transition>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" class="pb-0">
                            <!-- SERP Preview -->
                            <v-slide-y-transition>
                                <serp-preview
                                    :searched="!loading"
                                    :keyword="serpKeyword"
                                    :selectedLocation="selectedLocation"
                                    :reload="serpReload"
                                    :search_volume="preview_sv_data"
                                    @serpLoading="addToSerpDisabled = false"></serp-preview>
                            </v-slide-y-transition>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
    import KeywordLineChart from "../components/charts/KeywordLineChart"
    import KeywordPieChart from "../components/KeywordPieChart/KeywordPieChart.vue"
    import KeywordTable from "../components/keyword-table/KeywordTable"
    import ChartSettings from "../components/ChartSettings"
    import locs from "../components/helper/locs"
    import dataprocessing from "../components/helper/dataprocessing.js"
    import SearchSettings from "../components/SearchSettings"
    import SerpPreview from "../components/SerpPreview"

    export default {
        components: {
            KeywordLineChart,
            KeywordTable,
            KeywordPieChart,
            ChartSettings,
            SearchSettings,
            SerpPreview
        },
        data() {
            return {
                serpKeyword: null,
                serpReload: 0,
                pieChartVisible: true,
                lineChartVisible: true,
                renderPieChart: 0,
                notification: { show: false },
                columnsDisabled: {},
                startColumnIndex: 5,
                loading: true,
                cat_for_filter: null,
                catsOfList: [],
                seasonsOfList: [],
                serpsOfList: [],
                collection_information: {},
                display: {},
                dialog: false,
                catData: [],
                selectedCats: [],
                showAllCats: false,
                comments: [],
                comment: "",
                addCommentMenu: false,
                commentMenu: false,
                get userid() {
                    if (localStorage.getItem("id")) {
                        // console.log(JSON.parse(localStorage.getItem('id')))
                        if (localStorage.getItem("id") != "[object Object],[object Object]") {
                            return localStorage.getItem("id")
                        } else {
                            return []
                        }
                    } else {
                        return []
                    }
                },
                get plan() {
                    if (localStorage.getItem("plan")) {
                        if (localStorage.getItem("plan") != "[object Object],[object Object]") {
                            // //console.log(localStorage.getItem('user'))
                            return localStorage.getItem("plan")
                        } else {
                            return
                        }
                    } else {
                        return
                    }
                },

                showLocError: false,
                // stateItems: locs.stateItems,
                showAddMessage: false,
                selectedFilters: [
                    {
                        tags: [],
                        filtername: "Type",
                        selected: [],
                        condition: "that.selectedFilters[0].selected.includes(row.type)"
                    },
                    {
                        tags: [],
                        filtername: "Brand",
                        selected: [],
                        condition: "that.selectedFilters[1].selected.includes(row.brand)"
                    },
                    {
                        tags: [],
                        filtername: "Preposition",
                        selected: [],
                        condition: "that.selectedFilters[2].selected.includes(row.preposition)"
                    },
                    {
                        tags: [],
                        filtername: "Verb",
                        selected: [],
                        condition: "that.selectedFilters[3].selected.includes(row.verb)"
                    },
                    {
                        tags: [],
                        filtername: "Adjective",
                        selected: [],
                        condition: "that.selectedFilters[4].selected.includes(row.adjective)"
                    },
                    {
                        tags: [],
                        filtername: "Noun",
                        selected: [],
                        condition: "that.selectedFilters[5].selected.includes(row.noun)"
                    },
                    {
                        tags: [],
                        filtername: "Transactional",
                        selected: [],
                        condition: "that.selectedFilters[6].selected.includes(row.transactional)"
                    },
                    {
                        tags: [],
                        filtername: "Informative",
                        selected: [],
                        condition: "that.selectedFilters[7].selected.includes(row.informative)"
                    },
                    {
                        tags: [],
                        filtername: "Color",
                        selected: [],
                        condition: "that.selectedFilters[8].selected.includes(row.color)"
                    },
                    {
                        tags: [],
                        filtername: "Material",
                        selected: [],
                        condition: "that.selectedFilters[9].selected.includes(row.material)"
                    }
                ],
                deleteQuestion: false,
                hideAddCommentMenu: false,
                selected: [],
                keywords: [],
                gradient: [this.$vuetify.theme.themes.light.sparklineGradient1, this.$vuetify.theme.themes.light.sparklineGradient2, this.$vuetify.theme.themes.light.sparklineGradient3],
                pagination: { sortBy: "avgsv", descending: true },
                siteKeywordsLocation: {},
                search: "",
                headers: {},
                settingsLineChart: 0,
                downloadLineChart: 0,
                resetLineChart: 0,
                resetPieChart: 0,
                fetchKeywordsHeaders: {
                    options: {
                        sort: true,
                        active: true
                    },
                    standardHeaders: [
                        {
                            active: true,
                            sortable: true,
                            canBeModified: true,
                            modify: false,
                            group: 0,
                            custom: false,
                            text: "Keyword",
                            modifiedText: "Keyword",
                            value: "keyword",
                            tooltip: "The given search phrase",
                            modifiedtooltip: "The given search phrase",
                            width: "250",
                            align: "left"
                        },
                        {
                            active: true,
                            sortable: true,
                            canBeModified: true,
                            modify: false,
                            group: 0,
                            custom: false,
                            text: "Google Searchvolume (avg. monthly)",
                            modifiedText: "Google Searchvolume (avg. monthly)",
                            value: "avgsv",
                            tooltip: "Represents the average search queries via google per month",
                            modifiedtooltip: "Represents the average search queries via google per month",
                            width: "150",
                            align: "left"
                        },
                        {
                            active: true,
                            sortable: true,
                            canBeModified: true,
                            modify: false,
                            group: 0,
                            custom: false,
                            text: "Google Ranking",
                            modifiedText: "Google Ranking",
                            value: "ranking",
                            tooltip: "Represents the average search queries via google per month",
                            modifiedtooltip: "Represents the average search queries via google per month",
                            width: "150",
                            align: "end"
                        },
                        {
                            active: true,
                            sortable: false,
                            canBeModified: true,
                            modify: false,
                            group: 0,
                            custom: false,
                            text: "Searchvolume Trend",
                            modifiedText: "Searchvolume Trend",
                            value: "trend",
                            tooltip: "Represents the searches from january to december for each keyword",
                            modifiedtooltip: "Represents the searches from january to december for each keyword",
                            width: "80"
                        },
                        {
                            active: true,
                            sortable: false,
                            canBeModified: true,
                            modify: false,
                            group: 0,
                            text: "Categories",
                            modifiedText: "Categories",
                            value: "cats",
                            tooltip: "Represents the product and service categories for each keyword",
                            modifiedtooltip: "Represents the product and service categories for each keyword",
                            width: "80"
                        },
                        {
                            active: true,
                            sortable: true,
                            canBeModified: true,
                            modify: false,
                            group: 0,
                            custom: false,
                            text: "CPC",
                            modifiedText: "CPC",
                            value: "adwords_cpc",
                            tooltip: "Represents the average cost per click (EUR) historically paid for the keyword",
                            modifiedtooltip: "Represents the average cost per click (EUR) historically paid for the keyword",
                            width: "100",
                            align: "left"
                        },
                        {
                            active: true,
                            sortable: true,
                            canBeModified: true,
                            modify: false,
                            group: 0,
                            custom: false,
                            text: "Competition",
                            modifiedText: "Competition",
                            value: "adwords_competition",
                            tooltip: "Represents the relative amount of competition associated with the given keyword in paid SERP only",
                            modifiedtooltip: "Represents the relative amount of competition associated with the given keyword in paid SERP only",
                            width: "150",
                            align: "left"
                        },
                        {
                            name: "sv_headers",
                            active: true,
                            columns: []
                        }
                    ],
                    modifiedHeaders: []
                },
                svminimum: 400,
                dataChart: {
                    selected: [],
                    labels: [],
                    datasets: []
                },
                countGraph: 0,
                countGraph2: 0,
                graphloaded: false,
                unfilteredKeywords: [],
                cardData: {
                    title: "Collection: ",
                    subtitle: "Analyze searchvolume of the last 12 month",
                    searchPhrase: []
                },
                auth: "Basic " + window.btoa(process.env.VUE_APP_AUTH),
                startup: true,
                addToSerpDisabled: false,
                selectedLocation: {},
                preview_sv_data: {}
            }
        },
        watch: {
            "$store.state.user_settings.advancedMode"(val) {
                this.keywords.map((x) => {
                    x.selected = false
                })
            }
        },
        computed: {
            // decryptedId(){
            //   return this.CryptoJS.AES.decrypt(this.userid, process.env.VUE_APP_ENCRYPTION_PW).toString(this.CryptoJS.enc.Utf8)
            // },
            decryptedComments() {
                this.comments.map((comment) => {
                    comment.username = this.CryptoJS.AES.decrypt(comment.username, process.env.VUE_APP_ENCRYPTION_PW).toString(this.CryptoJS.enc.Utf8)
                    return comment
                })
            },
            decryptedPlan() {
                if (this.plan.length > 0) {
                    return this.CryptoJS.AES.decrypt(this.plan, process.env.VUE_APP_ENCRYPTION_PW).toString(this.CryptoJS.enc.Utf8)
                } else {
                    return ""
                }
            },
            user_rights() {
                return this.$store.state.user_rights
            }
        },
        methods: {
            modifiedDropDownOptions(option, headerValue) {
                let rowsToModify = []
                this.keywords.map((keywordRow) => {
                    if (keywordRow.customs[headerValue].id == option.id) {
                        keywordRow.customs[headerValue] = option
                        rowsToModify.push(keywordRow)
                    }
                })
                if (rowsToModify.length > 0) {
                    let listname = {}
                    if (rowsToModify[0].listname) {
                        listname = rowsToModify[0].listname
                    } else {
                        listname = this.$route.params.researchname
                    }
                    this.updateCustomOptions(listname, rowsToModify, option, headerValue)
                }
            },
            updateCustomOptions(listname, keywords, valueToSet, headerValue) {
                this.$http
                    .post(
                        process.env.VUE_APP_APIURL + "/research/keywords/customs/add",
                        {
                            listname: listname,
                            company_id: localStorage.company_id,
                            keywords: keywords,
                            valueToSet: valueToSet,
                            headerValue: headerValue
                        },
                        { headers: { Authorization: this.auth } }
                    )
                    .then((response) => {})
            },
            fetchSERP(selected) {
                console.log("fetchSERP:", selected.length)
                if (!selected || selected.length == 0) {
                    this.notification = this.$helpers.createNotification("You have to select one keyword for this function!", "mdi-alert-circle-outline", "#FCBA03")
                    return
                }
                this.addToSerpDisabled = true
                this.serpKeyword = selected[0].keyword
                this.preview_sv_data = { keyword: selected[0].keyword, value: selected[0].avgsv }
                this.serpReload++
            },
            changedHeaders() {
                this.headers = this.$store.state.header_items
                console.log(this.headers)
                this.$http
                    .post(
                        process.env.VUE_APP_APIURL + "/research/updateHeaders",
                        {
                            listname: this.$route.params.researchname,
                            company_id: localStorage.company_id,
                            headers: this.headers
                        },
                        { headers: { Authorization: this.auth } }
                    )
                    .then((response) => {
                        // this.notification = this.$helpers.createNotification("The headers have been updated", "mdi-check-circle-outline", "#16C96D")
                    })
            },
            filterColumns() {
                if (this.unfilteredKeywords.length > 0) {
                    this.keywords = [...this.unfilteredKeywords]
                }
                this.unfilteredKeywords = [...this.keywords]

                this.keywords = this.$helpers.filterKeywordTable(this.unfilteredKeywords)
            },

            filterForCustomDropdown(initialObject, isArray, cat_for_filter) {
                console.log(initialObject)
                var resultObject = Object.fromEntries(Object.entries(initialObject).filter(([_, v]) => v.length != 0))

                if (Object.keys(resultObject).length === 0 && cat_for_filter.length == 0) {
                    this.keywords = [...this.unfilteredKeywords]
                    this.cardData.subtitle = this.keywords.length + " keywords"

                    console.log("filtered resetted")
                    return
                }
                if (this.unfilteredKeywords.length > 0) {
                    this.keywords = [...this.unfilteredKeywords]
                }
                this.unfilteredKeywords = [...this.keywords]

                this.keywords = []
                this.unfilteredKeywords.map((keyword) => {
                    if (Object.keys(resultObject).length > 0) {
                        Object.keys(resultObject).map((entry) => {
                            if (keyword.customs !== undefined && Object.keys(keyword.customs).includes(entry)) {
                                if (
                                    resultObject[entry]
                                        .map((obj) => {
                                            if (isArray) {
                                                return keyword.customs[entry].some((keyEntry) => keyEntry.text === obj.text)
                                            } else {
                                                return keyword.customs[entry].text === obj.text
                                            }
                                        })
                                        .includes(true)
                                ) {
                                    if (!this.keywords.some((k) => k === keyword)) {
                                        this.keywords.push(keyword)
                                    }
                                }
                            }
                        })
                    }
                    if (cat_for_filter.length > 0) {
                        if (keyword.cats) {
                            let catsofKeyword = keyword.cats[keyword.active_cat_index]
                            if (catsofKeyword) {
                                cat_for_filter.forEach((catforfilter) => {
                                    if (catsofKeyword[catsofKeyword.length - 1].category_name == catforfilter.category_name) {
                                        this.keywords.push(keyword)
                                    }
                                })
                            }
                        } else if (!keyword.cats) {
                            let findemptycat = cat_for_filter.find((x) => x.category_name == "-")
                            if (findemptycat) this.keywords.push(keyword)
                        }
                    }
                })
                console.log("filtered")
                console.log("filtered")
                console.log("filtered")
                console.log("filtered")
                console.log("filtered")
                console.log("filtered")
                // this.cardData.subtitle = this.keywords.length + " keywords"
            },
            /**
             *  * sets/changes primary category for keyword(s)
             * @param {Array} rows keyword(s) to modify
             * @param {Object} cat category to set
             */
            switchCat(rows, cat) {
                this.$helpers.switchCat(rows, cat, this.keywords)
                this.getListCats()
                this.rerenderKeywordTable()
                this.$http
                    .post(
                        process.env.VUE_APP_APIURL + "/research/keywords/categories/switch",
                        {
                            listname: this.$route.params.researchname,
                            company_id: localStorage.company_id,
                            keywords: rows.map((row) => row.keyword),
                            cat: cat
                        },
                        { headers: { Authorization: this.auth } }
                    )
                    .then((response) => {
                        console.log(response.body)
                    })
            },

            /**
             * sets/changes custom entry for keyword(s)
             * @param {Array} rows keyword(s) to modify
             * @param {Object} selectedEntry entry to set
             * @param {String} headerValue header/column text to match
             */
            addCustomEntries(rows, selectedEntry, headerValue, isBackend, isArray) {
                if (!rows) return
                let listname = {}
                if (rows[0].listname) {
                    listname = rows[0].listname
                } else {
                    listname = this.$route.params.researchname
                }
                let valueToSet = isArray ? [...selectedEntry] : selectedEntry
                rows.forEach((row) => {
                    let rowindex = this.keywords.indexOf(row)
                    if (rowindex > -1) {
                        if (this.keywords[rowindex].customs === undefined) {
                            this.keywords[rowindex].customs = {}
                        }
                        this.keywords[rowindex].customs[headerValue] = valueToSet
                    }
                })
                let obj = rows[0].customs
                // this.keywords.push({})
                // this.keywords.pop()
                this.updateCustomOptions(listname, rows, valueToSet, headerValue)
            },
            createNotification(notification) {
                this.notification = this.$helpers.createNotification(notification.message, notification.icon, notification.color)
            },
            fixselectError() {
                if (this.cat_for_filter.length > 0) {
                    this.$refs.selectComponent.$el.querySelector(".v-select__selections input").style.width = "40px"
                } else {
                    this.$refs.selectComponent.$el.querySelector(".v-select__selections input").style.width = ""
                }
            },
            filterForCat(cat_for_filter) {
                if (cat_for_filter.length == 0) {
                    this.keywords = [...this.unfilteredKeywords]
                    this.cardData.subtitle = this.keywords.length + " keywords"
                    return
                }
                if (this.unfilteredKeywords.length > 0) {
                    this.keywords = [...this.unfilteredKeywords]
                }
                this.unfilteredKeywords = [...this.keywords]

                this.keywords = []
                this.unfilteredKeywords.forEach((keyword) => {
                    if (keyword.cats) {
                        let catsofKeyword = keyword.cats[keyword.active_cat_index]
                        if (catsofKeyword) {
                            cat_for_filter.forEach((catforfilter) => {
                                if (catsofKeyword[catsofKeyword.length - 1].category_name == catforfilter.category_name) {
                                    this.keywords.push(keyword)
                                }
                            })
                        }
                    } else if (!keyword.cats) {
                        let findemptycat = cat_for_filter.find((x) => x.category_name == "-")
                        if (findemptycat) this.keywords.push(keyword)
                    }
                })
                this.cardData.subtitle = this.keywords.length + " keywords"
            },

            removeCat(row, cat) {
                // remove cat
                let rowindex = this.keywords.indexOf(row)
                let catindex = this.keywords[rowindex].cats.indexOf(cat)
                if (this.keywords[rowindex].cats.length < 2) {
                    this.keywords[rowindex].cats = null
                    this.rerenderKeywordTable()
                } else {
                    this.keywords[rowindex].cats.splice(catindex, 1)
                    this.rerenderKeywordTable()
                }
                this.$http
                    .post(
                        process.env.VUE_APP_APIURL + "/research/keyword/categories/add",
                        {
                            listname: this.$route.params.researchname,
                            company_id: localStorage.company_id,
                            keyword: row.keyword,
                            cats: this.keywords[rowindex].cats
                        },
                        { headers: { Authorization: this.auth } }
                    )
                    .then((response) => {})
            },
            getTree(category) {
                let tree = []
                tree[0] = category
                var findParent = (category) => {
                    let parent = this.catData.filter((x) => x.category_code == category.category_code_parent)
                    if (parent[0]) {
                        tree.unshift(parent[0])
                        if (parent[0].category_code_parent) {
                            findParent(parent[0])
                        }
                    }
                }
                if (category.category_code_parent) {
                    findParent(category)
                }
                return tree
            },
            addCategories(rows, selectedCats) {
                //adds categories to a keyword
                if (!selectedCats[0]) return

                rows.forEach((row) => {
                    let rowindex = this.keywords.indexOf(row)
                    for (let index = 0; index < selectedCats.length; index++) {
                        let tree = this.getTree(selectedCats[index])

                        if (this.keywords[rowindex].cats) {
                            this.keywords[rowindex].cats.unshift(tree)
                        } else {
                            this.keywords[rowindex].cats = [tree]
                        }
                        console.log(row)
                        console.log(tree)
                    }
                })
                for (let index = 0; index < selectedCats.length; index++) {
                    let tree = this.getTree(selectedCats[index])
                    this.switchCat(rows, tree)
                }

                // for (let index = 0; index < selectedCats.length; index++) {
                //     let tree = this.getTree(selectedCats[index])

                //     if (this.keywords[rowindex].cats) {
                //         this.keywords[rowindex].cats.unshift(tree)
                //     } else {
                //         this.keywords[rowindex].cats = [tree]
                //     }
                //     console.log(row)
                //     console.log(tree)
                //     this.switchCat(row, tree)

                // let listname = ""
                // if (!row.listname) {
                //     listname = this.$route.params.researchname
                // } else {
                //     listname = row.listname
                // }

                this.$http
                    .post(
                        process.env.VUE_APP_APIURL + "/research/keywords/categories/add",
                        {
                            listname: this.$route.params.researchname,
                            company_id: localStorage.company_id,
                            keywords: this.keywords
                            // cats: this.keywords[rowindex].cats
                        },
                        { headers: { Authorization: this.auth } }
                    )
                    .then((response) => {})
                // }
                this.getListCats()
                this.rerenderKeywordTable()
            },
            getListCats() {
                let catsOfList = []
                this.keywords.forEach((keyword) => {
                    // console.log(keyword.cats)
                    let cats = keyword.cats
                    if (cats && cats != []) {
                        if (cats[0]) {
                            if (!cats[keyword.active_cat_index]) {
                                console.log(keyword.keyword)
                                console.log(cats)
                                console.log(keyword.active_cat_index)
                            }
                            if (cats[keyword.active_cat_index][cats[keyword.active_cat_index].length - 1]) {
                                catsOfList.push(cats[keyword.active_cat_index][cats[keyword.active_cat_index].length - 1])
                            }
                        }
                    }
                })

                catsOfList.sort((a, b) => a.category_name.localeCompare(b.category_name))
                catsOfList.unshift({ category_name: "-" })
                // console.log(catsOfList)
                this.catsOfList = catsOfList
            },
            getListSerps() {
                let serpsOfList = []
                this.keywords.forEach((keyword) => {
                    // console.log(keyword.serp_items)
                    let serps = keyword.serp_items
                    if (serps) {
                        serps.map((x) => {
                            serpsOfList.push(x)
                        })
                    }
                })
                serpsOfList = new Set(serpsOfList)
                serpsOfList = Array.from(serpsOfList)

                serpsOfList.sort((a, b) => a.value - b.value)
                serpsOfList.unshift({ value: "-", tooltip: "-:" })
                console.log(serpsOfList)
                this.serpsOfList = serpsOfList
            },
            getListSeasons() {
                let seasonsOfList = []
                this.keywords.forEach((keyword) => {
                    if (keyword.season) {
                        seasonsOfList.push(keyword.season.season)
                    }
                })
                seasonsOfList = new Set(seasonsOfList)
                seasonsOfList = Array.from(seasonsOfList)
                console.log(seasonsOfList)

                seasonsOfList.sort()
                seasonsOfList.unshift("-")
                this.seasonsOfList = seasonsOfList
            },
            rerenderKeywordTable() {
                this.$refs.kw_table.rerenderKeywordTable()
            },
            commentInput() {
                console.log("input")
            },
            transformCommentDate(commentDate) {
                let commentDateAsDate = new Date(commentDate)
                let transformedDate = commentDateAsDate.toLocaleString("de-DE", {
                    year: "numeric",
                    month: "numeric",
                    day: "numeric"
                })
                let transformedTime = commentDateAsDate.toLocaleString("de-DE", {
                    hour: "numeric",
                    minute: "numeric",
                    hour12: true
                })
                // console.log(transformedDate)
                return transformedTime + " - " + transformedDate
            },
            changeCommentText(commentId) {
                var div = document.getElementById("comment" + commentId)
                div.contentEditable = true
                div.focus()
                // div.style.backgroundColor = '#E0E0E0';
                div.style.border = "1px dotted black"

                var editbtn = document.getElementById("editbtn" + commentId)
                editbtn.style.display = "none"

                var checkbtn = document.getElementById("checkbtn" + commentId)
                checkbtn.style.display = "inline-flex"
            },
            editComment(commentId) {
                let newComment = document.getElementById("comment" + commentId).innerHTML
                console.log(newComment)
                this.$http
                    .post(
                        process.env.VUE_APP_APIURL + "/comments/research/edit",
                        {
                            comment: newComment,
                            commentId: commentId,
                            company_id: localStorage.company_id,
                            listname: this.$route.params.researchname
                        },
                        { headers: { Authorization: this.auth } }
                    )
                    .then((response) => {
                        var editbtn = document.getElementById("editbtn" + commentId)
                        editbtn.style.display = "inline-flex"
                        var div = document.getElementById("comment" + commentId)
                        div.contentEditable = false
                        div.style.border = "0px"
                        var checkbtn = document.getElementById("checkbtn" + commentId)
                        checkbtn.style.display = "none"

                        this.getComments()
                        this.$socket.emit("commentsChanged", {
                            company_id: localStorage.company_id,
                            openCommentMenu: false,
                            research: this.$route.params.researchname,
                            encrypted: true
                        })
                    })
            },
            saveComment() {
                this.addCommentMenu = false
                if (this.comment.length > 0) {
                    console.log(localStorage)
                    let comment = {
                        comment: this.comment,
                        company_id: localStorage.company_id,
                        user: localStorage.id,
                        username: localStorage.username,
                        listname: this.$route.params.researchname
                    }
                    this.$http.post(process.env.VUE_APP_APIURL + "/comments/research/add", comment, { headers: { Authorization: this.auth } }).then((response) => {
                        this.comment = ""
                        this.$socket.emit("commentsChanged", {
                            company_id: localStorage.company_id,
                            openCommentMenu: true,
                            research: this.$route.params.researchname,
                            encrypted: true
                        })
                        this.getComments()
                    })
                }
            },
            deleteComment(commentId) {
                let comment = {
                    company_id: localStorage.company_id,
                    listname: this.$route.params.researchname,
                    commentId: commentId
                }
                this.$http.post(process.env.VUE_APP_APIURL + "/comments/research/delete", comment, { headers: { Authorization: this.auth } }).then((response) => {
                    this.getComments()
                    this.$socket.emit("commentsChanged", {
                        company_id: localStorage.company_id,
                        openCommentMenu: false,
                        research: this.$route.params.researchname,
                        encrypted: true
                    })
                })
            },
            getComments() {
                this.$http
                    .post(
                        process.env.VUE_APP_APIURL + "/comments/research",
                        {
                            company_id: localStorage.company_id,
                            listname: this.$route.params.researchname
                        },
                        { headers: { Authorization: this.auth } }
                    )
                    .then((response) => {
                        //  console.log(response.body)
                        this.comments = response.body
                        this.decryptedComments
                        if (response.body.length > 0 && this.startup) {
                            this.commentMenu = true
                            this.startup = false
                        }
                        console.log(this.comments)
                    })
            },
            // startAlert(alert){
            //   //console.log(alert)

            //   this[alert] = true

            //   setTimeout(() => {
            //      this[alert] = false
            //   }, 5000);

            // },
            // selectAll(){

            //   if(this.selected.length == this.keywords.length){
            //       this.selected = [];
            //   }else{
            //       this.selected = this.keywords;
            //   }
            //   try {
            //     this.$matomo.trackEvent("Research", "selectAll", "length: "+ this.selected.length , "" );
            //   } catch (error) {
            //     console.log(error)
            //     console.log("matomo blocked")
            //   }

            // },

            prepareDeleteKw(selected) {
                this.selected = selected
                this.deleteQuestion = true
            },

            deleteKeywords(keywords) {
                if (this.selected.length > 0) {
                    if (this.$route.query.findKeyword) {
                        try {
                            for (var i = 0; i < this.selected.length; i++) {
                                let index = this.keywords.indexOf(this.selected[i])
                                this.keywords.splice(index, 1)
                            }
                            if (this.unfilteredKeywords.length > 0) {
                                for (var i = 0; i < this.selected.length; i++) {
                                    let index = this.unfilteredKeywords.indexOf(this.selected[i])
                                    this.unfilteredKeywords.splice(index, 1)
                                }
                            }
                            this.$matomo.trackEvent("FindKeywords", "deleteKeywords", "KeywordsCount: " + this.selected.length, "")
                        } catch (error) {
                            console.log(error)
                            console.log("matomo blocked")
                        }
                        let kwCount = this.unfilteredKeywords.length > this.keywords.length ? this.unfilteredKeywords.length : this.keywords.length

                        this.$refs.kw_table.keywordsDeleted(kwCount)
                        let selectedNoDoubles = this.$helpers.checkGraphDoubles(this.selected, this.dataChart.datasets)
                        for (var i = 0; i < this.selected.length; i++) {
                            let index = selectedNoDoubles.findIndex((set) => {
                                if (set.keyword == this.selected[i].keyword) return set
                            })
                            if (index > -1) selectedNoDoubles.splice(index, 1)
                        }
                        let kwForGraph = this.keywords
                        if (this.unfilteredKeywords.length > this.keywords.length) {
                            kwForGraph = this.unfilteredKeywords
                        }
                        if (kwForGraph.length > 0) {
                            var graphObjects = this.$helpers.addToChart(this.dataChart.datasets, this.dataChart.labels, kwForGraph, selectedNoDoubles)
                            this.dataChart.datasets = graphObjects.graphdata
                            this.dataChart.labels = graphObjects.graphlabel
                            if (this.dataChart.datasets.length > 1) {
                                this.dataChart.datasets[0].hidden = true
                            } else {
                                this.dataChart.datasets[0].hidden = false
                            }
                            this.countGraph2++
                            this.getListCats()
                            this.getListSerps()
                            this.getListSeasons()
                        }

                        this.deleteQuestion = false
                        this.notification = this.$helpers.createNotification("The selected keywords have been deleted!", "mdi-check-circle-outline", "#16C96D")

                        this.cardData.subtitle = this.keywords.length + " keywords"
                    } else {
                        this.$http
                            .post(
                                process.env.VUE_APP_APIURL + "/research/listdata",
                                {
                                    listname: this.$route.params.researchname,
                                    company_id: localStorage.company_id
                                },
                                { headers: { Authorization: this.auth } }
                            )
                            .then((response) => {
                                if (response.body[0].mdata.status !== "updating" && response.body[0].mdata.status !== "creating") {
                                    //console.log(keywords)
                                    try {
                                        this.$matomo.trackEvent("Research", "deleteKeywords", "length: " + this.selected.length, "")
                                    } catch (error) {
                                        console.log(error)
                                        console.log("matomo blocked")
                                    }
                                    let data = {
                                        keywords: [],
                                        listname: this.$route.params.researchname,
                                        newCount: this.keywords.length - this.selected.length,
                                        company_id: localStorage.company_id
                                    }
                                    for (let keywordscounter = 0; keywordscounter < this.selected.length; keywordscounter++) {
                                        data.keywords.push(this.selected[keywordscounter].keyword)
                                    }

                                    this.$http.post(process.env.VUE_APP_APIURL + "/research/deleteKeyword", data, { headers: { Authorization: this.auth } }).then((response) => {
                                        for (var i = 0; i < this.selected.length; i++) {
                                            const index = this.keywords.indexOf(this.selected[i])
                                            this.keywords.splice(index, 1)
                                        }
                                        if (this.unfilteredKeywords.length > 0) {
                                            for (var i = 0; i < this.selected.length; i++) {
                                                let index = this.unfilteredKeywords.indexOf(this.selected[i])
                                                this.unfilteredKeywords.splice(index, 1)
                                            }
                                        }
                                        let kwCount = this.unfilteredKeywords.length > this.keywords.length ? this.unfilteredKeywords.length : this.keywords.length

                                        this.$refs.kw_table.keywordsDeleted(kwCount)
                                        let selectedNoDoubles = this.$helpers.checkGraphDoubles(this.selected, this.dataChart.datasets)
                                        for (var i = 0; i < this.selected.length; i++) {
                                            let index = selectedNoDoubles.findIndex((set) => {
                                                if (set.keyword == this.selected[i].keyword) return set
                                            })
                                            if (index > -1) selectedNoDoubles.splice(index, 1)
                                        }
                                        let kwForGraph = this.keywords
                                        if (this.unfilteredKeywords.length > this.keywords.length) {
                                            kwForGraph = this.unfilteredKeywords
                                        }
                                        if (kwForGraph.length > 0) {
                                            var graphObjects = this.$helpers.addToChart(this.dataChart.datasets, this.dataChart.labels, kwForGraph, selectedNoDoubles)
                                            this.dataChart.datasets = graphObjects.graphdata
                                            this.dataChart.labels = graphObjects.graphlabel
                                            if (this.dataChart.datasets.length > 1) {
                                                this.dataChart.datasets[0].hidden = true
                                            } else {
                                                this.dataChart.datasets[0].hidden = false
                                            }
                                            this.countGraph2++
                                            this.getListCats()
                                            this.getListSerps()
                                            this.getListSeasons()
                                        }

                                        this.selected = []
                                        this.keywords.map((x) => {
                                            x.selected = false
                                        })
                                        this.deleteQuestion = false
                                        this.notification = this.$helpers.createNotification("The selected keywords have been deleted!", "mdi-check-circle-outline", "#16C96D")

                                        this.cardData.subtitle = this.keywords.length + " keywords"
                                    })
                                } else {
                                    this.notification = this.$helpers.createNotification(
                                        "This research is currently being updated. Please try again in a few moments!",
                                        "mdi-alert-circle-outline",
                                        "#fcba03"
                                    )
                                }
                            })
                    }
                } else {
                    this.notification = this.$helpers.createNotification("You have to select at least one keyword for this function!", "mdi-alert-circle-outline", "#FCBA03")
                }
            },
            resetFilter() {
                this.table1.subtitle = this.apiRows.length + " keywords"
                this.rows = this.apiRows
                // this.selectedFilters[0].tags = helper.cleanArray(this.apiType).sort();
                // this.selectedFilters[1].tags = helper.cleanArray(this.apiBrand).sort();
                // this.selectedFilters[2].tags = helper.cleanArray(this.apiPreposition).sort();
                // this.selectedFilters[3].tags = helper.cleanArray(this.apiVerb).sort();
                // this.selectedFilters[4].tags = helper.cleanArray(this.apiAdjective).sort();
                // this.selectedFilters[5].tags = helper.cleanArray(this.apiNoun).sort();
                // this.selectedFilters[6].tags = helper.cleanArray(this.apiTransactional).sort();
                // this.selectedFilters[7].tags = helper.cleanArray(this.apiInformative).sort();
                // this.selectedFilters[8].tags = helper.cleanArray(this.apiColor).sort();
                // this.selectedFilters[9].tags = helper.cleanArray(this.apiMaterial).sort();
                for (var apiCounter = 0; apiCounter < this.apiData.length; apiCounter++) {
                    this.selectedFilters[apiCounter].tags = this.$helpers.cleanArray(this.apiData[apiCounter].data).sort()
                }

                for (var selectCounter = 0; selectCounter < this.selectedFilters.length; selectCounter++) {
                    this.selectedFilters[selectCounter].selected.length = 0
                }
            },
            filterKeywords() {
                //console.log("filter")
                //console.log(this.selectedFilters)
                if (
                    this.selectedFilters[0].selected.length == 0 &&
                    this.selectedFilters[1].selected.length == 0 &&
                    this.selectedFilters[2].selected.length == 0 &&
                    this.selectedFilters[3].selected.length == 0 &&
                    this.selectedFilters[4].selected.length == 0 &&
                    this.selectedFilters[5].selected.length == 0 &&
                    this.selectedFilters[6].selected.length == 0 &&
                    this.selectedFilters[7].selected.length == 0 &&
                    this.selectedFilters[8].selected.length == 0 &&
                    this.selectedFilters[9].selected.length == 0
                ) {
                    this.table1.subtitle = this.apiRows.length + " keywords"
                    this.rows = this.apiRows
                    for (var tagCounter = 0; tagCounter < this.selectedFilters.length; tagCounter++) {
                        this.selectedFilters[tagCounter].tags = this.$helpers.cleanArray(this.apiData[tagCounter].data).sort()
                    }
                    // this.selectedFilters[0].tags = helper.cleanArray(this.apiType).sort();
                    // this.selectedFilters[1].tags = helper.cleanArray(this.apiBrand).sort();
                    // this.selectedFilters[2].tags = helper.cleanArray(this.apiPreposition).sort();
                    // this.selectedFilters[3].tags = helper.cleanArray(this.apiVerb).sort();
                    // this.selectedFilters[4].tags = helper.cleanArray(this.apiAdjective).sort();
                    // this.selectedFilters[5].tags = helper.cleanArray(this.apiNoun).sort();
                    // this.selectedFilters[6].tags = helper.cleanArray(this.apiTransactional).sort();
                    // this.selectedFilters[7].tags = helper.cleanArray(this.apiInformative).sort();
                    // this.selectedFilters[8].tags = helper.cleanArray(this.apiColor).sort();
                    // this.selectedFilters[9].tags = helper.cleanArray(this.apiMaterial).sort();
                } else {
                    var conditions = []
                    for (var conditionCounter = 0; conditionCounter < this.selectedFilters.length; conditionCounter++) {
                        if (this.selectedFilters[conditionCounter].selected.length > 0) {
                            conditions.push(this.selectedFilters[conditionCounter].condition)
                        }
                    }

                    // if (this.selectedFilters[0].selected.length > 0){
                    //   conditions.push("that.selectedFilters[0].selected.includes(row.type)");
                    // }
                    // if (this.selectedFilters[1].selected.length > 0){
                    //   conditions.push("that.selectedFilters[1].selected.includes(row.brand)");
                    // }
                    // if (this.selectedFilters[2].selected.length > 0){
                    //   conditions.push("that.selectedFilters[2].selected.includes(row.preposition)");
                    // }
                    // if (this.selectedFilters[3].selected.length > 0){
                    //   conditions.push("that.selectedFilters[3].selected.includes(row.verb)");
                    // }
                    // if (this.selectedFilters[4].selected.length > 0){
                    //   conditions.push("that.selectedFilters[4].selected.includes(row.adjective)");
                    // }
                    // if (this.selectedFilters[5].selected.length > 0){
                    //   conditions.push("that.selectedFilters[5].selected.includes(row.noun)");
                    // }
                    // if (this.selectedFilters[6].selected.length > 0){
                    //   conditions.push("that.selectedFilters[6].selected.includes(row.transactional)");
                    // }
                    // if (this.selectedFilters[7].selected.length > 0){
                    //   conditions.push("that.selectedFilters[7].selected.includes(row.informative)");
                    // }
                    // if (this.selectedFilters[8].selected.length > 0){
                    //   conditions.push("that.selectedFilters[8].selected.includes(row.color)");
                    // }
                    // if (this.selectedFilters[9].selected.length > 0){
                    //   conditions.push("that.selectedFilters[9].selected.includes(row.material)");
                    // }
                    var conditionString = ""
                    for (var counter = 0; counter < conditions.length; counter++) {
                        conditionString += conditions[counter]
                        if (counter < conditions.length - 1) {
                            conditionString += "&&"
                        }
                    }
                    //console.log(conditionString)
                    var that = this
                    var filteredRows = this.apiRows.filter(function (row) {
                        return eval(conditionString)
                        // that.selectedFilters[1].selected.includes(row.brand) &&
                        // that.selectedFilters[2].selected.includes(row.preposition) &&
                        // that.selectedFilters[3].selected.includes(row.verb) &&
                        // that.selectedFilters[4].selected.includes(row.adjective) &&
                        // that.selectedFilters[5].selected.includes(row.noun) &&
                        // that.selectedFilters[6].selected.includes(row.transactional) &&
                        // that.selectedFilters[7].selected.includes(row.informative) &&
                        // that.selectedFilters[8].selected.includes(row.color) &&
                        // that.selectedFilters[9].selected.includes(row.material);
                    })
                    this.table1.subtitle = filteredRows.length + " keywords"
                    this.rows = filteredRows

                    this.changeFilters(filteredRows)
                }
            },

            formatNumber(number) {
                return formatNumber(number)
            },

            addToChart(selected) {
                let graphdata = this.dataChart.datasets
                let selectedNoDoubles = this.$helpers.checkGraphDoubles(selected, graphdata)
                //console.log(selectedNoDoubles)
                if (selected.length > 0) {
                    if (selectedNoDoubles.length > 0) {
                        try {
                            this.$matomo.trackEvent("Research", "addToChart", "length: " + selectedNoDoubles.length, "")
                        } catch (error) {
                            console.log(error)
                            console.log("matomo blocked")
                        }
                        if (this.$helpers.checkSelectedSizeForGraph(selectedNoDoubles, 11, graphdata)) {
                            let graphObjects = this.$helpers.addToChart(this.dataChart.datasets, this.dataChart.labels, this.keywords, selectedNoDoubles)
                            this.dataChart.datasets = graphObjects.graphdata
                            this.dataChart.labels = graphObjects.graphlabel
                            if (this.dataChart.datasets[0].label == "Sum of all keywords") {
                                this.dataChart.datasets[0].hidden = true
                            }
                            // Hilfsvariable wird hochgezählt und Aktion in linechart.js ausgelöst
                            this.countGraph2++
                        } else {
                            this.notification = this.$helpers.createNotification("Only 10 keywords can be displayed in graph!", "mdi-alert-circle-outline", "#FCBA03")
                        }
                    } else {
                        this.notification = this.$helpers.createNotification("Selected keywords are displayed in the graph already!", "mdi-alert-circle-outline", "#FCBA03")
                    }
                } else {
                    this.notification = this.$helpers.createNotification("You have to select at least one keyword for this function!", "mdi-alert-circle-outline", "#FCBA03")
                }
            },
            getSparklineData(ranking) {
                let svArray = []
                let svArrayCounter = 0
                for (let searchvolumesCounter = ranking.search_volumes.length - 1; searchvolumesCounter > -1; searchvolumesCounter--) {
                    svArray[svArrayCounter] = ranking.search_volumes[searchvolumesCounter].count
                    svArrayCounter++
                }
                return svArray
            },

            fetchAllCats() {
                this.$http
                    .post(process.env.VUE_APP_APIURL + "/categories", { company_id: localStorage.company_id }, { headers: { Authorization: this.auth } })
                    .then((response) => {
                        let cats = response.body.categories.filter((x) => x != null && x.category_name)
                        // console.log(cats)
                        this.catData = cats.sort((a, b) => a.category_name.localeCompare(b.category_name))
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            },
            addRankings(url, device) {
                this.$helpers.addRankings(this.keywords, this.headers, this.collection_information.mdata.langloc.loc, url, device)
            },
            fetchResearch() {
                this.loading = true
                console.log("loading true", "Research.vue")
                this.fetchAllCats()
                this.cardData.title = "Collection: " + this.$route.params.researchname
                this.rows = []
                let keywords = []
                this.$http
                    .post(
                        process.env.VUE_APP_APIURL + "/research/",
                        {
                            research: this.$route.params.researchname,
                            svminimum: this.svminimum,
                            company_id: localStorage.company_id
                        },
                        { headers: { Authorization: this.auth } }
                    )
                    .then(async function (response) {
                        //do things with listdata
                        this.headers = response.body.listResponse[0].mdata.headers

                        console.log(response.body.listResponse[0])
                        this.collection_information = { listname: response.body.listResponse[0].listname, _id: response.body.listResponse[0]._id, mdata: response.body.listResponse[0].mdata }

                        // for (let index = 0; index < this.stateItems.length; index++) {
                        //     if (this.stateItems[index].value == response.body.listResponse[0].mdata.langloc.loc) {
                        //         this.selectedLocation = this.stateItems[index]
                        //         this.siteKeywordsLocation = this.stateItems[index]
                        //     }
                        // }
                        this.selectedLocation = response.body.listResponse[0].mdata.langloc_new
                        this.siteKeywordsLocation = response.body.listResponse[0].mdata.langloc_new

                        // do things with keyworddata
                        console.log(response)
                        for (let index = 0; index < response.body.keywordResponse.count; index++) {
                            let keyworddata = response.body.keywordResponse.keywords[index]
                            let annual = 0
                            for (let sumindex = 0; sumindex < 12; sumindex++) {
                                annual += parseInt(keyworddata.searchvolume[sumindex].volume) || 0
                            }
                            // console.log(keyworddata.categories)
                            if (keyworddata.active_cat_index) console.log(keyworddata.active_cat_index)
                            let row = {
                                keyword: keyworddata.keyword,
                                annualsv: annual,
                                avgsv: parseInt(annual / 12),
                                search_volumes: [],
                                categories: keyworddata.categories ? (keyworddata.categories.length ? keyworddata.categories : null) : null,
                                cats: keyworddata.categories_edited ? (keyworddata.categories_edited.length ? keyworddata.categories_edited : null) : null,
                                adwords_cpc: keyworddata.adwords_cpc,
                                adwords_cpc_low: keyworddata.adwords_cpc_low,
                                adwords_cpc_high: keyworddata.adwords_cpc_high,
                                adwords_competition: keyworddata.adwords_competition,
                                serp_items: await dataprocessing.getSERPItemsObject(keyworddata.serp_items),
                                keyword_difficulty: dataprocessing.getDifficultyObject(keyworddata.keyword_difficulty),
                                active_cat_index: keyworddata.active_cat_index ? keyworddata.active_cat_index : 0,
                                customs: keyworddata.customs !== null || keyworddata.customs !== undefined ? keyworddata.customs : null
                            }

                            if (this.$route.query.findKeyword) delete row.customs

                            for (let monthCounter = 0; monthCounter < keyworddata.searchvolume.length; monthCounter++) {
                                let volume = {
                                    count: parseInt(keyworddata.searchvolume[monthCounter].volume) || 0,
                                    year: keyworddata.searchvolume[monthCounter].year,
                                    month: this.returnMonth(keyworddata.searchvolume[monthCounter].month)
                                }
                                row.search_volumes.push(volume)

                                let svname = ""
                                if (this.returnMonth(keyworddata.searchvolume[monthCounter].month) < 10) {
                                    svname = keyworddata.searchvolume[monthCounter].year.toString() + "0" + this.returnMonth(keyworddata.searchvolume[monthCounter].month)
                                } else {
                                    svname = keyworddata.searchvolume[monthCounter].year.toString() + this.returnMonth(keyworddata.searchvolume[monthCounter].month)
                                }
                                row[svname] = parseInt(keyworddata.searchvolume[monthCounter].volume) || 0
                            }
                            keywords.push(row)
                        }
                        this.btndisabled = false
                        keywords = this.$helpers.removeDuplicatesByAttribute(keywords, "keyword")
                        keywords.sort(function (a, b) {
                            return b.avgsv - a.avgsv
                        })
                        this.keywords = keywords

                        // add rankings
                        if (this.$store.state.monitoring_suite) this.addRankings("www.xxxlutz.de", 0)

                        this.fetchSERP([this.keywords[0]])
                        let graphObjects = this.$helpers.buildGraphObjects([], keywords, this.dataChart.datasets, this.dataChart.labels)
                        // console.log(graphObjects)
                        this.dataChart.datasets = graphObjects.graphdata
                        this.dataChart.labels = graphObjects.graphlabel
                        // this.dataChart.datasets = graphDataSets
                        this.countGraph2++
                        this.graphloaded = true
                        console.log("loading false", "Research.vue")
                        this.loading = false
                        this.renderPieChart++
                        this.cardData.subtitle = response.body.keywordResponse.count + " keywords"
                        this.$helpers.addPeakAndSeason(this.keywords)
                        this.getListCats()
                        this.getListSerps()
                        this.getListSeasons()

                        if (this.$store.state.active_filter.length) this.filterColumns()
                    })
            },
            fetchKeyword() {
                this.fetchAllCats()
                this.rows = []
                let keywords = []
                // process.env.VUE_APP_APIURL + '/research/findkeywords',{kw: this.$route.query.findKeyword , limit: 0, svminimum: 0, nodata: false,company_id: localStorage.company_id},
                this.$http
                    .post(
                        process.env.VUE_APP_APIURL + "/research/findkeywords",
                        {
                            kw: this.$route.query.findKeyword,
                            limit: 0,
                            svminimum: 0,
                            nodata: false,
                            company_id: localStorage.company_id
                        },
                        { headers: { Authorization: this.auth } }
                    )
                    .then(function (response) {
                        console.log(response)
                        let uniqueKeywords = this.$helpers.removeDuplicatesByAttribute(response.body.keywords, "keyword")
                        this.cardData.title = "Keywords found for searchquery: " + this.$route.query.findKeyword
                        for (let index = 0; index < uniqueKeywords.length; index++) {
                            let keyworddata = uniqueKeywords[index]
                            let annual = 0
                            for (let sumindex = 0; sumindex < 12; sumindex++) {
                                annual += parseInt(keyworddata.searchvolume[sumindex].volume)
                            }
                            const row = {
                                keyword: keyworddata.keyword,
                                annualsv: annual,
                                avgsv: parseInt(annual / 12),
                                search_volumes: [],
                                categories: keyworddata.categories ? (keyworddata.categories.length ? keyworddata.categories : null) : null,
                                cats: keyworddata.categories_edited ? (keyworddata.categories_edited.length ? keyworddata.categories_edited : null) : null,
                                adwords_cpc: keyworddata.adwords_cpc,
                                adwords_competition: keyworddata.adwords_competition,
                                active_cat_index: keyworddata.active_cat_index ? keyworddata.active_cat_index : 0
                            }

                            for (let monthCounter = 0; monthCounter < keyworddata.searchvolume.length; monthCounter++) {
                                let volume = {
                                    count: parseInt(keyworddata.searchvolume[monthCounter].volume),
                                    year: keyworddata.searchvolume[monthCounter].year,
                                    month: this.returnMonth(keyworddata.searchvolume[monthCounter].month)
                                }
                                row.search_volumes.push(volume)

                                let svname = ""
                                if (this.returnMonth(keyworddata.searchvolume[monthCounter].month) < 10) {
                                    svname = keyworddata.searchvolume[monthCounter].year.toString() + "0" + this.returnMonth(keyworddata.searchvolume[monthCounter].month)
                                } else {
                                    svname = keyworddata.searchvolume[monthCounter].year.toString() + this.returnMonth(keyworddata.searchvolume[monthCounter].month)
                                }
                                row[svname] = parseInt(keyworddata.searchvolume[monthCounter].volume) || 0
                            }
                            keywords.push(row)
                        }
                        //console.log(this.keywords)
                        this.createHeader(keywords)
                        this.btndisabled = false
                        keywords = this.$helpers.removeDuplicatesByAttribute(keywords, "keyword")
                        keywords.sort(function (a, b) {
                            return b.avgsv - a.avgsv
                        })
                        this.keywords = keywords

                        let graphObjects = this.$helpers.buildGraphObjects([], keywords, this.dataChart.datasets, this.dataChart.labels)
                        // console.log(graphObjects)
                        this.dataChart.datasets = graphObjects.graphdata
                        this.dataChart.labels = graphObjects.graphlabel
                        this.countGraph2++
                        this.graphloaded = true

                        this.loading = false
                        this.cardData.subtitle = uniqueKeywords.length + " keywords"
                    })
            },
            createHeader(keywords) {
                this.headers = this.fetchKeywordsHeaders
                let monthArray = ["", "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
                const researchHeaderIndex = this.headers.standardHeaders.findIndex((p) => p.name === "sv_headers")
                let headers = []
                keywords.map((keyword) => {
                    keyword.search_volumes.map((vol) => {
                        let header = {
                            group: 1,
                            custom: false,
                            active: true,
                            canBeModified: false,
                            modifiedText: "Searches " + monthArray[vol.month] + "-" + vol.year,
                            text: "Searches " + monthArray[vol.month] + "-" + vol.year,
                            sortable: true,
                            width: "160"
                        }
                        if (vol.month < 10) {
                            header.value = vol.year.toString() + "0" + vol.month.toString()
                        } else {
                            header.value = vol.year.toString() + vol.month.toString()
                        }
                        header.dataChartLabel = monthArray[vol.month] + " " + vol.year
                        // console.log(headers.findIndex(p => p.text === header.text) === -1 )
                        if (headers.findIndex((p) => p.text === header.text) === -1) headers.push(header)
                    })
                })
                headers.sort(function (a, b) {
                    return a.value - b.value
                })
                this.headers.standardHeaders[researchHeaderIndex].columns = headers
                this.headers.modifiedHeaders = JSON.parse(JSON.stringify(this.headers.standardHeaders))
                console.log("Erstelle Header", this.headers)
            },
            returnMonth(month) {
                let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]

                return months.indexOf(month) + 1
            },
            rerenderChart() {
                this.countGraph2++
            },
            clearChart() {
                try {
                    this.$matomo.trackEvent("Research", "clearChart", "", "")
                } catch (error) {
                    console.log(error)
                    console.log("matomo blocked")
                }
                let graphObjects = this.$helpers.buildGraphObjects([], this.keywords, this.dataChart.datasets, this.dataChart.labels)
                // console.log(graphObjects)
                this.dataChart.datasets = graphObjects.graphdata
                this.dataChart.labels = graphObjects.graphlabel
                this.countGraph2++
            }
        },
        beforeRouteLeave(to, from, next) {
            // if (this.unfilteredKeywords.length) this.keywords = [...this.unfilteredKeywords]
            next()
        },
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                if (vm.$route.query.findKeyword) {
                    vm.fetchKeyword()
                    vm.selectedLocation = { loc: "Germany", value: "2276", cc: "de" }
                    vm.hideAddCommentMenu = true
                    //console.log("findkeywords")
                } else {
                    vm.fetchResearch()
                    vm.getComments()
                }
                // if (vm.keywords.length) {
                //     vm.unfilteredKeywords = [...vm.keywords]
                //     vm.keywords = vm.$helpers.filterKeywordTable(vm.unfilteredKeywords)
                // }

                //test end check
                if (vm.$store.state.company_status === "test_ended") {
                    vm.$router.push("/endoftest")
                }
            })
        },
        mounted() {
            this.$socket.on("reloadComments", (data) => {
                if (data.research == this.$route.params.researchname) {
                    console.log("Reloading Comments")
                    this.getComments()
                    if (data.openCommentMenu) {
                        this.commentMenu = true
                    }
                }
            })
        }
    }
</script>

<style>
    .v-card__subtitle {
        padding-bottom: 0px !important;
    }
    .chartCardHeading {
        padding-top: 23px;
        padding-left: 23px;
    }
    #cardtitle {
        padding-top: -30px !important;
        padding-bottom: 15px;
    }
    .fabicon {
        margin-left: 10px;
        margin-top: 10px;
    }
    .circularprogress {
        padding-top: 120px;
        padding-bottom: 180px;
    }
    #subtitle {
        margin-top: -35px;
        color: rgb(126, 126, 126);
    }
    #buttonFlex {
        padding-left: 2px;
    }
    #downloaddialog {
        height: 500px;
    }
    #selectfiletype {
        padding-top: 25px !important;
        padding-bottom: 40px !important;
        padding-right: 30px !important;
    }
    #title {
        padding-top: 16px;
        line-height: 25px;
        font-size: 12px;
        font-weight: 600;
        text-transform: uppercase;
        color: #212529;
        font-size: 0.85em;
    }
    #maintitle {
        color: rgba(0, 0, 0, 0.6);
        padding-top: 3px;
        line-height: 25px;
        font-size: 14px !important;
        font-weight: 600;
        text-transform: uppercase;
        text-align: center !important;
        font-size: 1em;
    }
    #divider {
        border: 1px solid rgb(231, 231, 231);
        border-radius: 1px;
    }
    .timelineitem {
        text-align: left;
    }
    .commentdate {
        margin-top: -5px;
        font-size: 0.65em;
    }
    .commenttext {
        margin-top: -5px;
    }
    .comment {
        box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
        margin-top: -13px;
        border-radius: 5px;
        margin-left: -13px;
        padding-left: 8px;
        padding-top: 3px;
        padding-right: 3px;
    }

    .v-timeline {
        margin-left: -15px;
    }
    #line_chart_not_responsive > #line_chart_card > div.v-card__text {
        max-height: 400px;
        height: auto;
    }
</style>
